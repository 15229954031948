import React from 'react'
import ReactDOM from 'react-dom'
import styled from 'styled-components'

const ModalContent = styled.div`
    position: fixed;
    top: 50%;
    left: 50%;
    width: 70%;
    height: 70%;
    transform: translate(-50%, -50%);
    padding: 50px;
    z-index: 50;
    background: white;
    overflow-y: scroll;
`

const ModalBack = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background: rgba(0,0,0,0.7); /* Black w/ opacity */
`

const ModalClose = styled.div`
    cursor: pointer;
    position: absolute;
    top: 15px;  
    right: 40px;
    font-family: gothammedium;
    font-weight: bold;
`

export default function Modal({ open, children, onClose }) {
    if (!open) return null
    
    return ReactDOM.createPortal(
        <div>
            <div style={{ position: 'relative' }}>
                <ModalBack onClick={onClose} />
                <ModalContent>
                    {/* AQUÍ IRIA EL SUSPENSE FALLBACK CON EL LOADER*/}
                    <ModalClose onClick={onClose}>X</ModalClose>
                    {children}
                </ModalContent>
            </div>

        </div>,
        document.getElementById('modal')
    )
}
