import React from "react";

import styled from "styled-components";

const LoaderCont = styled.div`
    position: fixed;
    top: 50%;
    left: 50%;
    width: 70%;
    height: 70%;
    transform: translate(-50%, -50%);
    padding: 50px;
    z-index: 100;
`

const LoaderBack = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background: rgba(0,0,0,0.7); /* Black w/ opacity */
`

export default function Loader() {
    return (
        <div>
            <LoaderBack />
            <LoaderCont>
                <img src={'/loader_spinner.gif'} alt="Cargando..."></img>
            </LoaderCont>
        </div>
    );
};