import React, { useRef } from "react";
import styled from 'styled-components'

// 3D Imports

import { Canvas } from '@react-three/fiber';
import { OrbitControls, useGLTF, useAnimations } from '@react-three/drei';
//import { Physics, useBox } from '@react-three/cannon';

const Container3d = styled.div`
    height: -webkit-fill-available;
    width: -webkit-fill-available;
    position: absolute;
    padding-bottom: 15px;
    padding-right: 50px
`

// Se añaden casos por cada objeto 3D
export default function Hello(name) {

    switch (name.name) {
        case 'Cabra':
            return Cabra()
        case 'Arcon':
            return Arcon()
        case 'Convergente':
            return Convergente()
        case 'Esferas':
            return Esferas()
        case 'Goleta':
            return Goleta()
        case 'Presa':
            return Presa()
        case 'Estacion':
            return Estacion()
        case 'Vasija':
            return Vasija()
        case 'Corona':
            return Corona()
        case 'Volcan':
            return Volcan()
        default:
            break
    }

};

export function Cabra() {
    const { nodes, materials } = useGLTF('/3dModels/cabra.gltf')
    return (
        <Container3d>
            <Canvas camera={{ near: 0.1, far: 20 }}>
                <OrbitControls enableZoom={true} enablePan={false} />
                <ambientLight intensity={0.5} />
                <directionalLight position={[-2., 5, 2]} intensity={1} />
                <directionalLight position={[4, 5, 4]} intensity={1} />
                <directionalLight position={[0, 10, 0]} intensity={1} />
                <group dispose={null} position={[0, -1.2, 0]} scale={1.2}>
                    <mesh geometry={nodes.SK_Goat_LOD0.geometry} material={materials['M_Goat.002']} scale={0.02} />
                </group>
            </Canvas>
        </Container3d>
    );
};

export function Arcon(props) {
    const group = useRef()
    const { nodes, materials } = useGLTF('/3dModels/Arcon_Canario_GLB.glb')
    return (
        <Container3d>
            <Canvas camera={{ near: 0.1, far: 20 }}>
                <OrbitControls enableZoom={true} enablePan={false} />
                <ambientLight intensity={0.5} />
                <directionalLight position={[-2., 5, 2]} intensity={1} />
                <group ref={group} {...props} dispose={null}>
                    <group>
                        <group name="Group001" scale={0.005}>
                            <mesh name="metal_straps_low" geometry={nodes.metal_straps_low.geometry} material={materials['Material #30']} position={[0, 20.375, 112.448]} scale={269.946} />
                            <mesh name="Box001" geometry={nodes.Box001.geometry} material={materials['Material #37']} position={[304.809, -167.445, 193.218]} rotation={[Math.PI / 2, 0, 0]} />
                            <mesh name="Box002" geometry={nodes.Box002.geometry} material={materials['Material #37']} position={[328.029, -167.445, -233.924]} rotation={[Math.PI / 2, 0, 0]} />
                            <mesh name="Box003" geometry={nodes.Box003.geometry} material={materials['Material #37']} position={[-314.348, -167.445, 202.569]} rotation={[Math.PI / 2, 0, 0]} />
                            <mesh name="Box004" geometry={nodes.Box004.geometry} material={materials['Material #37']} position={[-298.599, -167.445, -232.886]} rotation={[Math.PI / 2, 0, 0]} />
                            <mesh name="Skrzynia_na_zboze_LR" geometry={nodes.Skrzynia_na_zboze_LR.geometry} material={materials['Material #33']} position={[0, -11.56, -9.094]} rotation={[-Math.PI / 2, 0, 0]} />
                        </group>
                    </group>
                </group>
            </Canvas>
        </Container3d>
    )
}

export function Convergente(props) {
    const group = useRef()
    const { nodes, materials } = useGLTF('/3dModels/Convergente_GLB.glb')
    return (
        <Container3d>
            <Canvas camera={{ near: 0.1, far: 20 }}>
                <OrbitControls enableZoom={true} enablePan={false} maxPolarAngle={Math.PI / 2} />
                <ambientLight intensity={0.5} />
                <directionalLight position={[-2., 5, 2]} intensity={1} />
                <directionalLight position={[2, 5, 12]} intensity={1.5} />
                <directionalLight position={[2, 5, -12]} intensity={1} />
                <group ref={group} {...props} dispose={null} scale={3}>
                    <group>
                        <group name="Convergente" scale={0.025}>
                            <mesh name="Box002" geometry={nodes.Box002.geometry} material={materials['Material.001']} position={[-9.437, 4.711, 0.032]} />
                            <group name="mesh_range01_05K_OBJ002" position={[-6.427, 2.557, 16.495]} scale={[35.645, 24.421, 30.393]}>
                                <mesh name="Mesh004" geometry={nodes.Mesh004.geometry} material={materials['Material #30.001']} />
                                <mesh name="Mesh004_1" geometry={nodes.Mesh004_1.geometry} material={materials['Material #42.002']} />
                            </group>
                            <mesh name="Object001" geometry={nodes.Object001.geometry} material={materials['Material #28.001']} position={[-6.427, 2.559, 16.495]} scale={[35.645, 24.421, 30.393]} />
                        </group>
                        <mesh name="Object003" geometry={nodes.Object003.geometry} material={materials['Material #28.001']} position={[-0.163, 0.065, -0.393]} scale={[0.905, 0.62, 0.772]} />
                        <mesh name="Object004" geometry={nodes.Object004.geometry} material={materials['Material #29.001']} position={[-0.163, 0.065, 0.419]} scale={[0.905, 0.62, 0.772]} />
                        <mesh name="Object002" geometry={nodes.Object002.geometry} material={materials['Material #28.001']} position={[-0.163, 0.065, 0.419]} scale={[0.905, 0.62, 0.772]} />
                        <mesh name="Arrow02" geometry={nodes.Arrow02.geometry} material={materials.Arrows} position={[0.046, -0.037, 0.415]} rotation={[0, 0, -0.725]} scale={0.312} />
                        <mesh name="Arrow02001" geometry={nodes.Arrow02001.geometry} material={materials['Arrows.002']} position={[0.264, 0.049, 0.415]} rotation={[0, 0, 3.118]} scale={0.312} />
                        <mesh name="Arrow02002" geometry={nodes.Arrow02002.geometry} material={materials['Arrows.003']} position={[-0.219, 0.049, 0.415]} scale={0.312} />
                    </group>
                </group>
            </Canvas>
        </Container3d>

    )
}

export function Corona(props) {
    const group = useRef()
    const { nodes, materials } = useGLTF('/3dModels/La Corona_GLB.glb')
    return (
        <Container3d>
            <Canvas camera={{ near: 0.1, far: 20 }}>
                <OrbitControls enableZoom={true} enablePan={false} />
                <ambientLight intensity={1} />
                <directionalLight position={[-2., 5, 2]} intensity={1} />
                <directionalLight position={[0, 2, -2]} intensity={2} />
                <directionalLight position={[12., 15, 5]} intensity={2} />
                <group ref={group} {...props} dispose={null} scale={0.4}>
                    <group>
                        <group name="Group001" scale={0.025}>
                            <group name="BuildingMesh-00093" position={[267.656, -38.441, -160.185]} rotation={[0, 0, 1.571]} scale={0.308}>
                                <mesh name="Mesh002" geometry={nodes.Mesh002.geometry} material={materials['BuildingMat-00093']} />
                                <mesh name="Mesh002_1" geometry={nodes.Mesh002_1.geometry} material={materials['BuildingMat-00003']} />
                                <mesh name="Mesh002_2" geometry={nodes.Mesh002_2.geometry} material={materials['BuildingMat-00006']} />
                                <mesh name="Mesh002_3" geometry={nodes.Mesh002_3.geometry} material={materials['BuildingMat-00011']} />
                                <mesh name="Mesh002_4" geometry={nodes.Mesh002_4.geometry} material={materials['BuildingMat-00016']} />
                                <mesh name="Mesh002_5" geometry={nodes.Mesh002_5.geometry} material={materials['BuildingMat-00018']} />
                                <mesh name="Mesh002_6" geometry={nodes.Mesh002_6.geometry} material={materials['BuildingMat-00022']} />
                                <mesh name="Mesh002_7" geometry={nodes.Mesh002_7.geometry} material={materials['BuildingMat-00025']} />
                                <mesh name="Mesh002_8" geometry={nodes.Mesh002_8.geometry} material={materials['BuildingMat-00028']} />
                                <mesh name="Mesh002_9" geometry={nodes.Mesh002_9.geometry} material={materials['BuildingMat-00033']} />
                                <mesh name="Mesh002_10" geometry={nodes.Mesh002_10.geometry} material={materials['BuildingMat-00035']} />
                                <mesh name="Mesh002_11" geometry={nodes.Mesh002_11.geometry} material={materials['BuildingMat-00036']} />
                                <mesh name="Mesh002_12" geometry={nodes.Mesh002_12.geometry} material={materials['BuildingMat-00037']} />
                                <mesh name="Mesh002_13" geometry={nodes.Mesh002_13.geometry} material={materials['BuildingMat-00038']} />
                                <mesh name="Mesh002_14" geometry={nodes.Mesh002_14.geometry} material={materials['BuildingMat-00041']} />
                                <mesh name="Mesh002_15" geometry={nodes.Mesh002_15.geometry} material={materials['BuildingMat-00043']} />
                                <mesh name="Mesh002_16" geometry={nodes.Mesh002_16.geometry} material={materials['BuildingMat-00045']} />
                                <mesh name="Mesh002_17" geometry={nodes.Mesh002_17.geometry} material={materials['BuildingMat-00048']} />
                                <mesh name="Mesh002_18" geometry={nodes.Mesh002_18.geometry} material={materials['BuildingMat-00050']} />
                                <mesh name="Mesh002_19" geometry={nodes.Mesh002_19.geometry} material={materials['BuildingMat-00052']} />
                                <mesh name="Mesh002_20" geometry={nodes.Mesh002_20.geometry} material={materials['BuildingMat-00053']} />
                                <mesh name="Mesh002_21" geometry={nodes.Mesh002_21.geometry} material={materials['BuildingMat-00055']} />
                                <mesh name="Mesh002_22" geometry={nodes.Mesh002_22.geometry} material={materials['BuildingMat-00058']} />
                                <mesh name="Mesh002_23" geometry={nodes.Mesh002_23.geometry} material={materials['BuildingMat-00059']} />
                                <mesh name="Mesh002_24" geometry={nodes.Mesh002_24.geometry} material={materials['BuildingMat-00060']} />
                                <mesh name="Mesh002_25" geometry={nodes.Mesh002_25.geometry} material={materials['BuildingMat-00061']} />
                                <mesh name="Mesh002_26" geometry={nodes.Mesh002_26.geometry} material={materials['BuildingMat-00062']} />
                                <mesh name="Mesh002_27" geometry={nodes.Mesh002_27.geometry} material={materials['BuildingMat-00064']} />
                                <mesh name="Mesh002_28" geometry={nodes.Mesh002_28.geometry} material={materials['BuildingMat-00065']} />
                                <mesh name="Mesh002_29" geometry={nodes.Mesh002_29.geometry} material={materials['BuildingMat-00066']} />
                                <mesh name="Mesh002_30" geometry={nodes.Mesh002_30.geometry} material={materials['BuildingMat-00069']} />
                                <mesh name="Mesh002_31" geometry={nodes.Mesh002_31.geometry} material={materials['BuildingMat-00070']} />
                                <mesh name="Mesh002_32" geometry={nodes.Mesh002_32.geometry} material={materials['BuildingMat-00072']} />
                                <mesh name="Mesh002_33" geometry={nodes.Mesh002_33.geometry} material={materials['BuildingMat-00074']} />
                                <mesh name="Mesh002_34" geometry={nodes.Mesh002_34.geometry} material={materials['BuildingMat-00075']} />
                                <mesh name="Mesh002_35" geometry={nodes.Mesh002_35.geometry} material={materials['BuildingMat-00077']} />
                                <mesh name="Mesh002_36" geometry={nodes.Mesh002_36.geometry} material={materials['BuildingMat-00078']} />
                                <mesh name="Mesh002_37" geometry={nodes.Mesh002_37.geometry} material={materials['BuildingMat-00080']} />
                                <mesh name="Mesh002_38" geometry={nodes.Mesh002_38.geometry} material={materials['BuildingMat-00081']} />
                                <mesh name="Mesh002_39" geometry={nodes.Mesh002_39.geometry} material={materials['BuildingMat-00082']} />
                                <mesh name="Mesh002_40" geometry={nodes.Mesh002_40.geometry} material={materials['BuildingMat-00085']} />
                                <mesh name="Mesh002_41" geometry={nodes.Mesh002_41.geometry} material={materials['BuildingMat-00087']} />
                                <mesh name="Mesh002_42" geometry={nodes.Mesh002_42.geometry} material={materials['BuildingMat-00089']} />
                                <mesh name="Mesh002_43" geometry={nodes.Mesh002_43.geometry} material={materials['BuildingMat-00090']} />
                                <mesh name="Mesh002_44" geometry={nodes.Mesh002_44.geometry} material={materials['BuildingMat-00091']} />
                                <mesh name="Mesh002_45" geometry={nodes.Mesh002_45.geometry} material={materials['BuildingMat-00092']} />
                                <mesh name="Mesh002_46" geometry={nodes.Mesh002_46.geometry} material={materials['BuildingMat-00094']} />
                                <mesh name="Mesh002_47" geometry={nodes.Mesh002_47.geometry} material={materials['BuildingMat-00095']} />
                                <mesh name="Mesh002_48" geometry={nodes.Mesh002_48.geometry} material={materials['BuildingMat-00097']} />
                                <mesh name="Mesh002_49" geometry={nodes.Mesh002_49.geometry} material={materials['BuildingMat-00098']} />
                                <mesh name="Mesh002_50" geometry={nodes.Mesh002_50.geometry} material={materials['BuildingMat-00100']} />
                                <mesh name="Mesh002_51" geometry={nodes.Mesh002_51.geometry} material={materials['BuildingMat-00101']} />
                                <mesh name="Mesh002_52" geometry={nodes.Mesh002_52.geometry} material={materials['BuildingMat-00102']} />
                                <mesh name="Mesh002_53" geometry={nodes.Mesh002_53.geometry} material={materials['BuildingMat-00103']} />
                                <mesh name="Mesh002_54" geometry={nodes.Mesh002_54.geometry} material={materials['BuildingMat-00104']} />
                                <mesh name="Mesh002_55" geometry={nodes.Mesh002_55.geometry} material={materials['BuildingMat-00107']} />
                                <mesh name="Mesh002_56" geometry={nodes.Mesh002_56.geometry} material={materials['BuildingMat-00108']} />
                                <mesh name="Mesh002_57" geometry={nodes.Mesh002_57.geometry} material={materials['BuildingMat-00109']} />
                                <mesh name="Mesh002_58" geometry={nodes.Mesh002_58.geometry} material={materials['BuildingMat-00111']} />
                                <mesh name="Mesh002_59" geometry={nodes.Mesh002_59.geometry} material={materials['BuildingMat-00112']} />
                                <mesh name="Mesh002_60" geometry={nodes.Mesh002_60.geometry} material={materials['BuildingMat-00113']} />
                                <mesh name="Mesh002_61" geometry={nodes.Mesh002_61.geometry} material={materials['BuildingMat-00115']} />
                                <mesh name="Mesh002_62" geometry={nodes.Mesh002_62.geometry} material={materials['BuildingMat-00116']} />
                                <mesh name="Mesh002_63" geometry={nodes.Mesh002_63.geometry} material={materials['BuildingMat-00117']} />
                                <mesh name="Mesh002_64" geometry={nodes.Mesh002_64.geometry} material={materials['BuildingMat-00118']} />
                                <mesh name="Mesh002_65" geometry={nodes.Mesh002_65.geometry} material={materials['BuildingMat-00119']} />
                                <mesh name="Mesh002_66" geometry={nodes.Mesh002_66.geometry} material={materials['BuildingMat-00120']} />
                                <mesh name="Mesh002_67" geometry={nodes.Mesh002_67.geometry} material={materials['BuildingMat-00123']} />
                                <mesh name="Mesh002_68" geometry={nodes.Mesh002_68.geometry} material={materials['BuildingMat-00124']} />
                                <mesh name="Mesh002_69" geometry={nodes.Mesh002_69.geometry} material={materials['BuildingMat-00127']} />
                                <mesh name="Mesh002_70" geometry={nodes.Mesh002_70.geometry} material={materials['BuildingMat-00129']} />
                                <mesh name="Mesh002_71" geometry={nodes.Mesh002_71.geometry} material={materials['BuildingMat-00130']} />
                                <mesh name="Mesh002_72" geometry={nodes.Mesh002_72.geometry} material={materials['BuildingMat-00131']} />
                                <mesh name="Mesh002_73" geometry={nodes.Mesh002_73.geometry} material={materials['BuildingMat-00132']} />
                                <mesh name="Mesh002_74" geometry={nodes.Mesh002_74.geometry} material={materials['BuildingMat-00133']} />
                                <mesh name="Mesh002_75" geometry={nodes.Mesh002_75.geometry} material={materials['BuildingMat-00136']} />
                                <mesh name="Mesh002_76" geometry={nodes.Mesh002_76.geometry} material={materials['BuildingMat-00139']} />
                                <mesh name="Mesh002_77" geometry={nodes.Mesh002_77.geometry} material={materials['BuildingMat-00140']} />
                                <mesh name="Mesh002_78" geometry={nodes.Mesh002_78.geometry} material={materials['BuildingMat-00142']} />
                                <mesh name="Mesh002_79" geometry={nodes.Mesh002_79.geometry} material={materials['BuildingMat-00143']} />
                                <mesh name="Mesh002_80" geometry={nodes.Mesh002_80.geometry} material={materials['BuildingMat-00146']} />
                                <mesh name="Mesh002_81" geometry={nodes.Mesh002_81.geometry} material={materials['BuildingMat-00147']} />
                                <mesh name="Mesh002_82" geometry={nodes.Mesh002_82.geometry} material={materials['BuildingMat-00148']} />
                                <mesh name="Mesh002_83" geometry={nodes.Mesh002_83.geometry} material={materials['BuildingMat-00152']} />
                                <mesh name="Mesh002_84" geometry={nodes.Mesh002_84.geometry} material={materials['BuildingMat-00156']} />
                                <mesh name="Mesh002_85" geometry={nodes.Mesh002_85.geometry} material={materials['BuildingMat-00158']} />
                                <mesh name="Mesh002_86" geometry={nodes.Mesh002_86.geometry} material={materials['BuildingMat-00162']} />
                                <mesh name="Mesh002_87" geometry={nodes.Mesh002_87.geometry} material={materials['BuildingMat-00165']} />
                                <mesh name="Mesh002_88" geometry={nodes.Mesh002_88.geometry} material={materials['BuildingMat-00169']} />
                                <mesh name="Mesh002_89" geometry={nodes.Mesh002_89.geometry} material={materials['BuildingMat-00176']} />
                            </group>
                            <mesh name="Plane003" geometry={nodes.Plane003.geometry} material={materials['Material #115']} position={[265.426, -11.045, 0.08]} rotation={[Math.PI / 2, 0, -Math.PI / 2]} />
                            <mesh name="TextPlus001" geometry={nodes.TextPlus001.geometry} material={nodes.TextPlus001.material} position={[-14.913, -45.237, -232.398]} rotation={[Math.PI / 2, 0, -Math.PI]} />
                        </group>
                    </group>
                </group>
            </Canvas>
        </Container3d>

    )
}

export function Esferas(props) {
    const { nodes, materials } = useGLTF('/3dModels/Esferas.glb')
    return (
        <Container3d>
            <Canvas camera={{ near: 0.1, far: 20 }}>
                <OrbitControls enableZoom={true} enablePan={false} />
                <ambientLight intensity={0.5} />
                <directionalLight position={[-2., 5, 2]} intensity={1} />
                <group {...props} dispose={null}>
                    <mesh geometry={nodes.Tierra002.geometry} material={materials['Material #26.002']} position={[0, 0, 0]} />
                </group>
            </Canvas>
        </Container3d>

    )
}

export function Goleta(props) {
    const { nodes, materials } = useGLTF('/3dModels/Goleta.glb')
    return (
        <Container3d>
            <Canvas camera={{ near: 0.1, far: 20 }}>
                <OrbitControls enableZoom={true} enablePan={false} />
                <ambientLight intensity={1} />
                <directionalLight position={[-12, 5, 12]} intensity={2} />
                <directionalLight position={[12, 5, -12]} intensity={2} />
                <group {...props} dispose={null}>
                    <group scale={0.2} position={[0, 0.1, 0]} rotation={[0, -8, 0]}>
                        <group rotation={[-Math.PI, 0, -Math.PI]}>
                            <mesh geometry={nodes.Mesh.geometry} material={materials['01 - Default']} />
                            <mesh geometry={nodes.Mesh_1.geometry} material={materials['03 - Default']} />
                            <mesh geometry={nodes.Mesh_2.geometry} material={materials['Material #48']} />
                            <mesh geometry={nodes.Mesh_3.geometry} material={materials['Material #49']} />
                            <mesh geometry={nodes.Mesh_4.geometry} material={materials['Material #50']} />
                        </group>
                        <mesh geometry={nodes.Plane07.geometry} material={materials['02 - Default']} position={[0.784, -3.974, 8.774]} rotation={[-Math.PI, 0, -Math.PI]} />
                    </group>
                </group>
            </Canvas>
        </Container3d>

    )
}

export function Presa(props) {
    const { nodes, materials } = useGLTF('/3dModels/presa.glb')
    return (
        <Container3d>
            <Canvas camera={{ near: 0.1, far: 20 }}>
                <OrbitControls enableZoom={true} enablePan={false} maxPolarAngle={Math.PI / 2} />
                <ambientLight intensity={1} />
                <directionalLight position={[-5., 15, 2]} intensity={1} />
                <directionalLight position={[5., 15, 2]} intensity={1} />
                <directionalLight position={[5., 15, 12]} intensity={1} />
                <group {...props} dispose={null} scale={1.5} position={[0, -1, 0]}>
                    <group position={[0.019, 1.085, -0.785]} scale={0.01}>
                        <mesh geometry={nodes.sub014.geometry} material={materials['metal.003']} position={[34.709, -6.45, -4.278]} rotation={[0, -0.435, 0]} scale={1.726} />
                        <mesh geometry={nodes.sub015.geometry} material={materials['metal.003']} position={[-13.046, -6.45, -6.674]} rotation={[0, -0.098, 0]} scale={1.726} />
                        <mesh geometry={nodes.sub016.geometry} material={materials['metal.003']} position={[-50.585, -6.45, 1.216]} rotation={[0, 0.165, 0]} scale={1.726} />
                        <mesh geometry={nodes.sub017.geometry} material={materials['metal.003']} position={[-91.426, -6.45, 22.764]} rotation={[0, 0.457, 0]} scale={1.726} />
                    </group>
                    <group position={[-0.012, 0.124, -0.343]} scale={0.01}>
                        <mesh geometry={nodes.Mesh022.geometry} material={materials['wayside.003']} />
                        <mesh geometry={nodes.Mesh022_1.geometry} material={materials['road.003']} />
                        <mesh geometry={nodes.Mesh022_2.geometry} material={materials['roadyel.003']} />
                    </group>
                    <mesh geometry={nodes.damcol.geometry} material={materials['muros.003']} position={[-0.234, 0.981, -0.877]} rotation={[0, -0.11, 0]} scale={0.024} />
                    <mesh geometry={nodes.damcol001.geometry} material={materials['muros.003']} position={[-0.618, 0.981, -0.763]} rotation={[0, 0.165, 0]} scale={0.024} />
                    <mesh geometry={nodes.damcol002.geometry} material={materials['muros.003']} position={[-1.022, 0.981, -0.52]} rotation={[0, 0.435, 0]} scale={0.024} />
                    <mesh geometry={nodes.damcol003.geometry} material={materials['muros.003']} position={[0.255, 0.981, -0.892]} rotation={[0, -0.437, 0]} scale={0.024} />
                    <mesh geometry={nodes.Arc001.geometry} material={materials['presa.003']} position={[0.004, 0.968, 0.375]} scale={[0.01, 0.009, 0.01]} />
                    <mesh geometry={nodes.enviro002.geometry} material={materials['ground.003']} position={[0, 0.12, -0.353]} scale={0.01} />
                    <group position={[-0.176, 0.107, -0.692]} scale={0.01}>
                        <mesh geometry={nodes.Mesh030.geometry} material={materials['Agua verde']} />
                        <mesh geometry={nodes.Mesh030_1.geometry} material={materials['Material.002']} />
                    </group>
                    <mesh geometry={nodes.Cube.geometry} material={materials['Agua verde']} position={[0, 0.097, 0.415]} scale={[1, 0.122, 1.193]} />
                </group>
            </Canvas>
        </Container3d>

    )
}

export function Estacion(props) {
    const { nodes, materials } = useGLTF('/3dModels/estacion_espacial.glb')
    
    return (
        <Container3d>
            <Canvas camera={{ near: 0.1, far: 20 }}>
                <OrbitControls enableZoom={true} enablePan={false} />
                <ambientLight intensity={0.5} />
                <directionalLight position={[2., 5, 2]} intensity={1} />
                <directionalLight position={[12, 5, 12]} intensity={1} />
                <directionalLight position={[-12, -25, 32]} rotation={[5, 8, 2]} intensity={1} />
                <group {...props} dispose={null} scale={0.07}>
                    <group position={[0, 0, 2.221]}>
                        <mesh geometry={nodes.Cylinder002.geometry} material={nodes.Cylinder002.material} />
                        <mesh geometry={nodes.Cylinder002_1.geometry} material={materials.ISS_AO_03} />
                        <mesh geometry={nodes.Cylinder002_2.geometry} material={materials.ISS_01_dull} />
                        <mesh geometry={nodes.Cylinder002_3.geometry} material={materials.ISS_AO_01} />
                        <mesh geometry={nodes.Cylinder002_4.geometry} material={materials.ISS_01_shiny_n} />
                        <mesh geometry={nodes.Cylinder002_5.geometry} material={materials.ISS_03_shiny_n} />
                        <mesh geometry={nodes.Cylinder002_6.geometry} material={materials.ISS_AO_02} />
                        <mesh geometry={nodes.Cylinder002_7.geometry} material={materials.ISS_02_dull} />
                        <mesh geometry={nodes.Cylinder002_8.geometry} material={materials.ISS_03_dull} />
                        <mesh geometry={nodes.Cylinder002_9.geometry} material={materials.ISS_AO_06} />
                        <mesh geometry={nodes.Cylinder002_10.geometry} material={materials.shiny_panel} />
                        <mesh geometry={nodes.Cylinder002_11.geometry} material={materials['olive *']} />
                        <mesh geometry={nodes.Cylinder002_12.geometry} material={materials.ISS_AO_04} />
                        <mesh geometry={nodes.Cylinder002_13.geometry} material={materials['ISS_01_dark *']} />
                        <mesh geometry={nodes.Cylinder002_14.geometry} material={materials.foil_silver} />
                        <mesh geometry={nodes.Cylinder002_15.geometry} material={materials['ISS_02_dark *']} />
                        <mesh geometry={nodes.Cylinder002_16.geometry} material={materials.ISS_04_dull} />
                        <mesh geometry={nodes.Cylinder002_17.geometry} material={materials.ISS_AO_05} />
                        <mesh geometry={nodes.Cylinder002_18.geometry} material={materials.ISS_AO_07} />
                        <mesh geometry={nodes.Cylinder002_19.geometry} material={materials.ISS_AO_08} />
                        <mesh geometry={nodes.Cylinder002_20.geometry} material={materials.white} />
                        <mesh geometry={nodes.Cylinder002_21.geometry} material={materials.base_metal} />
                        <mesh geometry={nodes.Cylinder002_22.geometry} material={materials['ISS_03_dull.001']} />
                        <mesh geometry={nodes.Cylinder002_23.geometry} material={materials.ecostress} />
                        <mesh geometry={nodes.Cylinder002_24.geometry} material={materials['plastic black']} />
                        <mesh geometry={nodes.Cylinder002_25.geometry} material={materials.ecostressWhite} />
                        <mesh geometry={nodes.Cylinder002_26.geometry} material={materials['ISS_03_dull.002']} />
                        <mesh geometry={nodes.Cylinder002_27.geometry} material={materials['ecostress metal']} />
                        <mesh geometry={nodes.Cylinder002_28.geometry} material={materials.ecostress_dexter} />
                    </group>
                </group>
            </Canvas>
        </Container3d>

    )
}

export function Vasija(props) {
    const { nodes, materials } = useGLTF('/3dModels/Vasija_Canaria2_GLB.glb')
    return (
        <Container3d>
            <Canvas camera={{ near: 0.1, far: 20 }}>
                <OrbitControls enableZoom={true} enablePan={false} />
                <ambientLight intensity={0.5} />
                <directionalLight position={[-2., 5, 2]} intensity={1} />
                <group {...props} dispose={null}>
                    <group position={[0, 2.741, -5.052]} rotation={[0, 0, Math.PI]} scale={0.258}>
                        <mesh geometry={nodes.Object_10.geometry} material={materials['material_0.001']} />
                        <mesh geometry={nodes.Object_11.geometry} material={materials['material_0.001']} />
                        <mesh geometry={nodes.Object_12.geometry} material={materials['material_0.001']} />
                        <mesh geometry={nodes.Object_13.geometry} material={materials['material_0.001']} />
                        <mesh geometry={nodes.Object_14.geometry} material={materials['material_0.001']} />
                        <mesh geometry={nodes.Object_15.geometry} material={materials['material_0.001']} />
                        <mesh geometry={nodes.Object_16.geometry} material={materials['material_0.001']} />
                        <mesh geometry={nodes.Object_2.geometry} material={materials['material_0.001']} />
                        <mesh geometry={nodes.Object_3.geometry} material={materials['material_0.001']} />
                        <mesh geometry={nodes.Object_4.geometry} material={materials['material_0.001']} />
                        <mesh geometry={nodes.Object_5.geometry} material={materials['material_0.001']} />
                        <mesh geometry={nodes.Object_6.geometry} material={materials['material_0.001']} />
                        <mesh geometry={nodes.Object_7.geometry} material={materials['material_0.001']} />
                        <mesh geometry={nodes.Object_8.geometry} material={materials['material_0.001']} />
                        <mesh geometry={nodes.Object_9.geometry} material={materials['material_0.001']} />
                    </group>
                </group>
            </Canvas>
        </Container3d>

    )
}

export function Volcan(props) {
    const group = useRef()
    const { nodes, materials } = useGLTF('/3dModels/Volcan_Mitad_GLB.glb')
    //const { actions } = useAnimations(animations, group)
    return (
        <Container3d>
            <Canvas camera={{ near: 0.1, far: 20 }}>
                <OrbitControls enableZoom={true} maxPolarAngle={Math.PI / 2} enablePan={false}/>
                <ambientLight intensity={1} />
                <directionalLight position={[-2., 5, 2]} intensity={1} />
                <directionalLight position={[10, 5, -12]} intensity={1} />
                <directionalLight position={[10, 15, 12]} intensity={1.5} />
                <group ref={group} {...props} dispose={null} position={[0,-1,0.25]} scale={0.5}>
                    <group>
                        <group name="Node_19001" position={[38.209, 0, -6.586]} />
                        <group name="Node_19" position={[38.209, 0, -6.586]} />
                        <group name="Node_19002" position={[38.209, 0, -6.586]}>
                            <group name="Mesh_6" position={[-38.209, 0, 6.586]} rotation={[-Math.PI / 2, 0, 0]}>
                                <mesh name="Mesh016" geometry={nodes.Mesh016.geometry} material={materials['01 - Default.002']} />
                                <mesh name="Mesh016_1" geometry={nodes.Mesh016_1.geometry} material={materials['Material #37.002']} />
                            </group>
                        </group>
                        <mesh name="Line001" geometry={nodes.Line001.geometry} material={materials['Material.001']} position={[0.273, 1.291, 0.07]} rotation={[Math.PI / 2, 0, 0]} scale={0.025} />
                        <mesh name="Line003" geometry={nodes.Line003.geometry} material={materials.Lava} position={[0.35, 0.028, 0.032]} rotation={[Math.PI / 2, 0, 0]} scale={0.025} />
                        <mesh name="pasted__pasted__bifrostLiquid1Mesh1" geometry={nodes.pasted__pasted__bifrostLiquid1Mesh1.geometry} material={materials['Lava.001']} position={[-0.001, 3.49, -1.352]} rotation={[Math.PI / 2, 0, -0.66]} scale={[0.023, 0.023, 0.014]} />
                        <mesh name="TextPlus001" geometry={nodes.TextPlus001.geometry} material={nodes.TextPlus001.material} position={[-0.982, 2.149, 0.131]} rotation={[Math.PI / 2, 0, 0]} scale={[0.008, 0.039, 0.008]} />
                        <mesh name="TextPlus002" geometry={nodes.TextPlus002.geometry} material={nodes.TextPlus002.material} position={[-2.757, 0.675, 0.131]} rotation={[Math.PI / 2, 0, 0]} scale={[0.008, 0.039, 0.008]} />
                        <group name="TextPlus003" position={[-0.158, -0.653, 0.131]} rotation={[Math.PI / 2, 0, 0]} scale={[0.008, 0.039, 0.008]}>
                            <mesh name="Mesh022" geometry={nodes.Mesh022.geometry} material={materials['01 - Default.002']} />
                            <mesh name="Mesh022_1" geometry={nodes.Mesh022_1.geometry} material={materials['Material #37.002']} />
                        </group>
                        <mesh name="TextPlus004" geometry={nodes.TextPlus004.geometry} material={nodes.TextPlus004.material} position={[-2.757, -0.148, 0.131]} rotation={[Math.PI / 2, 0, 0]} scale={[0.008, 0.039, 0.008]} />
                    </group>
                </group>
            </Canvas>
        </Container3d>

    )
}

useGLTF.preload('/3dModels/cabra.gltf')
useGLTF.preload('/3dModels/Arcon_Canario_GLB.glb')
useGLTF.preload('/3dModels/Convergente_GLB.glb')
useGLTF.preload('/3dModels/La Corona_GLB.glb')
useGLTF.preload('/3dModels/Esferas.glb')
useGLTF.preload('/3dModels/Goleta.glb')
useGLTF.preload('/3dModels/presa.glb')
useGLTF.preload('/3dModels/Vasija_Canaria2_GLB.glb')
useGLTF.preload('/3dModels/Volcan_Mitad_GLB.glb')
useGLTF.preload('/3dModels/estacion_espacial.glb')