import React, { useState } from "react";
import "./cards.css";
import styled from "styled-components";

import Modal from "./Modal";

const Container = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin-bottom: 50px;
`;

const CenterCont = styled.div`
  display: flex;
  flex-direction: column;
  margin: auto;
  width: max-content;
  max-width: 985px;

  video, img{
    width: auto;
    height: 690px;
  }
`

const H1 = styled.h1`
  font-family: gotham_condensedmedium;
  margin: 35px 0px 0px;
`

const H2 = styled.h2`
  font-family: gotham_condensedbook;
  margin: 10px 0px 0px;
`

const Audio = styled.audio`
  margin-left: 15px;
`

export default function Card(props) {
  const { data, type } = props
  const [modalInfo, setModalInfo] = useState({ isOpen: false, tittleModal: '', contentModal: '' })

  let listItem = data.map(({ name, url, mini, miniPod, mediaType, id, descrip, digital }) => {
    var content;
    if (type === "video") {
      content = <video controls>
        <source src={url} type="video/mp4" />
      </video>
    } else if (type === "podcast") {
      content = <div style={{ display: "flex", alignItems: "baseline" }}>
        <img src={miniPod} alt="podcast"></img>
        <Audio controls>
          <source src={url} type={"audio/" + mediaType} />
        </Audio>
      </div>
    } else if (type === "laminas" || type === "infografias" || type === "animaciones") {
      if (digital) {
        return (
          <div className="box" key={id}>
            <div className="card text-center">
              <div className="overflow">
                <a href={url} target="_blank" rel="noreferrer">
                  <img src={mini} title={name} align="center" alt={name} />
                </a>
              </div>
            </div>
          </div>
        )
      }
      content = <img src={url} style={{ height: "100%" }} alt="No digitales"></img>
    } else {
      content = <img src={url} alt="Imagenes"></img>
    }

    return (
      <div className="box" key={id}>
        <div className="card text-center">
          <div className="overflow">
            <img onClick={() => setModalInfo({
              isOpen: true, tittleModal: name, descModal: descrip,
              contentModal: content
            })} src={mini} title={name} align="center" alt={name} />
          </div>
        </div>
      </div>
    );

  });

  return <Container>
    {listItem}
    <Modal open={modalInfo.isOpen} onClose={() => setModalInfo({
      isOpen: false, tittleModal: '',
      contentModal: ''
    })}>
      <CenterCont>
        {modalInfo.contentModal}
        <div>
          <H1>{modalInfo.tittleModal}</H1>
          <H2>{modalInfo.descModal}</H2>
        </div>

      </CenterCont>
    </Modal>
  </Container>


}