
import React from 'react';
import './book.css';

import listPdf from '../../elementos/ImagenesPDF.json'

import HTMLFlipBook from 'react-pageflip';

const PageCover = React.forwardRef((props, ref) => {
    return (
        <div className="page page-cover" ref={ref} data-density="hard">
            <div className="page-content">
                {props.children}
            </div>
        </div>
    );
});

const Page = React.forwardRef((props, ref) => {
    return (
        <div className="page" ref={ref}>
            <div className='page-content'>
                {props.children}
            </div>
        </div>
    );
});

export default function MyBook(props) {

    let listImg = listPdf.map(({ pageNumber, imagen }) => {
        return <Page key={pageNumber} number={pageNumber-1}><img src={imagen} alt="Dossier"></img></Page>
    })

    return (
        <div style={{display: "flex", justifyContent: "center"}}>
            <HTMLFlipBook width={495.28}
                height={700.91}
                max-width={495.28}
                max-height={700.91}
                size="stretch"
                className='flipBook'
                maxShadowOpacity={0}
                showCover={true}
                mobileScrollSupport={true}>
                <PageCover><img src={'/dossier/PORTADA Y CONTRA SUELTAS.jpg'} alt='Portada Dossier'/></PageCover>
                {listImg}
                <PageCover><img src={'/dossier/PORTADA Y CONTRA SUELTAS2.jpg'} alt='Contra Dossier'/></PageCover>
            </HTMLFlipBook>
        </div>
    );
}