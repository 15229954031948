import React, { useState } from "react";
import "./cards.css";
import styled from "styled-components";

// import Modal Component

import Modal from './Modal';

// 3D Object Pages

import Hello from '../objects/Objetos';

const Container = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin-bottom: 50px;
`;

export default function Card3D(data) {

  const [modalInfo, setModalInfo] = useState({ isOpen: false, tittleModal: '', contentModal: '' })

  let object = data.data.map(({ title, src, object3D }, i) => {

    return (
      <div className="box" key={i}>
        <div className="card text-center">
          <div className="overflow">
            <img onClick={() => setModalInfo({
              isOpen: true, tittleModal: title,
              contentModal: <Hello name={object3D} />
            })} src={src} title={title} align="center" alt={title} />
          </div>
        </div>
      </div>

    )
  })

  return <Container>
    {object}
    <Modal open={modalInfo.isOpen} onClose={() => setModalInfo({
      isOpen: false, tittleModal: '',
      contentModal: ''
    })}>
      {modalInfo.contentModal}
      <h1>{modalInfo.tittleModal}</h1>
    </Modal>
  </Container>

}