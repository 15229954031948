import React from "react";
import "./cards.css";
import styled from "styled-components";

const Container = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin-bottom: 50px;
`;


export default function CardLink(props) {
  const { data } = props
  
  let listItem = data.map(({ name, mini, url, id }) => {

    return (
      <a href={url} target="_blank" rel="noreferrer" key={id} className="box">
        <div>
          <div className="card text-center">
            <div className="overflow">
              <img src={mini} title={name} align="center" alt={name} />
            </div>
          </div>
        </div>
      </a>
    );

  });

  return <Container>
    {listItem}
  </Container>


}