import './App.css';
import './fonts.css';

import ani from './PRUEBAULT_Sara.gif';

import styled from 'styled-components';
import Banner from './BANNER_ESTRECHO.png'

import Loader from './components/Loader';

//import listImages from './elementos/listaImagenes.json';
import list3D from './elementos/lista3D.json';
import listVid from './elementos/listaVideos.json';
import listFotos from './elementos/listaFotos.json';
import listPod from './elementos/listaPodcast.json';
import listIcon from './elementos/listaIconos.json';
import listObjEdu from './elementos/listaObjEdu.json';
import listCarou from './elementos/carouselObj.json';
import listIlus from './elementos/listaIlust.json';
import listLam from './elementos/listaLaminas.json';
import listInfo from './elementos/listaInfo.json';
import listAni from './elementos/listaAnim.json';
import listArtic from './elementos/listArticulo.json';
import listRv from './elementos/listaRv.json';

// PDF 

//import DemoBook from './components/flipbook/Demobook';
import MyBook from './components/flipbook/Demo2';

// Carousel

import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';

// Route Imports

import { Link, BrowserRouter, Routes, Route } from 'react-router-dom'

// 3D Object Pages

//import { Model } from './components/Museo_estructura_luces';

//import Hello from './components/objects/Objetos';
//import Escena from './components/objects/Virtual';

// Funcion Cards para Galeria

import Card from './components/cards/CardImg';
import Card3D from './components/cards/Card3D';
import CardLink from './components/cards/CardLink'
import Modal from './components/cards/Modal';

import { Canvas } from '@react-three/fiber';
/*
import { Suspense } from 'react';*/
import { Suspense, useState } from 'react';
import Escena2 from './components/objects/Virtual2';
import { Estacion } from './components/objects/Objetos';

const Header = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 20px 0px;

  div {
    margin-top: 18px;
  }

  a {
    margin-left: 160px;
    color: white;
    font-size: 20px;
    font-family: gothammedium;
    text-decoration: none;

    &:first-child {
      margin-left: 0px
    }
  }

  ul {
    display: flex;
    align-items: center;
    list-style: none;

    li {
      cursor: pointer;
      margin-right: 159.5px;
      color: white;
      font-size: 20px;
      font-family: gothammedium;
    }

    li:last-child {
      margin-right: 0px
    }

  }

`;

const Recom = styled.p`
  font-family: gothammedium;
  color: white;
  margin: 0px 0px 10px
`

const ContDiv = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 50px;
`

const ContRec = styled.div`
  width: 290px;
  margin-right: 19.75px;
  cursor: pointer;

  &:last-child {
    margin-right: 0px;
  }
`
const Line = styled.div`
  border-top: 2.5px solid #FFED00;
`
const Footer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 40px 0px 50px;
`

const Portada = styled.div`
  background-color: #0CC6DE;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 50;
  display: flex;
  justify-content: center
`;

const ButtonCom = styled.button`
  cursor: pointer;
  background: transparent;
  border: 2px solid black;
  padding: 3px 10px;
  border-radius: 20px;
  font-family: gothammedium;
  font-size: 20px;
`

const CenterCont = styled.div`
  display: flex;
  flex-direction: column;
  margin: auto;
  width: max-content;
  max-width: 985px;

  video, img{
    width: auto;
    height: 690px;
  }
`

const H1 = styled.h1`
  font-family: gotham_condensedmedium;
  margin: 35px 0px 0px;
`

const H2 = styled.h2`
  font-family: gotham_condensedbook;
  margin: 10px 0px 0px;
`

const responsive = listCarou.responsive;

function App() {

  const [isVisible, setIsVisible] = useState(false);
  const [isVisible2, setIsVisible2] = useState(false);
  const [modalInfo, setModalInfo] = useState({ isOpen: false, tittleModal: '', contentModal: '' })
  const [modalInfo2, setModalInfo2] = useState({ isOpen: false, tittleModal: '', contentModal: '' })

  const toggleVisibility = () => {
    setIsVisible(!isVisible);
    setIsVisible2(false);
  };

  const toggleVisibility2 = () => {
    console.log(!isVisible2)
    setIsVisible(false);
    setIsVisible2(!isVisible2);
  };

  const toggleVis = () => {
    setIsVisible(false)
    setIsVisible2(false)
  }

  const listObj = listCarou.objects.map((item) => {
    return (
      <div className='cardCar' key={item.id}>
        <Link to={item.link}>
          <img className='product--image' src={item.imageUrl} alt='prodcut'></img>
        </Link>
      </div >
    )
  })

  return (
    <BrowserRouter>
      <Header>
        <a href='https://www3.gobiernodecanarias.org/medusa/ecoescuela/ate/' target='_blank' rel="noreferrer">
          <img src={'/images/logos/LOGO_GOBCAN_CABECERA.png'} alt='logo Gobierno'></img>
        </a>
        <ul id='menu'>
          <li onClick={toggleVisibility}>
            Buscar
            <div id='twoSub'>
              <ul className='submenu' style={{ display: isVisible ? 'block' : 'none' }}>
                <li>
                  <Link to='/videos'>Vídeos</Link>
                </li>
                <li>
                  <Link to='/rv'>RV</Link>
                </li>
                <li>
                  <Link to='/3d'>3D</Link>
                </li>
                <li>
                  <Link to='/animaciones'>Animaciones</Link>
                </li>
                <li>
                  <Link to='/objetosEducativos'>Obj. educativos</Link>
                </li>
                <li>
                  <Link to='/podcast'>Pódcast</Link>
                </li>
              </ul>
              <ul className='submenu lastSub' style={{ display: isVisible ? 'block' : 'none' }}>
                <li>
                  <Link to='/infografias'>Infografías</Link>
                </li>
                <li>
                  <Link to='/laminas'>Láminas</Link>
                </li>
                <li>
                  <Link to='/ilustraciones'>Ilustraciones</Link>
                </li>
                <li>
                  <Link to='/iconos'>Iconos</Link>
                </li>
                <li>
                  <Link to='/fotos'>Fotografías</Link>
                </li>
                <li>
                  <Link to='/articulos'>Artículos</Link>
                </li>
              </ul>
            </div>
          </li>
          {/*<li onClick={() => setModalInfo2({
            isOpen: true, tittleModal: "", descModal: "",
            contentModal: <MyBook></MyBook>
          })}>
            + Información
        </li>*/}
        <li>
        <Link to='/informacion'>+ Información</Link>
        </li>
          <li onClick={toggleVisibility2}>
            + Recursos
            <ul style={{ display: isVisible2 ? 'block' : 'none' }} className='submenu centerSub'>
              <li>
                <a href='https://www3.gobiernodecanarias.org/medusa/wiki/index.php?title=P%C3%A1gina_principal' target='_blank' rel="noreferrer">
                  Canariwiki
                </a>
              </li>
              <li>
                <a href='https://www3.gobiernodecanarias.org/medusa/ecoescuela/recursosdigitales/' target='_blank' rel="noreferrer">
                  Recursos educativos
                </a>
              </li>
              <li>
                <a href='https://www3.gobiernodecanarias.org/medusa/mediateca/' target='_blank' rel="noreferrer">
                  Mediateca
                </a>
              </li>
            </ul>
          </li>
          <li>
            <Link to='/'>Home</Link>
          </li>
        </ul>
      </Header >

      <div id='Body' onClick={toggleVis}>
        <Suspense fallback={<Loader />}>
          <img src={Banner} alt="Banner" />
          <Carousel responsive={responsive}>
            {listObj}
          </Carousel>
        </Suspense>

        <Routes>
          <Route path='/' element={
            <Portada>
              <div className='max'>
                <img src={ani} alt='Portada_Gif'></img>
                <Link to='/home' className='linkPort'>
                  <ButtonCom>comenzar</ButtonCom>
                </Link>
                <Line />
                <Footer>
                  <img src={'/images/logos/TODOS_LOGOS_NEGRO.png'} alt='Todos' />
                </Footer>
              </div>

            </Portada>

          } />
          <Route path="/home" element={
            <div>
              <Recom>Recomendados</Recom>
              <ContDiv>
                <ContRec>
                  <img onClick={() => setModalInfo({
                    isOpen: true, tittleModal: "Estructura de la Tierra", descModal: "Volcanes, 2023",
                    contentModal: <>
                      <video controls>
                        <source src={'/videos/CD-21-0000010 Vídeo educativo complejo Volcanes-Estructura de la tierra.mp4'} type="video/mp4" />
                      </video>
                    </>
                  })} style={{ borderRadius: "10px" }} src={'/videos/mini/estructura_de_la_tierra.jpg'} alt='Ejemplo'></img>

                </ContRec>
                <ContRec>
                  <a href='/animaciones/alisios' target='_blank' rel="noreferrer">
                    <img style={{ borderRadius: "10px" }} src={'/animaciones/miniatura_alisios.jpg'} alt='Ejemplo'></img>
                  </a>
                </ContRec>
                <ContRec>
                  <a href='https://volcanweb.mitcastudionet.com/tema-1/estructura-de-la-tierra/' target='_blank' rel="noreferrer">
                    <img style={{ borderRadius: "10px" }} src={'/objetoEdu/miniatura_Objeto_educativo_ciencia_de_la_tierra.jpg'} alt='Ejemplo'></img>
                  </a>
                </ContRec>
                <ContRec>
                  <img onClick={() => setModalInfo({
                    isOpen: true, tittleModal: "Estrella de mar roja", descModal: "Fauna marina, 2023",
                    contentModal: <>
                      <img src={'/images/fotografias/fotos/fotografía_marinas_echinaster_sepositus.jpg'} alt='Fotografia'/>
                    </>
                  })} style={{ borderRadius: "10px" }} src={'/images/fotografias/miniatura_marinas_echinaster_sepositus.jpg'} alt='Ejemplo'></img>

                </ContRec>
                <ContRec>
                  <img onClick={() => setModalInfo2({
                    isOpen: true, tittleModal: "Estación Espacial", descModal: "",
                    contentModal: <>
                      <Estacion></Estacion>
                    </>
                  })} style={{ borderRadius: "10px" }} src={'/3dModels/minis/miniatura_Objeto_3D_complejo_Volcanes_Estación_Espacial.jpg'} alt='Ejemplo'></img>
                </ContRec>
              </ContDiv>
            </div>
          } />
          <Route path="/informacion" element={
            <>
              <Recom>+ Información</Recom>
              <MyBook></MyBook>
              <div style={{color: "black", marginBottom: "30px"}}>Hello</div>
            </>
          } />
          <Route path="/3d" element={
            <>
              <Recom>Objetos 3D</Recom>
              <Card3D data={list3D} />
            </>
          } />
          <Route path="/pruebaRv" element={
            <>
              <div onClick={() => setModalInfo({
                isOpen: true, tittleModal: "", descModal: "",
                contentModal: <Suspense fallback={<Loader></Loader>}>
                  <Canvas>
                    <Escena2 />
                  </Canvas>


                  {/*<Canvas>
                    <PerspectiveCamera makeDefault position={[0, 0, 0]}/>
                    <ambientLight intensity={1} />
                    <directionalLight position={[2, 5, 10]} intensity={1}></directionalLight>
                    <OrbitControls enableZoom={false}
                      enablePan={false}
                      minPolarAngle={Math.PI / 2}></OrbitControls>
                    <pointLight position={[0, 0, 0]} intensity={1}></pointLight>
                    <MuseoEstructura scale={0.5} />
              </Canvas>*/}
                </Suspense>
              })}> Click</div>

            </>
          } />
          <Route path='/videos' element={
            <>
              <Recom>Vídeos</Recom>
              <Card data={listVid} type={'video'} />
            </>
          } />
          <Route path='/ilustraciones' element={
            <>
              <Recom>Ilustraciones</Recom>
              <Card data={listIlus} type={'ilustraciones'} />
            </>
          } />
          <Route path='/podcast' element={
            <>
              <Recom>Pódcast</Recom>
              <Card data={listPod} type={'podcast'} />
            </>
          } />
          <Route path='/objetosEducativos' element={
            <>
              <Recom>Objetos educativos</Recom>
              <CardLink data={listObjEdu} />
            </>
          } />
          <Route path='/animaciones' element={
            <>
              <Recom>Animaciones</Recom>
              <CardLink data={listAni} type={'animacion'} />
            </>
          } />
          <Route path='/iconos' element={
            <>
              <Recom>Iconos</Recom>
              <Card data={listIcon} type={'icono'} />
            </>
          } />
          <Route path='/laminas' element={
            <>
              <Recom>Láminas</Recom>
              <Card data={listLam} type={'laminas'} />
            </>
          } />
          <Route path='/infografias' element={
            <>
              <Recom>Infografías</Recom>
              <Card data={listInfo} type={'infografias'} />
            </>
          } />
          <Route path='/fotos' element={
            <>
              <Recom>Fotos</Recom>
              <Card data={listFotos} type='icono' />
            </>
          } />
          <Route path='/rv' element={
            <>
              <Recom>Vídeos</Recom>
              <Card data={listRv} type={'video'} />
            </>
          } />
          <Route path='/articulos' element={
            <>
              <Recom>Artículos</Recom>
              <CardLink data={listArtic} type={'animacion'} />
            </>
          } />
        </Routes>
        <Line />
        <Suspense fallback={<Loader></Loader>}>
          <Modal open={modalInfo.isOpen} onClose={() => setModalInfo({
            isOpen: false, tittleModal: '',
            contentModal: ''
          })}>
            <Suspense fallback={<Loader></Loader>}>
              <CenterCont>
                {modalInfo.contentModal}
                <div>
                  <H1>{modalInfo.tittleModal}</H1>
                  <H2>{modalInfo.descModal}</H2>
                </div>
              </CenterCont>
            </Suspense>

          </Modal>

          <Modal open={modalInfo2.isOpen} onClose={() => setModalInfo2({
            isOpen: false, tittleModal: '',
            contentModal: ''
          })}>
            {modalInfo2.contentModal}
          </Modal>
        </Suspense>

        <Footer>
          <img src={'/images/logos/TODOS_LOGOS_BLANCO.png'} alt='Todos' />
        </Footer>
      </div>

    </BrowserRouter >

  );
}



export default App;
