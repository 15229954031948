import React, { useRef, useState } from "react";
import styled from 'styled-components'

// 3D Imports

import * as THREE from 'three'

import { Canvas, useFrame, useThree, extend } from '@react-three/fiber';
import { useGLTF, Text } from '@react-three/drei';

import { OrbitControls } from "three/examples/jsm/controls/OrbitControls";

import { MuseoEstructura } from "../Museo_estructura_";

const Container3d = styled.div`
    height: -webkit-fill-available;
    width: -webkit-fill-available;
    position: absolute;
    padding-bottom: 15px;
    padding-right: 50px
`

extend({ OrbitControls })

function CameraControl() {
    const { camera, gl } = useThree()
    const controlsRef = useRef()
    const [position, setPosition] = useState([0, 0, 0])

    useFrame(() => {
        controlsRef.current.target.set(...camera.position)
        controlsRef.current.update()
    })

    const handleClick = (newPosition) => {
        // Desactiva OrbitControls
        controlsRef.current.dispose()

        // Mueve la cámara
        setPosition(newPosition)
        camera.position.set(...newPosition)
        camera.lookAt(0, 0, 0)

        // Reactiva OrbitControls
        controlsRef.current = new OrbitControls(camera, gl.domElement)
    }

    return (
        <>
            <orbitControls ref={controlsRef} args={[camera, gl.domElement]} target={camera}/>
            {/* Aquí va el resto de tu escena */}
            <Text position={[1, -1, 1]} onClick={() => handleClick([1, -1, 1])}>Posicion 1</Text>
            <Text position={[4, -1, 4]} onClick={() => handleClick([4, -1, 4])}>Posicion 2</Text>
        </>
    )
}

export default function Escena2() {

    return (
        <Container3d>
            <Canvas onCreated={({ gl }) => {
                gl.shadowMap.enabled = true;
                gl.shadowMap.type = THREE.PCFSoftShadowMap;
            }}>
                <ambientLight intensity={1} />
                <directionalLight position={[-2., 5, 2]} intensity={1} />
                <CameraControl />
                <MuseoEstructura></MuseoEstructura>

            </Canvas>
        </Container3d>
    );
};
