/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
Command: npx gltfjsx@6.2.14 museo_estructura_.glb 
*/

import React, { useRef } from 'react'
import { useGLTF } from '@react-three/drei'

export function MuseoEstructura(props) {
  const group = useRef()
  const { nodes, materials } = useGLTF('/3dModels/museo_estructura_.glb')
  return (
    <group ref={group} {...props} dispose={null}>
      <group name="Scene">
        <group name="Group002" scale={0.01}>
          <group name="divan_sofa" position={[145.317, -279.417, 600.916]} scale={0.5}>
            <group name="Group001">
              <group name="chandon_droite" position={[-0.663, 0, -0.804]} rotation={[Math.PI, -0.015, 0]} scale={-0.171}>
                <group name="Box008" position={[-84.849, -176.811, 113.695]} />
                <group name="Cloth_Shape002" position={[294.092, 174.539, -187.401]} rotation={[-0.015, 0.008, 0.058]} scale={0.825} />
                <group name="Cloth_Shape003" position={[610.493, 156.534, -95.266]} rotation={[-0.035, -0.13, -0.098]} scale={0.825} />
                <group name="Line324" position={[1002.783, 117.603, 212.597]} rotation={[1.567, 0.003, -0.592]} />
                <group name="Object006" position={[817.476, 35.522, 346.771]} rotation={[Math.PI / 2, 0, 0]} scale={0.954} />
                <group name="Plane004" position={[-71.909, -53.913, -346.034]} rotation={[Math.PI / 2, 0, 0]} scale={0.954} />
              </group>
            </group>
            <group name="SkeletalMeshComponent0">
              <mesh name="Mesh349" geometry={nodes.Mesh349.geometry} material={materials['Material #123']} />
              <mesh name="Mesh349_1" geometry={nodes.Mesh349_1.geometry} material={materials['Material #57']} />
            </group>
          </group>
          <group name="GroupActor0" position={[376.657, -269.791, 978.981]} rotation={[0, 1.571, 0]} />
          <group name="GroupActor1" position={[117.071, -286.079, 1063.302]} rotation={[-Math.PI, 0, -Math.PI]} scale={[0.201, 0.15, 0.15]} />
          <group name="museo" position={[12.245, -101.173, 267.857]} rotation={[0, -1.571, 0]} scale={[22.929, 22.286, 22.929]}>
            <group name="Box005" position={[44.323, -10.655, -0.17]} rotation={[0, -1.571, 0]} scale={[4.744, 4.744, 0.651]}>
              <mesh name="Mesh452" geometry={nodes.Mesh452.geometry} material={materials['02 - Default']} />
              <mesh name="Mesh452_1" geometry={nodes.Mesh452_1.geometry} material={materials['Material #51']} />
              <mesh name="Mesh452_2" geometry={nodes.Mesh452_2.geometry} material={materials['Material #52']} />
              <mesh name="Mesh452_3" geometry={nodes.Mesh452_3.geometry} material={materials['Material #53']} />
              <mesh name="Mesh452_4" geometry={nodes.Mesh452_4.geometry} material={materials['Material #54']} />
              <mesh name="Mesh452_5" geometry={nodes.Mesh452_5.geometry} material={materials['Material #55']} />
              <mesh name="Mesh452_6" geometry={nodes.Mesh452_6.geometry} material={materials['Material #56']} />
            </group>
            <group name="Box006" position={[-55.543, -10.491, 0.046]}>
              <mesh name="Mesh451" geometry={nodes.Mesh451.geometry} material={materials['02 - Default']} />
              <mesh name="Mesh451_1" geometry={nodes.Mesh451_1.geometry} material={materials['Material #51']} />
              <mesh name="Mesh451_2" geometry={nodes.Mesh451_2.geometry} material={materials['Material #52']} />
              <mesh name="Mesh451_3" geometry={nodes.Mesh451_3.geometry} material={materials['Material #54']} />
              <mesh name="Mesh451_4" geometry={nodes.Mesh451_4.geometry} material={materials['Material #55']} />
            </group>
          </group>
          <group name="SkyLight" position={[195.214, -320.522, 449.542]} rotation={[0, -1.571, 0]} />
          <group name="Text3DActor" position={[423.095, -211.285, -638.034]} rotation={[0, 0.028, 0]} scale={0.042}>
            <group name="StaticMeshComponent0_71" position={[-0.001, 0, 0]}>
              <mesh name="Mesh040" geometry={nodes.Mesh040.geometry} material={materials.MI_FlatColor04} />
              <mesh name="Mesh040_1" geometry={nodes.Mesh040_1.geometry} material={materials['MI_Copper_-cu-_scnnfhbp_4K']} />
              <mesh name="Mesh040_2" geometry={nodes.Mesh040_2.geometry} material={materials['MI_Copper_-cu-_scnnfhbp_4K']} />
            </group>
            <group name="StaticMeshComponent10_81" position={[-0.001, 0, 372]}>
              <mesh name="Mesh035" geometry={nodes.Mesh035.geometry} material={materials.MI_FlatColor04} />
              <mesh name="Mesh035_1" geometry={nodes.Mesh035_1.geometry} material={materials['MI_Copper_-cu-_scnnfhbp_4K']} />
              <mesh name="Mesh035_2" geometry={nodes.Mesh035_2.geometry} material={materials['MI_Copper_-cu-_scnnfhbp_4K']} />
            </group>
            <group name="StaticMeshComponent11_82" position={[-0.002, 0, 426.002]}>
              <mesh name="Mesh031" geometry={nodes.Mesh031.geometry} material={materials.MI_FlatColor04} />
              <mesh name="Mesh031_1" geometry={nodes.Mesh031_1.geometry} material={materials['MI_Copper_-cu-_scnnfhbp_4K']} />
              <mesh name="Mesh031_2" geometry={nodes.Mesh031_2.geometry} material={materials['MI_Copper_-cu-_scnnfhbp_4K']} />
            </group>
            <group name="StaticMeshComponent12_83" position={[0, 0, 469]}>
              <mesh name="Mesh027" geometry={nodes.Mesh027.geometry} material={materials.MI_FlatColor04} />
              <mesh name="Mesh027_1" geometry={nodes.Mesh027_1.geometry} material={materials['MI_Copper_-cu-_scnnfhbp_4K']} />
              <mesh name="Mesh027_2" geometry={nodes.Mesh027_2.geometry} material={materials['MI_Copper_-cu-_scnnfhbp_4K']} />
            </group>
            <group name="StaticMeshComponent13_84" position={[-0.001, 0, 486.001]}>
              <mesh name="Mesh045" geometry={nodes.Mesh045.geometry} material={materials.MI_FlatColor04} />
              <mesh name="Mesh045_1" geometry={nodes.Mesh045_1.geometry} material={materials['MI_Copper_-cu-_scnnfhbp_4K']} />
              <mesh name="Mesh045_2" geometry={nodes.Mesh045_2.geometry} material={materials['MI_Copper_-cu-_scnnfhbp_4K']} />
            </group>
            <group name="StaticMeshComponent14_85" position={[0.001, 0, 518]}>
              <mesh name="Mesh049" geometry={nodes.Mesh049.geometry} material={materials.MI_FlatColor04} />
              <mesh name="Mesh049_1" geometry={nodes.Mesh049_1.geometry} material={materials['MI_Copper_-cu-_scnnfhbp_4K']} />
              <mesh name="Mesh049_2" geometry={nodes.Mesh049_2.geometry} material={materials['MI_Copper_-cu-_scnnfhbp_4K']} />
            </group>
            <group name="StaticMeshComponent15_86" position={[0, 0, 561.002]}>
              <mesh name="Mesh053" geometry={nodes.Mesh053.geometry} material={materials.MI_FlatColor04} />
              <mesh name="Mesh053_1" geometry={nodes.Mesh053_1.geometry} material={materials['MI_Copper_-cu-_scnnfhbp_4K']} />
              <mesh name="Mesh053_2" geometry={nodes.Mesh053_2.geometry} material={materials['MI_Copper_-cu-_scnnfhbp_4K']} />
            </group>
            <group name="StaticMeshComponent16_87" position={[-0.001, -77, 0]}>
              <mesh name="Mesh057" geometry={nodes.Mesh057.geometry} material={materials.MI_FlatColor04} />
              <mesh name="Mesh057_1" geometry={nodes.Mesh057_1.geometry} material={materials['MI_Copper_-cu-_scnnfhbp_4K']} />
              <mesh name="Mesh057_2" geometry={nodes.Mesh057_2.geometry} material={materials['MI_Copper_-cu-_scnnfhbp_4K']} />
            </group>
            <group name="StaticMeshComponent17_88" position={[0, -77, 35.001]}>
              <mesh name="Mesh042" geometry={nodes.Mesh042.geometry} material={materials.MI_FlatColor04} />
              <mesh name="Mesh042_1" geometry={nodes.Mesh042_1.geometry} material={materials['MI_Copper_-cu-_scnnfhbp_4K']} />
              <mesh name="Mesh042_2" geometry={nodes.Mesh042_2.geometry} material={materials['MI_Copper_-cu-_scnnfhbp_4K']} />
            </group>
            <group name="StaticMeshComponent18_89" position={[-0.002, -77, 74.002]}>
              <mesh name="Mesh038" geometry={nodes.Mesh038.geometry} material={materials.MI_FlatColor04} />
              <mesh name="Mesh038_1" geometry={nodes.Mesh038_1.geometry} material={materials['MI_Copper_-cu-_scnnfhbp_4K']} />
              <mesh name="Mesh038_2" geometry={nodes.Mesh038_2.geometry} material={materials['MI_Copper_-cu-_scnnfhbp_4K']} />
            </group>
            <group name="StaticMeshComponent19_90" position={[-0.001, -77, 111]}>
              <mesh name="Mesh034" geometry={nodes.Mesh034.geometry} material={materials.MI_FlatColor04} />
              <mesh name="Mesh034_1" geometry={nodes.Mesh034_1.geometry} material={materials['MI_Copper_-cu-_scnnfhbp_4K']} />
              <mesh name="Mesh034_2" geometry={nodes.Mesh034_2.geometry} material={materials['MI_Copper_-cu-_scnnfhbp_4K']} />
            </group>
            <group name="StaticMeshComponent1_72" position={[-0.002, 0, 46.001]}>
              <mesh name="Mesh036" geometry={nodes.Mesh036.geometry} material={materials.MI_FlatColor04} />
              <mesh name="Mesh036_1" geometry={nodes.Mesh036_1.geometry} material={materials['MI_Copper_-cu-_scnnfhbp_4K']} />
              <mesh name="Mesh036_2" geometry={nodes.Mesh036_2.geometry} material={materials['MI_Copper_-cu-_scnnfhbp_4K']} />
            </group>
            <group name="StaticMeshComponent20_91" position={[-0.001, -77, 169.001]}>
              <mesh name="Mesh030" geometry={nodes.Mesh030.geometry} material={materials.MI_FlatColor04} />
              <mesh name="Mesh030_1" geometry={nodes.Mesh030_1.geometry} material={materials['MI_Copper_-cu-_scnnfhbp_4K']} />
              <mesh name="Mesh030_2" geometry={nodes.Mesh030_2.geometry} material={materials['MI_Copper_-cu-_scnnfhbp_4K']} />
            </group>
            <group name="StaticMeshComponent21_92" position={[0, -77, 215.001]}>
              <mesh name="Mesh026" geometry={nodes.Mesh026.geometry} material={materials.MI_FlatColor04} />
              <mesh name="Mesh026_1" geometry={nodes.Mesh026_1.geometry} material={materials['MI_Copper_-cu-_scnnfhbp_4K']} />
              <mesh name="Mesh026_2" geometry={nodes.Mesh026_2.geometry} material={materials['MI_Copper_-cu-_scnnfhbp_4K']} />
            </group>
            <group name="StaticMeshComponent22_93" position={[-0.001, -77, 252.002]}>
              <mesh name="Mesh046" geometry={nodes.Mesh046.geometry} material={materials.MI_FlatColor04} />
              <mesh name="Mesh046_1" geometry={nodes.Mesh046_1.geometry} material={materials['MI_Copper_-cu-_scnnfhbp_4K']} />
              <mesh name="Mesh046_2" geometry={nodes.Mesh046_2.geometry} material={materials['MI_Copper_-cu-_scnnfhbp_4K']} />
            </group>
            <group name="StaticMeshComponent23_94" position={[-0.002, -77, 291.002]}>
              <mesh name="Mesh050" geometry={nodes.Mesh050.geometry} material={materials.MI_FlatColor04} />
              <mesh name="Mesh050_1" geometry={nodes.Mesh050_1.geometry} material={materials['MI_Copper_-cu-_scnnfhbp_4K']} />
              <mesh name="Mesh050_2" geometry={nodes.Mesh050_2.geometry} material={materials['MI_Copper_-cu-_scnnfhbp_4K']} />
            </group>
            <group name="StaticMeshComponent24_95" position={[0, -77, 316.998]}>
              <mesh name="Mesh054" geometry={nodes.Mesh054.geometry} material={materials.MI_FlatColor04} />
              <mesh name="Mesh054_1" geometry={nodes.Mesh054_1.geometry} material={materials['MI_Copper_-cu-_scnnfhbp_4K']} />
              <mesh name="Mesh054_2" geometry={nodes.Mesh054_2.geometry} material={materials['MI_Copper_-cu-_scnnfhbp_4K']} />
            </group>
            <group name="StaticMeshComponent25_96" position={[-0.001, -77, 334.002]}>
              <mesh name="Mesh058" geometry={nodes.Mesh058.geometry} material={materials.MI_FlatColor04} />
              <mesh name="Mesh058_1" geometry={nodes.Mesh058_1.geometry} material={materials['MI_Copper_-cu-_scnnfhbp_4K']} />
              <mesh name="Mesh058_2" geometry={nodes.Mesh058_2.geometry} material={materials['MI_Copper_-cu-_scnnfhbp_4K']} />
            </group>
            <group name="StaticMeshComponent26_97" position={[-0.001, -77, 363.999]}>
              <mesh name="Mesh041" geometry={nodes.Mesh041.geometry} material={materials.MI_FlatColor04} />
              <mesh name="Mesh041_1" geometry={nodes.Mesh041_1.geometry} material={materials['MI_Copper_-cu-_scnnfhbp_4K']} />
              <mesh name="Mesh041_2" geometry={nodes.Mesh041_2.geometry} material={materials['MI_Copper_-cu-_scnnfhbp_4K']} />
            </group>
            <group name="StaticMeshComponent27_98" position={[-0.002, -77, 390.003]}>
              <mesh name="Mesh037" geometry={nodes.Mesh037.geometry} material={materials.MI_FlatColor04} />
              <mesh name="Mesh037_1" geometry={nodes.Mesh037_1.geometry} material={materials['MI_Copper_-cu-_scnnfhbp_4K']} />
              <mesh name="Mesh037_2" geometry={nodes.Mesh037_2.geometry} material={materials['MI_Copper_-cu-_scnnfhbp_4K']} />
            </group>
            <group name="StaticMeshComponent28_99" position={[-0.001, -77, 446.002]}>
              <mesh name="Mesh033" geometry={nodes.Mesh033.geometry} material={materials.MI_FlatColor04} />
              <mesh name="Mesh033_1" geometry={nodes.Mesh033_1.geometry} material={materials['MI_Copper_-cu-_scnnfhbp_4K']} />
              <mesh name="Mesh033_2" geometry={nodes.Mesh033_2.geometry} material={materials['MI_Copper_-cu-_scnnfhbp_4K']} />
            </group>
            <group name="StaticMeshComponent29_100" position={[-0.002, -77, 493.001]}>
              <mesh name="Mesh029" geometry={nodes.Mesh029.geometry} material={materials.MI_FlatColor04} />
              <mesh name="Mesh029_1" geometry={nodes.Mesh029_1.geometry} material={materials['MI_Copper_-cu-_scnnfhbp_4K']} />
              <mesh name="Mesh029_2" geometry={nodes.Mesh029_2.geometry} material={materials['MI_Copper_-cu-_scnnfhbp_4K']} />
            </group>
            <group name="StaticMeshComponent2_73" position={[-0.002, 0, 84.001]}>
              <mesh name="Mesh032" geometry={nodes.Mesh032.geometry} material={materials.MI_FlatColor04} />
              <mesh name="Mesh032_1" geometry={nodes.Mesh032_1.geometry} material={materials['MI_Copper_-cu-_scnnfhbp_4K']} />
              <mesh name="Mesh032_2" geometry={nodes.Mesh032_2.geometry} material={materials['MI_Copper_-cu-_scnnfhbp_4K']} />
            </group>
            <group name="StaticMeshComponent30_101" position={[0, -77, 531.002]}>
              <mesh name="Mesh025" geometry={nodes.Mesh025.geometry} material={materials.MI_FlatColor04} />
              <mesh name="Mesh025_1" geometry={nodes.Mesh025_1.geometry} material={materials['MI_Copper_-cu-_scnnfhbp_4K']} />
              <mesh name="Mesh025_2" geometry={nodes.Mesh025_2.geometry} material={materials['MI_Copper_-cu-_scnnfhbp_4K']} />
            </group>
            <group name="StaticMeshComponent31_102" position={[0, -77, 556.999]}>
              <mesh name="Mesh047" geometry={nodes.Mesh047.geometry} material={materials.MI_FlatColor04} />
              <mesh name="Mesh047_1" geometry={nodes.Mesh047_1.geometry} material={materials['MI_Copper_-cu-_scnnfhbp_4K']} />
              <mesh name="Mesh047_2" geometry={nodes.Mesh047_2.geometry} material={materials['MI_Copper_-cu-_scnnfhbp_4K']} />
            </group>
            <group name="StaticMeshComponent32_103" position={[0, -77, 595.001]}>
              <mesh name="Mesh051" geometry={nodes.Mesh051.geometry} material={materials.MI_FlatColor04} />
              <mesh name="Mesh051_1" geometry={nodes.Mesh051_1.geometry} material={materials['MI_Copper_-cu-_scnnfhbp_4K']} />
              <mesh name="Mesh051_2" geometry={nodes.Mesh051_2.geometry} material={materials['MI_Copper_-cu-_scnnfhbp_4K']} />
            </group>
            <group name="StaticMeshComponent33_104" position={[0, -77, 633]}>
              <mesh name="Mesh055" geometry={nodes.Mesh055.geometry} material={materials.MI_FlatColor04} />
              <mesh name="Mesh055_1" geometry={nodes.Mesh055_1.geometry} material={materials['MI_Copper_-cu-_scnnfhbp_4K']} />
              <mesh name="Mesh055_2" geometry={nodes.Mesh055_2.geometry} material={materials['MI_Copper_-cu-_scnnfhbp_4K']} />
            </group>
            <group name="StaticMeshComponent34_105" position={[0.001, -77, 654.999]}>
              <mesh name="Mesh059" geometry={nodes.Mesh059.geometry} material={materials.MI_FlatColor04} />
              <mesh name="Mesh059_1" geometry={nodes.Mesh059_1.geometry} material={materials['MI_Copper_-cu-_scnnfhbp_4K']} />
              <mesh name="Mesh059_2" geometry={nodes.Mesh059_2.geometry} material={materials['MI_Copper_-cu-_scnnfhbp_4K']} />
            </group>
            <group name="StaticMeshComponent3_74" position={[-0.002, 0, 110.001]}>
              <mesh name="Mesh028" geometry={nodes.Mesh028.geometry} material={materials.MI_FlatColor04} />
              <mesh name="Mesh028_1" geometry={nodes.Mesh028_1.geometry} material={materials['MI_Copper_-cu-_scnnfhbp_4K']} />
              <mesh name="Mesh028_2" geometry={nodes.Mesh028_2.geometry} material={materials['MI_Copper_-cu-_scnnfhbp_4K']} />
            </group>
            <group name="StaticMeshComponent4_75" position={[-0.001, 0, 132.001]}>
              <mesh name="Mesh044" geometry={nodes.Mesh044.geometry} material={materials.MI_FlatColor04} />
              <mesh name="Mesh044_1" geometry={nodes.Mesh044_1.geometry} material={materials['MI_Copper_-cu-_scnnfhbp_4K']} />
              <mesh name="Mesh044_2" geometry={nodes.Mesh044_2.geometry} material={materials['MI_Copper_-cu-_scnnfhbp_4K']} />
            </group>
            <group name="StaticMeshComponent5_76" position={[-0.001, 0, 169.001]}>
              <mesh name="Mesh048" geometry={nodes.Mesh048.geometry} material={materials.MI_FlatColor04} />
              <mesh name="Mesh048_1" geometry={nodes.Mesh048_1.geometry} material={materials['MI_Copper_-cu-_scnnfhbp_4K']} />
              <mesh name="Mesh048_2" geometry={nodes.Mesh048_2.geometry} material={materials['MI_Copper_-cu-_scnnfhbp_4K']} />
            </group>
            <group name="StaticMeshComponent6_77" position={[0, 0, 195.001]}>
              <mesh name="Mesh052" geometry={nodes.Mesh052.geometry} material={materials.MI_FlatColor04} />
              <mesh name="Mesh052_1" geometry={nodes.Mesh052_1.geometry} material={materials['MI_Copper_-cu-_scnnfhbp_4K']} />
              <mesh name="Mesh052_2" geometry={nodes.Mesh052_2.geometry} material={materials['MI_Copper_-cu-_scnnfhbp_4K']} />
            </group>
            <group name="StaticMeshComponent7_78" position={[-0.001, 0, 254.998]}>
              <mesh name="Mesh056" geometry={nodes.Mesh056.geometry} material={materials.MI_FlatColor04} />
              <mesh name="Mesh056_1" geometry={nodes.Mesh056_1.geometry} material={materials['MI_Copper_-cu-_scnnfhbp_4K']} />
              <mesh name="Mesh056_2" geometry={nodes.Mesh056_2.geometry} material={materials['MI_Copper_-cu-_scnnfhbp_4K']} />
            </group>
            <group name="StaticMeshComponent8_79" position={[-0.001, 0, 298.002]}>
              <mesh name="Mesh043" geometry={nodes.Mesh043.geometry} material={materials.MI_FlatColor04} />
              <mesh name="Mesh043_1" geometry={nodes.Mesh043_1.geometry} material={materials['MI_Copper_-cu-_scnnfhbp_4K']} />
              <mesh name="Mesh043_2" geometry={nodes.Mesh043_2.geometry} material={materials['MI_Copper_-cu-_scnnfhbp_4K']} />
            </group>
            <group name="StaticMeshComponent9_80" position={[-0.002, 0, 336.002]}>
              <mesh name="Mesh039" geometry={nodes.Mesh039.geometry} material={materials.MI_FlatColor04} />
              <mesh name="Mesh039_1" geometry={nodes.Mesh039_1.geometry} material={materials['MI_Copper_-cu-_scnnfhbp_4K']} />
              <mesh name="Mesh039_2" geometry={nodes.Mesh039_2.geometry} material={materials['MI_Copper_-cu-_scnnfhbp_4K']} />
            </group>
          </group>
          <group name="Text3DActor10" position={[-376.463, -170.259, -726.424]} rotation={[-Math.PI, 0, -Math.PI]} scale={0.068}>
            <group name="StaticMeshComponent0_73" position={[0, 0, 95.001]}>
              <mesh name="Mesh409" geometry={nodes.Mesh409.geometry} material={materials.WorldGridMaterial} />
              <mesh name="Mesh409_1" geometry={nodes.Mesh409_1.geometry} material={materials.WorldGridMaterial} />
              <mesh name="Mesh409_2" geometry={nodes.Mesh409_2.geometry} material={materials.BasicShapeMaterial} />
            </group>
            <group name="StaticMeshComponent10_83" position={[-0.001, 0, 487]}>
              <mesh name="Mesh406" geometry={nodes.Mesh406.geometry} material={materials.WorldGridMaterial} />
              <mesh name="Mesh406_1" geometry={nodes.Mesh406_1.geometry} material={materials.WorldGridMaterial} />
              <mesh name="Mesh406_2" geometry={nodes.Mesh406_2.geometry} material={materials.BasicShapeMaterial} />
            </group>
            <group name="StaticMeshComponent11_84" position={[0, 0, 524.001]}>
              <mesh name="Mesh402" geometry={nodes.Mesh402.geometry} material={materials.WorldGridMaterial} />
              <mesh name="Mesh402_1" geometry={nodes.Mesh402_1.geometry} material={materials.WorldGridMaterial} />
              <mesh name="Mesh402_2" geometry={nodes.Mesh402_2.geometry} material={materials.BasicShapeMaterial} />
            </group>
            <group name="StaticMeshComponent12_85" position={[0, 0, 585.001]}>
              <mesh name="Mesh398" geometry={nodes.Mesh398.geometry} material={materials.WorldGridMaterial} />
              <mesh name="Mesh398_1" geometry={nodes.Mesh398_1.geometry} material={materials.WorldGridMaterial} />
              <mesh name="Mesh398_2" geometry={nodes.Mesh398_2.geometry} material={materials.BasicShapeMaterial} />
            </group>
            <group name="StaticMeshComponent13_86" position={[0, -77, 0.001]}>
              <mesh name="Mesh415" geometry={nodes.Mesh415.geometry} material={materials.WorldGridMaterial} />
              <mesh name="Mesh415_1" geometry={nodes.Mesh415_1.geometry} material={materials.WorldGridMaterial} />
              <mesh name="Mesh415_2" geometry={nodes.Mesh415_2.geometry} material={materials.BasicShapeMaterial} />
            </group>
            <group name="StaticMeshComponent14_87" position={[0, -77, 50.001]}>
              <mesh name="Mesh419" geometry={nodes.Mesh419.geometry} material={materials.WorldGridMaterial} />
              <mesh name="Mesh419_1" geometry={nodes.Mesh419_1.geometry} material={materials.WorldGridMaterial} />
              <mesh name="Mesh419_2" geometry={nodes.Mesh419_2.geometry} material={materials.BasicShapeMaterial} />
            </group>
            <group name="StaticMeshComponent15_88" position={[0, -77, 88.001]}>
              <mesh name="Mesh423" geometry={nodes.Mesh423.geometry} material={materials.WorldGridMaterial} />
              <mesh name="Mesh423_1" geometry={nodes.Mesh423_1.geometry} material={materials.WorldGridMaterial} />
              <mesh name="Mesh423_2" geometry={nodes.Mesh423_2.geometry} material={materials.BasicShapeMaterial} />
            </group>
            <group name="StaticMeshComponent16_89" position={[0, -77, 105.001]}>
              <mesh name="Mesh427" geometry={nodes.Mesh427.geometry} material={materials.WorldGridMaterial} />
              <mesh name="Mesh427_1" geometry={nodes.Mesh427_1.geometry} material={materials.WorldGridMaterial} />
              <mesh name="Mesh427_2" geometry={nodes.Mesh427_2.geometry} material={materials.BasicShapeMaterial} />
            </group>
            <group name="StaticMeshComponent17_90" position={[0, -77, 143.002]}>
              <mesh name="Mesh431" geometry={nodes.Mesh431.geometry} material={materials.WorldGridMaterial} />
              <mesh name="Mesh431_1" geometry={nodes.Mesh431_1.geometry} material={materials.WorldGridMaterial} />
              <mesh name="Mesh431_2" geometry={nodes.Mesh431_2.geometry} material={materials.BasicShapeMaterial} />
            </group>
            <group name="StaticMeshComponent18_91" position={[0, -77, 182]}>
              <mesh name="Mesh411" geometry={nodes.Mesh411.geometry} material={materials.WorldGridMaterial} />
              <mesh name="Mesh411_1" geometry={nodes.Mesh411_1.geometry} material={materials.WorldGridMaterial} />
              <mesh name="Mesh411_2" geometry={nodes.Mesh411_2.geometry} material={materials.BasicShapeMaterial} />
            </group>
            <group name="StaticMeshComponent19_92" position={[-0.001, -77, 221.001]}>
              <mesh name="Mesh407" geometry={nodes.Mesh407.geometry} material={materials.WorldGridMaterial} />
              <mesh name="Mesh407_1" geometry={nodes.Mesh407_1.geometry} material={materials.WorldGridMaterial} />
              <mesh name="Mesh407_2" geometry={nodes.Mesh407_2.geometry} material={materials.BasicShapeMaterial} />
            </group>
            <group name="StaticMeshComponent1_74" position={[0, 0, 137]}>
              <mesh name="Mesh405" geometry={nodes.Mesh405.geometry} material={materials.WorldGridMaterial} />
              <mesh name="Mesh405_1" geometry={nodes.Mesh405_1.geometry} material={materials.WorldGridMaterial} />
              <mesh name="Mesh405_2" geometry={nodes.Mesh405_2.geometry} material={materials.BasicShapeMaterial} />
            </group>
            <group name="StaticMeshComponent20_93" position={[-0.001, -77, 259.001]}>
              <mesh name="Mesh403" geometry={nodes.Mesh403.geometry} material={materials.WorldGridMaterial} />
              <mesh name="Mesh403_1" geometry={nodes.Mesh403_1.geometry} material={materials.WorldGridMaterial} />
              <mesh name="Mesh403_2" geometry={nodes.Mesh403_2.geometry} material={materials.BasicShapeMaterial} />
            </group>
            <group name="StaticMeshComponent21_94" position={[-0.001, -77, 304.002]}>
              <mesh name="Mesh399" geometry={nodes.Mesh399.geometry} material={materials.WorldGridMaterial} />
              <mesh name="Mesh399_1" geometry={nodes.Mesh399_1.geometry} material={materials.WorldGridMaterial} />
              <mesh name="Mesh399_2" geometry={nodes.Mesh399_2.geometry} material={materials.BasicShapeMaterial} />
            </group>
            <group name="StaticMeshComponent22_95" position={[-0.001, -77, 336]}>
              <mesh name="Mesh414" geometry={nodes.Mesh414.geometry} material={materials.WorldGridMaterial} />
              <mesh name="Mesh414_1" geometry={nodes.Mesh414_1.geometry} material={materials.WorldGridMaterial} />
              <mesh name="Mesh414_2" geometry={nodes.Mesh414_2.geometry} material={materials.BasicShapeMaterial} />
            </group>
            <group name="StaticMeshComponent23_96" position={[-0.001, -77, 375.001]}>
              <mesh name="Mesh418" geometry={nodes.Mesh418.geometry} material={materials.WorldGridMaterial} />
              <mesh name="Mesh418_1" geometry={nodes.Mesh418_1.geometry} material={materials.WorldGridMaterial} />
              <mesh name="Mesh418_2" geometry={nodes.Mesh418_2.geometry} material={materials.BasicShapeMaterial} />
            </group>
            <group name="StaticMeshComponent24_97" position={[-0.001, -77, 420.001]}>
              <mesh name="Mesh422" geometry={nodes.Mesh422.geometry} material={materials.WorldGridMaterial} />
              <mesh name="Mesh422_1" geometry={nodes.Mesh422_1.geometry} material={materials.WorldGridMaterial} />
              <mesh name="Mesh422_2" geometry={nodes.Mesh422_2.geometry} material={materials.BasicShapeMaterial} />
            </group>
            <group name="StaticMeshComponent25_98" position={[-0.001, -77, 437]}>
              <mesh name="Mesh426" geometry={nodes.Mesh426.geometry} material={materials.WorldGridMaterial} />
              <mesh name="Mesh426_1" geometry={nodes.Mesh426_1.geometry} material={materials.WorldGridMaterial} />
              <mesh name="Mesh426_2" geometry={nodes.Mesh426_2.geometry} material={materials.BasicShapeMaterial} />
            </group>
            <group name="StaticMeshComponent26_99" position={[-0.001, -77, 453.001]}>
              <mesh name="Mesh430" geometry={nodes.Mesh430.geometry} material={materials.WorldGridMaterial} />
              <mesh name="Mesh430_1" geometry={nodes.Mesh430_1.geometry} material={materials.WorldGridMaterial} />
              <mesh name="Mesh430_2" geometry={nodes.Mesh430_2.geometry} material={materials.BasicShapeMaterial} />
            </group>
            <group name="StaticMeshComponent27_100" position={[0, -77, 492.002]}>
              <mesh name="Mesh412" geometry={nodes.Mesh412.geometry} material={materials.WorldGridMaterial} />
              <mesh name="Mesh412_1" geometry={nodes.Mesh412_1.geometry} material={materials.WorldGridMaterial} />
              <mesh name="Mesh412_2" geometry={nodes.Mesh412_2.geometry} material={materials.BasicShapeMaterial} />
            </group>
            <group name="StaticMeshComponent28_101" position={[0, -77, 530.001]}>
              <mesh name="Mesh408" geometry={nodes.Mesh408.geometry} material={materials.WorldGridMaterial} />
              <mesh name="Mesh408_1" geometry={nodes.Mesh408_1.geometry} material={materials.WorldGridMaterial} />
              <mesh name="Mesh408_2" geometry={nodes.Mesh408_2.geometry} material={materials.BasicShapeMaterial} />
            </group>
            <group name="StaticMeshComponent29_102" position={[0, -77, 556]}>
              <mesh name="Mesh404" geometry={nodes.Mesh404.geometry} material={materials.WorldGridMaterial} />
              <mesh name="Mesh404_1" geometry={nodes.Mesh404_1.geometry} material={materials.WorldGridMaterial} />
              <mesh name="Mesh404_2" geometry={nodes.Mesh404_2.geometry} material={materials.BasicShapeMaterial} />
            </group>
            <group name="StaticMeshComponent2_75" position={[0, 0, 175]}>
              <mesh name="Mesh401" geometry={nodes.Mesh401.geometry} material={materials.WorldGridMaterial} />
              <mesh name="Mesh401_1" geometry={nodes.Mesh401_1.geometry} material={materials.WorldGridMaterial} />
              <mesh name="Mesh401_2" geometry={nodes.Mesh401_2.geometry} material={materials.BasicShapeMaterial} />
            </group>
            <group name="StaticMeshComponent30_103" position={[0, -77, 599]}>
              <mesh name="Mesh400" geometry={nodes.Mesh400.geometry} material={materials.WorldGridMaterial} />
              <mesh name="Mesh400_1" geometry={nodes.Mesh400_1.geometry} material={materials.WorldGridMaterial} />
              <mesh name="Mesh400_2" geometry={nodes.Mesh400_2.geometry} material={materials.BasicShapeMaterial} />
            </group>
            <group name="StaticMeshComponent31_104" position={[0, -77, 637.001]}>
              <mesh name="Mesh413" geometry={nodes.Mesh413.geometry} material={materials.WorldGridMaterial} />
              <mesh name="Mesh413_1" geometry={nodes.Mesh413_1.geometry} material={materials.WorldGridMaterial} />
              <mesh name="Mesh413_2" geometry={nodes.Mesh413_2.geometry} material={materials.BasicShapeMaterial} />
            </group>
            <group name="StaticMeshComponent32_105" position={[0, -77, 672]}>
              <mesh name="Mesh417" geometry={nodes.Mesh417.geometry} material={materials.WorldGridMaterial} />
              <mesh name="Mesh417_1" geometry={nodes.Mesh417_1.geometry} material={materials.WorldGridMaterial} />
              <mesh name="Mesh417_2" geometry={nodes.Mesh417_2.geometry} material={materials.BasicShapeMaterial} />
            </group>
            <group name="StaticMeshComponent33_106" position={[0, -77, 695]}>
              <mesh name="Mesh421" geometry={nodes.Mesh421.geometry} material={materials.WorldGridMaterial} />
              <mesh name="Mesh421_1" geometry={nodes.Mesh421_1.geometry} material={materials.WorldGridMaterial} />
              <mesh name="Mesh421_2" geometry={nodes.Mesh421_2.geometry} material={materials.BasicShapeMaterial} />
            </group>
            <group name="StaticMeshComponent34_107" position={[0, -77, 736]}>
              <mesh name="Mesh425" geometry={nodes.Mesh425.geometry} material={materials.WorldGridMaterial} />
              <mesh name="Mesh425_1" geometry={nodes.Mesh425_1.geometry} material={materials.WorldGridMaterial} />
              <mesh name="Mesh425_2" geometry={nodes.Mesh425_2.geometry} material={materials.BasicShapeMaterial} />
            </group>
            <group name="StaticMeshComponent35_108" position={[0, -77, 776]}>
              <mesh name="Mesh429" geometry={nodes.Mesh429.geometry} material={materials.WorldGridMaterial} />
              <mesh name="Mesh429_1" geometry={nodes.Mesh429_1.geometry} material={materials.WorldGridMaterial} />
              <mesh name="Mesh429_2" geometry={nodes.Mesh429_2.geometry} material={materials.BasicShapeMaterial} />
            </group>
            <group name="StaticMeshComponent3_76" position={[0, 0, 201]}>
              <mesh name="Mesh397" geometry={nodes.Mesh397.geometry} material={materials.WorldGridMaterial} />
              <mesh name="Mesh397_1" geometry={nodes.Mesh397_1.geometry} material={materials.WorldGridMaterial} />
              <mesh name="Mesh397_2" geometry={nodes.Mesh397_2.geometry} material={materials.BasicShapeMaterial} />
            </group>
            <group name="StaticMeshComponent4_77" position={[-0.001, 0, 240.001]}>
              <mesh name="Mesh416" geometry={nodes.Mesh416.geometry} material={materials.WorldGridMaterial} />
              <mesh name="Mesh416_1" geometry={nodes.Mesh416_1.geometry} material={materials.WorldGridMaterial} />
              <mesh name="Mesh416_2" geometry={nodes.Mesh416_2.geometry} material={materials.BasicShapeMaterial} />
            </group>
            <group name="StaticMeshComponent5_78" position={[-0.001, 0, 277]}>
              <mesh name="Mesh420" geometry={nodes.Mesh420.geometry} material={materials.WorldGridMaterial} />
              <mesh name="Mesh420_1" geometry={nodes.Mesh420_1.geometry} material={materials.WorldGridMaterial} />
              <mesh name="Mesh420_2" geometry={nodes.Mesh420_2.geometry} material={materials.BasicShapeMaterial} />
            </group>
            <group name="StaticMeshComponent6_79" position={[-0.001, 0, 316.002]}>
              <mesh name="Mesh424" geometry={nodes.Mesh424.geometry} material={materials.WorldGridMaterial} />
              <mesh name="Mesh424_1" geometry={nodes.Mesh424_1.geometry} material={materials.WorldGridMaterial} />
              <mesh name="Mesh424_2" geometry={nodes.Mesh424_2.geometry} material={materials.BasicShapeMaterial} />
            </group>
            <group name="StaticMeshComponent7_80" position={[-0.001, 0, 359]}>
              <mesh name="Mesh428" geometry={nodes.Mesh428.geometry} material={materials.WorldGridMaterial} />
              <mesh name="Mesh428_1" geometry={nodes.Mesh428_1.geometry} material={materials.WorldGridMaterial} />
              <mesh name="Mesh428_2" geometry={nodes.Mesh428_2.geometry} material={materials.BasicShapeMaterial} />
            </group>
            <group name="StaticMeshComponent8_81" position={[-0.001, 0, 419]}>
              <mesh name="Mesh432" geometry={nodes.Mesh432.geometry} material={materials.WorldGridMaterial} />
              <mesh name="Mesh432_1" geometry={nodes.Mesh432_1.geometry} material={materials.WorldGridMaterial} />
              <mesh name="Mesh432_2" geometry={nodes.Mesh432_2.geometry} material={materials.BasicShapeMaterial} />
            </group>
            <group name="StaticMeshComponent9_82" position={[-0.001, 0, 470]}>
              <mesh name="Mesh410" geometry={nodes.Mesh410.geometry} material={materials.WorldGridMaterial} />
              <mesh name="Mesh410_1" geometry={nodes.Mesh410_1.geometry} material={materials.WorldGridMaterial} />
              <mesh name="Mesh410_2" geometry={nodes.Mesh410_2.geometry} material={materials.BasicShapeMaterial} />
            </group>
          </group>
          <group name="Text3DActor11" position={[332.223, -110.566, 526.176]} rotation={[0, -1.543, 0]} scale={0.045}>
            <group name="StaticMeshComponent0_51" position={[-0.001, 0, 0]}>
              <mesh name="Mesh279" geometry={nodes.Mesh279.geometry} material={materials.MI_FlatColor04} />
              <mesh name="Mesh279_1" geometry={nodes.Mesh279_1.geometry} material={materials['MI_Copper_-cu-_scnnfhbp_4K']} />
              <mesh name="Mesh279_2" geometry={nodes.Mesh279_2.geometry} material={materials['MI_Copper_-cu-_scnnfhbp_4K']} />
            </group>
            <group name="StaticMeshComponent10_61" position={[-0.001, 0, 416.999]}>
              <mesh name="Mesh288" geometry={nodes.Mesh288.geometry} material={materials.MI_FlatColor04} />
              <mesh name="Mesh288_1" geometry={nodes.Mesh288_1.geometry} material={materials['MI_Copper_-cu-_scnnfhbp_4K']} />
              <mesh name="Mesh288_2" geometry={nodes.Mesh288_2.geometry} material={materials['MI_Copper_-cu-_scnnfhbp_4K']} />
            </group>
            <group name="StaticMeshComponent11_62" position={[-0.002, 0, 474]}>
              <mesh name="Mesh292" geometry={nodes.Mesh292.geometry} material={materials.MI_FlatColor04} />
              <mesh name="Mesh292_1" geometry={nodes.Mesh292_1.geometry} material={materials['MI_Copper_-cu-_scnnfhbp_4K']} />
              <mesh name="Mesh292_2" geometry={nodes.Mesh292_2.geometry} material={materials['MI_Copper_-cu-_scnnfhbp_4K']} />
            </group>
            <group name="StaticMeshComponent12_63" position={[-0.002, 0, 526]}>
              <mesh name="Mesh282" geometry={nodes.Mesh282.geometry} material={materials.MI_FlatColor04} />
              <mesh name="Mesh282_1" geometry={nodes.Mesh282_1.geometry} material={materials['MI_Copper_-cu-_scnnfhbp_4K']} />
              <mesh name="Mesh282_2" geometry={nodes.Mesh282_2.geometry} material={materials['MI_Copper_-cu-_scnnfhbp_4K']} />
            </group>
            <group name="StaticMeshComponent13_64" position={[-0.002, 0, 563.001]}>
              <mesh name="Mesh278" geometry={nodes.Mesh278.geometry} material={materials.MI_FlatColor04} />
              <mesh name="Mesh278_1" geometry={nodes.Mesh278_1.geometry} material={materials['MI_Copper_-cu-_scnnfhbp_4K']} />
              <mesh name="Mesh278_2" geometry={nodes.Mesh278_2.geometry} material={materials['MI_Copper_-cu-_scnnfhbp_4K']} />
            </group>
            <group name="StaticMeshComponent14_65" position={[-0.003, 0, 585]}>
              <mesh name="Mesh274" geometry={nodes.Mesh274.geometry} material={materials.MI_FlatColor04} />
              <mesh name="Mesh274_1" geometry={nodes.Mesh274_1.geometry} material={materials['MI_Copper_-cu-_scnnfhbp_4K']} />
              <mesh name="Mesh274_2" geometry={nodes.Mesh274_2.geometry} material={materials['MI_Copper_-cu-_scnnfhbp_4K']} />
            </group>
            <group name="StaticMeshComponent15_66" position={[0, 0, 622.001]}>
              <mesh name="Mesh283" geometry={nodes.Mesh283.geometry} material={materials.MI_FlatColor04} />
              <mesh name="Mesh283_1" geometry={nodes.Mesh283_1.geometry} material={materials['MI_Copper_-cu-_scnnfhbp_4K']} />
              <mesh name="Mesh283_2" geometry={nodes.Mesh283_2.geometry} material={materials['MI_Copper_-cu-_scnnfhbp_4K']} />
            </group>
            <group name="StaticMeshComponent16_67" position={[-0.003, 0, 644.999]}>
              <mesh name="Mesh287" geometry={nodes.Mesh287.geometry} material={materials.MI_FlatColor04} />
              <mesh name="Mesh287_1" geometry={nodes.Mesh287_1.geometry} material={materials['MI_Copper_-cu-_scnnfhbp_4K']} />
              <mesh name="Mesh287_2" geometry={nodes.Mesh287_2.geometry} material={materials['MI_Copper_-cu-_scnnfhbp_4K']} />
            </group>
            <group name="StaticMeshComponent17_68" position={[0, 0, 662]}>
              <mesh name="Mesh291" geometry={nodes.Mesh291.geometry} material={materials.MI_FlatColor04} />
              <mesh name="Mesh291_1" geometry={nodes.Mesh291_1.geometry} material={materials['MI_Copper_-cu-_scnnfhbp_4K']} />
              <mesh name="Mesh291_2" geometry={nodes.Mesh291_2.geometry} material={materials['MI_Copper_-cu-_scnnfhbp_4K']} />
            </group>
            <group name="StaticMeshComponent18_69" position={[0.001, -77, 19.001]}>
              <mesh name="Mesh295" geometry={nodes.Mesh295.geometry} material={materials.MI_FlatColor04} />
              <mesh name="Mesh295_1" geometry={nodes.Mesh295_1.geometry} material={materials['MI_Copper_-cu-_scnnfhbp_4K']} />
              <mesh name="Mesh295_2" geometry={nodes.Mesh295_2.geometry} material={materials['MI_Copper_-cu-_scnnfhbp_4K']} />
            </group>
            <group name="StaticMeshComponent19_70" position={[-0.001, -77, 55.999]}>
              <mesh name="Mesh280" geometry={nodes.Mesh280.geometry} material={materials.MI_FlatColor04} />
              <mesh name="Mesh280_1" geometry={nodes.Mesh280_1.geometry} material={materials['MI_Copper_-cu-_scnnfhbp_4K']} />
              <mesh name="Mesh280_2" geometry={nodes.Mesh280_2.geometry} material={materials['MI_Copper_-cu-_scnnfhbp_4K']} />
            </group>
            <group name="StaticMeshComponent1_52" position={[-0.002, 0, 47]}>
              <mesh name="Mesh275" geometry={nodes.Mesh275.geometry} material={materials.MI_FlatColor04} />
              <mesh name="Mesh275_1" geometry={nodes.Mesh275_1.geometry} material={materials['MI_Copper_-cu-_scnnfhbp_4K']} />
              <mesh name="Mesh275_2" geometry={nodes.Mesh275_2.geometry} material={materials['MI_Copper_-cu-_scnnfhbp_4K']} />
            </group>
            <group name="StaticMeshComponent20_71" position={[-0.002, -77, 92.999]}>
              <mesh name="Mesh276" geometry={nodes.Mesh276.geometry} material={materials.MI_FlatColor04} />
              <mesh name="Mesh276_1" geometry={nodes.Mesh276_1.geometry} material={materials['MI_Copper_-cu-_scnnfhbp_4K']} />
              <mesh name="Mesh276_2" geometry={nodes.Mesh276_2.geometry} material={materials['MI_Copper_-cu-_scnnfhbp_4K']} />
            </group>
            <group name="StaticMeshComponent21_72" position={[-0.002, -77, 103]}>
              <mesh name="Mesh272" geometry={nodes.Mesh272.geometry} material={materials.MI_FlatColor04} />
              <mesh name="Mesh272_1" geometry={nodes.Mesh272_1.geometry} material={materials['MI_Copper_-cu-_scnnfhbp_4K']} />
              <mesh name="Mesh272_2" geometry={nodes.Mesh272_2.geometry} material={materials['MI_Copper_-cu-_scnnfhbp_4K']} />
            </group>
            <group name="StaticMeshComponent22_73" position={[-0.001, -77, 125.999]}>
              <mesh name="Mesh285" geometry={nodes.Mesh285.geometry} material={materials.MI_FlatColor04} />
              <mesh name="Mesh285_1" geometry={nodes.Mesh285_1.geometry} material={materials['MI_Copper_-cu-_scnnfhbp_4K']} />
              <mesh name="Mesh285_2" geometry={nodes.Mesh285_2.geometry} material={materials['MI_Copper_-cu-_scnnfhbp_4K']} />
            </group>
            <group name="StaticMeshComponent23_74" position={[-0.001, -77, 166]}>
              <mesh name="Mesh289" geometry={nodes.Mesh289.geometry} material={materials.MI_FlatColor04} />
              <mesh name="Mesh289_1" geometry={nodes.Mesh289_1.geometry} material={materials['MI_Copper_-cu-_scnnfhbp_4K']} />
              <mesh name="Mesh289_2" geometry={nodes.Mesh289_2.geometry} material={materials['MI_Copper_-cu-_scnnfhbp_4K']} />
            </group>
            <group name="StaticMeshComponent24_75" position={[-0.002, -77, 206.999]}>
              <mesh name="Mesh293" geometry={nodes.Mesh293.geometry} material={materials.MI_FlatColor04} />
              <mesh name="Mesh293_1" geometry={nodes.Mesh293_1.geometry} material={materials['MI_Copper_-cu-_scnnfhbp_4K']} />
              <mesh name="Mesh293_2" geometry={nodes.Mesh293_2.geometry} material={materials['MI_Copper_-cu-_scnnfhbp_4K']} />
            </group>
            <group name="StaticMeshComponent2_53" position={[0, 0, 84]}>
              <mesh name="Mesh271" geometry={nodes.Mesh271.geometry} material={materials.MI_FlatColor04} />
              <mesh name="Mesh271_1" geometry={nodes.Mesh271_1.geometry} material={materials['MI_Copper_-cu-_scnnfhbp_4K']} />
              <mesh name="Mesh271_2" geometry={nodes.Mesh271_2.geometry} material={materials['MI_Copper_-cu-_scnnfhbp_4K']} />
            </group>
            <group name="StaticMeshComponent3_54" position={[0, 0, 145]}>
              <mesh name="Mesh286" geometry={nodes.Mesh286.geometry} material={materials.MI_FlatColor04} />
              <mesh name="Mesh286_1" geometry={nodes.Mesh286_1.geometry} material={materials['MI_Copper_-cu-_scnnfhbp_4K']} />
              <mesh name="Mesh286_2" geometry={nodes.Mesh286_2.geometry} material={materials['MI_Copper_-cu-_scnnfhbp_4K']} />
            </group>
            <group name="StaticMeshComponent4_55" position={[0, 0, 188]}>
              <mesh name="Mesh290" geometry={nodes.Mesh290.geometry} material={materials.MI_FlatColor04} />
              <mesh name="Mesh290_1" geometry={nodes.Mesh290_1.geometry} material={materials['MI_Copper_-cu-_scnnfhbp_4K']} />
              <mesh name="Mesh290_2" geometry={nodes.Mesh290_2.geometry} material={materials['MI_Copper_-cu-_scnnfhbp_4K']} />
            </group>
            <group name="StaticMeshComponent5_56" position={[-0.001, 0, 226]}>
              <mesh name="Mesh294" geometry={nodes.Mesh294.geometry} material={materials.MI_FlatColor04} />
              <mesh name="Mesh294_1" geometry={nodes.Mesh294_1.geometry} material={materials['MI_Copper_-cu-_scnnfhbp_4K']} />
              <mesh name="Mesh294_2" geometry={nodes.Mesh294_2.geometry} material={materials['MI_Copper_-cu-_scnnfhbp_4K']} />
            </group>
            <group name="StaticMeshComponent6_57" position={[-0.001, 0, 258]}>
              <mesh name="Mesh281" geometry={nodes.Mesh281.geometry} material={materials.MI_FlatColor04} />
              <mesh name="Mesh281_1" geometry={nodes.Mesh281_1.geometry} material={materials['MI_Copper_-cu-_scnnfhbp_4K']} />
              <mesh name="Mesh281_2" geometry={nodes.Mesh281_2.geometry} material={materials['MI_Copper_-cu-_scnnfhbp_4K']} />
            </group>
            <group name="StaticMeshComponent7_58" position={[-0.002, 0, 274.999]}>
              <mesh name="Mesh277" geometry={nodes.Mesh277.geometry} material={materials.MI_FlatColor04} />
              <mesh name="Mesh277_1" geometry={nodes.Mesh277_1.geometry} material={materials['MI_Copper_-cu-_scnnfhbp_4K']} />
              <mesh name="Mesh277_2" geometry={nodes.Mesh277_2.geometry} material={materials['MI_Copper_-cu-_scnnfhbp_4K']} />
            </group>
            <group name="StaticMeshComponent8_59" position={[-0.001, 0, 314]}>
              <mesh name="Mesh273" geometry={nodes.Mesh273.geometry} material={materials.MI_FlatColor04} />
              <mesh name="Mesh273_1" geometry={nodes.Mesh273_1.geometry} material={materials['MI_Copper_-cu-_scnnfhbp_4K']} />
              <mesh name="Mesh273_2" geometry={nodes.Mesh273_2.geometry} material={materials['MI_Copper_-cu-_scnnfhbp_4K']} />
            </group>
            <group name="StaticMeshComponent9_60" position={[-0.001, 0, 374]}>
              <mesh name="Mesh284" geometry={nodes.Mesh284.geometry} material={materials.MI_FlatColor04} />
              <mesh name="Mesh284_1" geometry={nodes.Mesh284_1.geometry} material={materials['MI_Copper_-cu-_scnnfhbp_4K']} />
              <mesh name="Mesh284_2" geometry={nodes.Mesh284_2.geometry} material={materials['MI_Copper_-cu-_scnnfhbp_4K']} />
            </group>
          </group>
          <group name="Text3DActor12" position={[227.397, -110.566, 527.233]} rotation={[0, -1.543, 0]} scale={0.035}>
            <group name="StaticMeshComponent0_59" position={[0, 0, -0.001]}>
              <mesh name="Mesh306" geometry={nodes.Mesh306.geometry} material={materials.MI_FlatColor04} />
              <mesh name="Mesh306_1" geometry={nodes.Mesh306_1.geometry} material={materials['MI_Copper_-cu-_scnnfhbp_4K']} />
              <mesh name="Mesh306_2" geometry={nodes.Mesh306_2.geometry} material={materials['MI_Copper_-cu-_scnnfhbp_4K']} />
            </group>
            <group name="StaticMeshComponent10_69" position={[0, 0, 415.999]}>
              <mesh name="Mesh296" geometry={nodes.Mesh296.geometry} material={materials.MI_FlatColor04} />
              <mesh name="Mesh296_1" geometry={nodes.Mesh296_1.geometry} material={materials['MI_Copper_-cu-_scnnfhbp_4K']} />
              <mesh name="Mesh296_2" geometry={nodes.Mesh296_2.geometry} material={materials['MI_Copper_-cu-_scnnfhbp_4K']} />
            </group>
            <group name="StaticMeshComponent11_70" position={[0.001, 0, 455]}>
              <mesh name="Mesh313" geometry={nodes.Mesh313.geometry} material={materials.MI_FlatColor04} />
              <mesh name="Mesh313_1" geometry={nodes.Mesh313_1.geometry} material={materials['MI_Copper_-cu-_scnnfhbp_4K']} />
              <mesh name="Mesh313_2" geometry={nodes.Mesh313_2.geometry} material={materials['MI_Copper_-cu-_scnnfhbp_4K']} />
            </group>
            <group name="StaticMeshComponent12_71" position={[0.001, 0, 491.999]}>
              <mesh name="Mesh317" geometry={nodes.Mesh317.geometry} material={materials.MI_FlatColor04} />
              <mesh name="Mesh317_1" geometry={nodes.Mesh317_1.geometry} material={materials['MI_Copper_-cu-_scnnfhbp_4K']} />
              <mesh name="Mesh317_2" geometry={nodes.Mesh317_2.geometry} material={materials['MI_Copper_-cu-_scnnfhbp_4K']} />
            </group>
            <group name="StaticMeshComponent13_72" position={[-0.002, 0, 531]}>
              <mesh name="Mesh321" geometry={nodes.Mesh321.geometry} material={materials.MI_FlatColor04} />
              <mesh name="Mesh321_1" geometry={nodes.Mesh321_1.geometry} material={materials['MI_Copper_-cu-_scnnfhbp_4K']} />
              <mesh name="Mesh321_2" geometry={nodes.Mesh321_2.geometry} material={materials['MI_Copper_-cu-_scnnfhbp_4K']} />
            </group>
            <group name="StaticMeshComponent14_73" position={[0.001, 0, 567.999]}>
              <mesh name="Mesh309" geometry={nodes.Mesh309.geometry} material={materials.MI_FlatColor04} />
              <mesh name="Mesh309_1" geometry={nodes.Mesh309_1.geometry} material={materials['MI_Copper_-cu-_scnnfhbp_4K']} />
              <mesh name="Mesh309_2" geometry={nodes.Mesh309_2.geometry} material={materials['MI_Copper_-cu-_scnnfhbp_4K']} />
            </group>
            <group name="StaticMeshComponent15_74" position={[-0.002, 0, 604.999]}>
              <mesh name="Mesh305" geometry={nodes.Mesh305.geometry} material={materials.MI_FlatColor04} />
              <mesh name="Mesh305_1" geometry={nodes.Mesh305_1.geometry} material={materials['MI_Copper_-cu-_scnnfhbp_4K']} />
              <mesh name="Mesh305_2" geometry={nodes.Mesh305_2.geometry} material={materials['MI_Copper_-cu-_scnnfhbp_4K']} />
            </group>
            <group name="StaticMeshComponent16_75" position={[0.001, 0, 643.999]}>
              <mesh name="Mesh301" geometry={nodes.Mesh301.geometry} material={materials.MI_FlatColor04} />
              <mesh name="Mesh301_1" geometry={nodes.Mesh301_1.geometry} material={materials['MI_Copper_-cu-_scnnfhbp_4K']} />
              <mesh name="Mesh301_2" geometry={nodes.Mesh301_2.geometry} material={materials['MI_Copper_-cu-_scnnfhbp_4K']} />
            </group>
            <group name="StaticMeshComponent17_76" position={[0, -77, -0.001]}>
              <mesh name="Mesh297" geometry={nodes.Mesh297.geometry} material={materials.MI_FlatColor04} />
              <mesh name="Mesh297_1" geometry={nodes.Mesh297_1.geometry} material={materials['MI_Copper_-cu-_scnnfhbp_4K']} />
              <mesh name="Mesh297_2" geometry={nodes.Mesh297_2.geometry} material={materials['MI_Copper_-cu-_scnnfhbp_4K']} />
            </group>
            <group name="StaticMeshComponent18_77" position={[-0.001, -77, 37]}>
              <mesh name="Mesh312" geometry={nodes.Mesh312.geometry} material={materials.MI_FlatColor04} />
              <mesh name="Mesh312_1" geometry={nodes.Mesh312_1.geometry} material={materials['MI_Copper_-cu-_scnnfhbp_4K']} />
              <mesh name="Mesh312_2" geometry={nodes.Mesh312_2.geometry} material={materials['MI_Copper_-cu-_scnnfhbp_4K']} />
            </group>
            <group name="StaticMeshComponent19_78" position={[-0.001, -77, 74]}>
              <mesh name="Mesh316" geometry={nodes.Mesh316.geometry} material={materials.MI_FlatColor04} />
              <mesh name="Mesh316_1" geometry={nodes.Mesh316_1.geometry} material={materials['MI_Copper_-cu-_scnnfhbp_4K']} />
              <mesh name="Mesh316_2" geometry={nodes.Mesh316_2.geometry} material={materials['MI_Copper_-cu-_scnnfhbp_4K']} />
            </group>
            <group name="StaticMeshComponent1_60" position={[0.001, 0, 46]}>
              <mesh name="Mesh302" geometry={nodes.Mesh302.geometry} material={materials.MI_FlatColor04} />
              <mesh name="Mesh302_1" geometry={nodes.Mesh302_1.geometry} material={materials['MI_Copper_-cu-_scnnfhbp_4K']} />
              <mesh name="Mesh302_2" geometry={nodes.Mesh302_2.geometry} material={materials['MI_Copper_-cu-_scnnfhbp_4K']} />
            </group>
            <group name="StaticMeshComponent20_79" position={[0.001, -77, 84]}>
              <mesh name="Mesh320" geometry={nodes.Mesh320.geometry} material={materials.MI_FlatColor04} />
              <mesh name="Mesh320_1" geometry={nodes.Mesh320_1.geometry} material={materials['MI_Copper_-cu-_scnnfhbp_4K']} />
              <mesh name="Mesh320_2" geometry={nodes.Mesh320_2.geometry} material={materials['MI_Copper_-cu-_scnnfhbp_4K']} />
            </group>
            <group name="StaticMeshComponent21_80" position={[0, -77, 106.999]}>
              <mesh name="Mesh324" geometry={nodes.Mesh324.geometry} material={materials.MI_FlatColor04} />
              <mesh name="Mesh324_1" geometry={nodes.Mesh324_1.geometry} material={materials['MI_Copper_-cu-_scnnfhbp_4K']} />
              <mesh name="Mesh324_2" geometry={nodes.Mesh324_2.geometry} material={materials['MI_Copper_-cu-_scnnfhbp_4K']} />
            </group>
            <group name="StaticMeshComponent22_81" position={[-0.002, -77, 148]}>
              <mesh name="Mesh307" geometry={nodes.Mesh307.geometry} material={materials.MI_FlatColor04} />
              <mesh name="Mesh307_1" geometry={nodes.Mesh307_1.geometry} material={materials['MI_Copper_-cu-_scnnfhbp_4K']} />
              <mesh name="Mesh307_2" geometry={nodes.Mesh307_2.geometry} material={materials['MI_Copper_-cu-_scnnfhbp_4K']} />
            </group>
            <group name="StaticMeshComponent23_82" position={[0.001, -77, 193.999]}>
              <mesh name="Mesh303" geometry={nodes.Mesh303.geometry} material={materials.MI_FlatColor04} />
              <mesh name="Mesh303_1" geometry={nodes.Mesh303_1.geometry} material={materials['MI_Copper_-cu-_scnnfhbp_4K']} />
              <mesh name="Mesh303_2" geometry={nodes.Mesh303_2.geometry} material={materials['MI_Copper_-cu-_scnnfhbp_4K']} />
            </group>
            <group name="StaticMeshComponent24_83" position={[-0.001, -77, 240]}>
              <mesh name="Mesh299" geometry={nodes.Mesh299.geometry} material={materials.MI_FlatColor04} />
              <mesh name="Mesh299_1" geometry={nodes.Mesh299_1.geometry} material={materials['MI_Copper_-cu-_scnnfhbp_4K']} />
              <mesh name="Mesh299_2" geometry={nodes.Mesh299_2.geometry} material={materials['MI_Copper_-cu-_scnnfhbp_4K']} />
            </group>
            <group name="StaticMeshComponent25_84" position={[-0.001, -77, 261.999]}>
              <mesh name="Mesh310" geometry={nodes.Mesh310.geometry} material={materials.MI_FlatColor04} />
              <mesh name="Mesh310_1" geometry={nodes.Mesh310_1.geometry} material={materials['MI_Copper_-cu-_scnnfhbp_4K']} />
              <mesh name="Mesh310_2" geometry={nodes.Mesh310_2.geometry} material={materials['MI_Copper_-cu-_scnnfhbp_4K']} />
            </group>
            <group name="StaticMeshComponent26_85" position={[-0.001, -77, 285]}>
              <mesh name="Mesh314" geometry={nodes.Mesh314.geometry} material={materials.MI_FlatColor04} />
              <mesh name="Mesh314_1" geometry={nodes.Mesh314_1.geometry} material={materials['MI_Copper_-cu-_scnnfhbp_4K']} />
              <mesh name="Mesh314_2" geometry={nodes.Mesh314_2.geometry} material={materials['MI_Copper_-cu-_scnnfhbp_4K']} />
            </group>
            <group name="StaticMeshComponent27_86" position={[-0.001, -77, 325.999]}>
              <mesh name="Mesh318" geometry={nodes.Mesh318.geometry} material={materials.MI_FlatColor04} />
              <mesh name="Mesh318_1" geometry={nodes.Mesh318_1.geometry} material={materials['MI_Copper_-cu-_scnnfhbp_4K']} />
              <mesh name="Mesh318_2" geometry={nodes.Mesh318_2.geometry} material={materials['MI_Copper_-cu-_scnnfhbp_4K']} />
            </group>
            <group name="StaticMeshComponent28_87" position={[0, -77, 369.999]}>
              <mesh name="Mesh322" geometry={nodes.Mesh322.geometry} material={materials.MI_FlatColor04} />
              <mesh name="Mesh322_1" geometry={nodes.Mesh322_1.geometry} material={materials['MI_Copper_-cu-_scnnfhbp_4K']} />
              <mesh name="Mesh322_2" geometry={nodes.Mesh322_2.geometry} material={materials['MI_Copper_-cu-_scnnfhbp_4K']} />
            </group>
            <group name="StaticMeshComponent2_61" position={[0.001, 0, 84]}>
              <mesh name="Mesh298" geometry={nodes.Mesh298.geometry} material={materials.MI_FlatColor04} />
              <mesh name="Mesh298_1" geometry={nodes.Mesh298_1.geometry} material={materials['MI_Copper_-cu-_scnnfhbp_4K']} />
              <mesh name="Mesh298_2" geometry={nodes.Mesh298_2.geometry} material={materials['MI_Copper_-cu-_scnnfhbp_4K']} />
            </group>
            <group name="StaticMeshComponent3_62" position={[-0.002, 0, 110]}>
              <mesh name="Mesh311" geometry={nodes.Mesh311.geometry} material={materials.MI_FlatColor04} />
              <mesh name="Mesh311_1" geometry={nodes.Mesh311_1.geometry} material={materials['MI_Copper_-cu-_scnnfhbp_4K']} />
              <mesh name="Mesh311_2" geometry={nodes.Mesh311_2.geometry} material={materials['MI_Copper_-cu-_scnnfhbp_4K']} />
            </group>
            <group name="StaticMeshComponent4_63" position={[0, 0, 132]}>
              <mesh name="Mesh315" geometry={nodes.Mesh315.geometry} material={materials.MI_FlatColor04} />
              <mesh name="Mesh315_1" geometry={nodes.Mesh315_1.geometry} material={materials['MI_Copper_-cu-_scnnfhbp_4K']} />
              <mesh name="Mesh315_2" geometry={nodes.Mesh315_2.geometry} material={materials['MI_Copper_-cu-_scnnfhbp_4K']} />
            </group>
            <group name="StaticMeshComponent5_64" position={[0, 0, 168.999]}>
              <mesh name="Mesh319" geometry={nodes.Mesh319.geometry} material={materials.MI_FlatColor04} />
              <mesh name="Mesh319_1" geometry={nodes.Mesh319_1.geometry} material={materials['MI_Copper_-cu-_scnnfhbp_4K']} />
              <mesh name="Mesh319_2" geometry={nodes.Mesh319_2.geometry} material={materials['MI_Copper_-cu-_scnnfhbp_4K']} />
            </group>
            <group name="StaticMeshComponent6_65" position={[0.001, 0, 195]}>
              <mesh name="Mesh323" geometry={nodes.Mesh323.geometry} material={materials.MI_FlatColor04} />
              <mesh name="Mesh323_1" geometry={nodes.Mesh323_1.geometry} material={materials['MI_Copper_-cu-_scnnfhbp_4K']} />
              <mesh name="Mesh323_2" geometry={nodes.Mesh323_2.geometry} material={materials['MI_Copper_-cu-_scnnfhbp_4K']} />
            </group>
            <group name="StaticMeshComponent7_66" position={[-0.002, 0, 255]}>
              <mesh name="Mesh308" geometry={nodes.Mesh308.geometry} material={materials.MI_FlatColor04} />
              <mesh name="Mesh308_1" geometry={nodes.Mesh308_1.geometry} material={materials['MI_Copper_-cu-_scnnfhbp_4K']} />
              <mesh name="Mesh308_2" geometry={nodes.Mesh308_2.geometry} material={materials['MI_Copper_-cu-_scnnfhbp_4K']} />
            </group>
            <group name="StaticMeshComponent8_67" position={[0.001, 0, 297.999]}>
              <mesh name="Mesh304" geometry={nodes.Mesh304.geometry} material={materials.MI_FlatColor04} />
              <mesh name="Mesh304_1" geometry={nodes.Mesh304_1.geometry} material={materials['MI_Copper_-cu-_scnnfhbp_4K']} />
              <mesh name="Mesh304_2" geometry={nodes.Mesh304_2.geometry} material={materials['MI_Copper_-cu-_scnnfhbp_4K']} />
            </group>
            <group name="StaticMeshComponent9_68" position={[0, 0, 354.999]}>
              <mesh name="Mesh300" geometry={nodes.Mesh300.geometry} material={materials.MI_FlatColor04} />
              <mesh name="Mesh300_1" geometry={nodes.Mesh300_1.geometry} material={materials['MI_Copper_-cu-_scnnfhbp_4K']} />
              <mesh name="Mesh300_2" geometry={nodes.Mesh300_2.geometry} material={materials['MI_Copper_-cu-_scnnfhbp_4K']} />
            </group>
          </group>
          <group name="Text3DActor13" position={[103.617, -110.566, 526.416]} rotation={[0, -1.543, 0]} scale={0.045}>
            <group name="StaticMeshComponent0_39001" position={[-0.001, 0, 0]}>
              <mesh name="Mesh332" geometry={nodes.Mesh332.geometry} material={materials.MI_FlatColor04} />
              <mesh name="Mesh332_1" geometry={nodes.Mesh332_1.geometry} material={materials['MI_Copper_-cu-_scnnfhbp_4K']} />
              <mesh name="Mesh332_2" geometry={nodes.Mesh332_2.geometry} material={materials['MI_Copper_-cu-_scnnfhbp_4K']} />
            </group>
            <group name="StaticMeshComponent10_49001" position={[-0.001, -77, 0]}>
              <mesh name="Mesh330" geometry={nodes.Mesh330.geometry} material={materials.MI_FlatColor04} />
              <mesh name="Mesh330_1" geometry={nodes.Mesh330_1.geometry} material={materials['MI_Copper_-cu-_scnnfhbp_4K']} />
              <mesh name="Mesh330_2" geometry={nodes.Mesh330_2.geometry} material={materials['MI_Copper_-cu-_scnnfhbp_4K']} />
            </group>
            <group name="StaticMeshComponent11_50001" position={[-0.001, -77, 23]}>
              <mesh name="Mesh326" geometry={nodes.Mesh326.geometry} material={materials.MI_FlatColor04} />
              <mesh name="Mesh326_1" geometry={nodes.Mesh326_1.geometry} material={materials['MI_Copper_-cu-_scnnfhbp_4K']} />
              <mesh name="Mesh326_2" geometry={nodes.Mesh326_2.geometry} material={materials['MI_Copper_-cu-_scnnfhbp_4K']} />
            </group>
            <group name="StaticMeshComponent12_51001" position={[-0.003, -77, 64]}>
              <mesh name="Mesh338" geometry={nodes.Mesh338.geometry} material={materials.MI_FlatColor04} />
              <mesh name="Mesh338_1" geometry={nodes.Mesh338_1.geometry} material={materials['MI_Copper_-cu-_scnnfhbp_4K']} />
              <mesh name="Mesh338_2" geometry={nodes.Mesh338_2.geometry} material={materials['MI_Copper_-cu-_scnnfhbp_4K']} />
            </group>
            <group name="StaticMeshComponent13_52001" position={[-0.001, -77, 110]}>
              <mesh name="Mesh342" geometry={nodes.Mesh342.geometry} material={materials.MI_FlatColor04} />
              <mesh name="Mesh342_1" geometry={nodes.Mesh342_1.geometry} material={materials['MI_Copper_-cu-_scnnfhbp_4K']} />
              <mesh name="Mesh342_2" geometry={nodes.Mesh342_2.geometry} material={materials['MI_Copper_-cu-_scnnfhbp_4K']} />
            </group>
            <group name="StaticMeshComponent14_53001" position={[-0.002, -77, 149]}>
              <mesh name="Mesh333" geometry={nodes.Mesh333.geometry} material={materials.MI_FlatColor04} />
              <mesh name="Mesh333_1" geometry={nodes.Mesh333_1.geometry} material={materials['MI_Copper_-cu-_scnnfhbp_4K']} />
              <mesh name="Mesh333_2" geometry={nodes.Mesh333_2.geometry} material={materials['MI_Copper_-cu-_scnnfhbp_4K']} />
            </group>
            <group name="StaticMeshComponent15_54001" position={[-0.001, -77, 171]}>
              <mesh name="Mesh329" geometry={nodes.Mesh329.geometry} material={materials.MI_FlatColor04} />
              <mesh name="Mesh329_1" geometry={nodes.Mesh329_1.geometry} material={materials['MI_Copper_-cu-_scnnfhbp_4K']} />
              <mesh name="Mesh329_2" geometry={nodes.Mesh329_2.geometry} material={materials['MI_Copper_-cu-_scnnfhbp_4K']} />
            </group>
            <group name="StaticMeshComponent16_55001" position={[-0.001, -77, 194]}>
              <mesh name="Mesh325" geometry={nodes.Mesh325.geometry} material={materials.MI_FlatColor04} />
              <mesh name="Mesh325_1" geometry={nodes.Mesh325_1.geometry} material={materials['MI_Copper_-cu-_scnnfhbp_4K']} />
              <mesh name="Mesh325_2" geometry={nodes.Mesh325_2.geometry} material={materials['MI_Copper_-cu-_scnnfhbp_4K']} />
            </group>
            <group name="StaticMeshComponent17_56001" position={[-0.002, -77, 235]}>
              <mesh name="Mesh339" geometry={nodes.Mesh339.geometry} material={materials.MI_FlatColor04} />
              <mesh name="Mesh339_1" geometry={nodes.Mesh339_1.geometry} material={materials['MI_Copper_-cu-_scnnfhbp_4K']} />
              <mesh name="Mesh339_2" geometry={nodes.Mesh339_2.geometry} material={materials['MI_Copper_-cu-_scnnfhbp_4K']} />
            </group>
            <group name="StaticMeshComponent18_57001" position={[-0.002, -77, 277]}>
              <mesh name="Mesh343" geometry={nodes.Mesh343.geometry} material={materials.MI_FlatColor04} />
              <mesh name="Mesh343_1" geometry={nodes.Mesh343_1.geometry} material={materials['MI_Copper_-cu-_scnnfhbp_4K']} />
              <mesh name="Mesh343_2" geometry={nodes.Mesh343_2.geometry} material={materials['MI_Copper_-cu-_scnnfhbp_4K']} />
            </group>
            <group name="StaticMeshComponent1_40001" position={[-0.002, 0, 40]}>
              <mesh name="Mesh328" geometry={nodes.Mesh328.geometry} material={materials.MI_FlatColor04} />
              <mesh name="Mesh328_1" geometry={nodes.Mesh328_1.geometry} material={materials['MI_Copper_-cu-_scnnfhbp_4K']} />
              <mesh name="Mesh328_2" geometry={nodes.Mesh328_2.geometry} material={materials['MI_Copper_-cu-_scnnfhbp_4K']} />
            </group>
            <group name="StaticMeshComponent2_41001" position={[-0.001, 0, 81]}>
              <mesh name="Mesh336" geometry={nodes.Mesh336.geometry} material={materials.MI_FlatColor04} />
              <mesh name="Mesh336_1" geometry={nodes.Mesh336_1.geometry} material={materials['MI_Copper_-cu-_scnnfhbp_4K']} />
              <mesh name="Mesh336_2" geometry={nodes.Mesh336_2.geometry} material={materials['MI_Copper_-cu-_scnnfhbp_4K']} />
            </group>
            <group name="StaticMeshComponent3_42001" position={[-0.002, 0, 132]}>
              <mesh name="Mesh340" geometry={nodes.Mesh340.geometry} material={materials.MI_FlatColor04} />
              <mesh name="Mesh340_1" geometry={nodes.Mesh340_1.geometry} material={materials['MI_Copper_-cu-_scnnfhbp_4K']} />
              <mesh name="Mesh340_2" geometry={nodes.Mesh340_2.geometry} material={materials['MI_Copper_-cu-_scnnfhbp_4K']} />
            </group>
            <group name="StaticMeshComponent4_43001" position={[-0.001, 0, 170]}>
              <mesh name="Mesh335" geometry={nodes.Mesh335.geometry} material={materials.MI_FlatColor04} />
              <mesh name="Mesh335_1" geometry={nodes.Mesh335_1.geometry} material={materials['MI_Copper_-cu-_scnnfhbp_4K']} />
              <mesh name="Mesh335_2" geometry={nodes.Mesh335_2.geometry} material={materials['MI_Copper_-cu-_scnnfhbp_4K']} />
            </group>
            <group name="StaticMeshComponent5_44001" position={[-0.001, 0, 187]}>
              <mesh name="Mesh331" geometry={nodes.Mesh331.geometry} material={materials.MI_FlatColor04} />
              <mesh name="Mesh331_1" geometry={nodes.Mesh331_1.geometry} material={materials['MI_Copper_-cu-_scnnfhbp_4K']} />
              <mesh name="Mesh331_2" geometry={nodes.Mesh331_2.geometry} material={materials['MI_Copper_-cu-_scnnfhbp_4K']} />
            </group>
            <group name="StaticMeshComponent6_45001" position={[-0.003, 0, 225]}>
              <mesh name="Mesh327" geometry={nodes.Mesh327.geometry} material={materials.MI_FlatColor04} />
              <mesh name="Mesh327_1" geometry={nodes.Mesh327_1.geometry} material={materials['MI_Copper_-cu-_scnnfhbp_4K']} />
              <mesh name="Mesh327_2" geometry={nodes.Mesh327_2.geometry} material={materials['MI_Copper_-cu-_scnnfhbp_4K']} />
            </group>
            <group name="StaticMeshComponent7_46001" position={[-0.002, 0, 242]}>
              <mesh name="Mesh337" geometry={nodes.Mesh337.geometry} material={materials.MI_FlatColor04} />
              <mesh name="Mesh337_1" geometry={nodes.Mesh337_1.geometry} material={materials['MI_Copper_-cu-_scnnfhbp_4K']} />
              <mesh name="Mesh337_2" geometry={nodes.Mesh337_2.geometry} material={materials['MI_Copper_-cu-_scnnfhbp_4K']} />
            </group>
            <group name="StaticMeshComponent8_47001" position={[-0.001, 0, 283]}>
              <mesh name="Mesh341" geometry={nodes.Mesh341.geometry} material={materials.MI_FlatColor04} />
              <mesh name="Mesh341_1" geometry={nodes.Mesh341_1.geometry} material={materials['MI_Copper_-cu-_scnnfhbp_4K']} />
              <mesh name="Mesh341_2" geometry={nodes.Mesh341_2.geometry} material={materials['MI_Copper_-cu-_scnnfhbp_4K']} />
            </group>
            <group name="StaticMeshComponent9_48001" position={[-0.002, 0, 315]}>
              <mesh name="Mesh334" geometry={nodes.Mesh334.geometry} material={materials.MI_FlatColor04} />
              <mesh name="Mesh334_1" geometry={nodes.Mesh334_1.geometry} material={materials['MI_Copper_-cu-_scnnfhbp_4K']} />
              <mesh name="Mesh334_2" geometry={nodes.Mesh334_2.geometry} material={materials['MI_Copper_-cu-_scnnfhbp_4K']} />
            </group>
          </group>
          <group name="Text3DActor2" position={[423.152, -186.896, -505.968]} rotation={[0, 0.028, 0]} scale={0.045}>
            <group name="StaticMeshComponent0_67" position={[-0.001, 0, 0]}>
              <mesh name="Mesh074" geometry={nodes.Mesh074.geometry} material={materials.MI_FlatColor04} />
              <mesh name="Mesh074_1" geometry={nodes.Mesh074_1.geometry} material={materials['MI_Copper_-cu-_scnnfhbp_4K']} />
              <mesh name="Mesh074_2" geometry={nodes.Mesh074_2.geometry} material={materials['MI_Copper_-cu-_scnnfhbp_4K']} />
            </group>
            <group name="StaticMeshComponent10_77" position={[0, 0, 358]}>
              <mesh name="Mesh068" geometry={nodes.Mesh068.geometry} material={materials.MI_FlatColor04} />
              <mesh name="Mesh068_1" geometry={nodes.Mesh068_1.geometry} material={materials['MI_Copper_-cu-_scnnfhbp_4K']} />
              <mesh name="Mesh068_2" geometry={nodes.Mesh068_2.geometry} material={materials['MI_Copper_-cu-_scnnfhbp_4K']} />
            </group>
            <group name="StaticMeshComponent11_78" position={[-0.001, 0, 398.999]}>
              <mesh name="Mesh064" geometry={nodes.Mesh064.geometry} material={materials.MI_FlatColor04} />
              <mesh name="Mesh064_1" geometry={nodes.Mesh064_1.geometry} material={materials['MI_Copper_-cu-_scnnfhbp_4K']} />
              <mesh name="Mesh064_2" geometry={nodes.Mesh064_2.geometry} material={materials['MI_Copper_-cu-_scnnfhbp_4K']} />
            </group>
            <group name="StaticMeshComponent12_79" position={[0, 0, 430.999]}>
              <mesh name="Mesh079" geometry={nodes.Mesh079.geometry} material={materials.MI_FlatColor04} />
              <mesh name="Mesh079_1" geometry={nodes.Mesh079_1.geometry} material={materials['MI_Copper_-cu-_scnnfhbp_4K']} />
              <mesh name="Mesh079_2" geometry={nodes.Mesh079_2.geometry} material={materials['MI_Copper_-cu-_scnnfhbp_4K']} />
            </group>
            <group name="StaticMeshComponent13_80" position={[-0.001, -77, 0]}>
              <mesh name="Mesh083" geometry={nodes.Mesh083.geometry} material={materials.MI_FlatColor04} />
              <mesh name="Mesh083_1" geometry={nodes.Mesh083_1.geometry} material={materials['MI_Copper_-cu-_scnnfhbp_4K']} />
              <mesh name="Mesh083_2" geometry={nodes.Mesh083_2.geometry} material={materials['MI_Copper_-cu-_scnnfhbp_4K']} />
            </group>
            <group name="StaticMeshComponent14_81" position={[-0.001, -77, 43]}>
              <mesh name="Mesh087" geometry={nodes.Mesh087.geometry} material={materials.MI_FlatColor04} />
              <mesh name="Mesh087_1" geometry={nodes.Mesh087_1.geometry} material={materials['MI_Copper_-cu-_scnnfhbp_4K']} />
              <mesh name="Mesh087_2" geometry={nodes.Mesh087_2.geometry} material={materials['MI_Copper_-cu-_scnnfhbp_4K']} />
            </group>
            <group name="StaticMeshComponent15_82" position={[-0.001, -77, 104]}>
              <mesh name="Mesh091" geometry={nodes.Mesh091.geometry} material={materials.MI_FlatColor04} />
              <mesh name="Mesh091_1" geometry={nodes.Mesh091_1.geometry} material={materials['MI_Copper_-cu-_scnnfhbp_4K']} />
              <mesh name="Mesh091_2" geometry={nodes.Mesh091_2.geometry} material={materials['MI_Copper_-cu-_scnnfhbp_4K']} />
            </group>
            <group name="StaticMeshComponent16_83" position={[0, -77, 147.001]}>
              <mesh name="Mesh077" geometry={nodes.Mesh077.geometry} material={materials.MI_FlatColor04} />
              <mesh name="Mesh077_1" geometry={nodes.Mesh077_1.geometry} material={materials['MI_Copper_-cu-_scnnfhbp_4K']} />
              <mesh name="Mesh077_2" geometry={nodes.Mesh077_2.geometry} material={materials['MI_Copper_-cu-_scnnfhbp_4K']} />
            </group>
            <group name="StaticMeshComponent17_84" position={[0.001, -77, 184.001]}>
              <mesh name="Mesh073" geometry={nodes.Mesh073.geometry} material={materials.MI_FlatColor04} />
              <mesh name="Mesh073_1" geometry={nodes.Mesh073_1.geometry} material={materials['MI_Copper_-cu-_scnnfhbp_4K']} />
              <mesh name="Mesh073_2" geometry={nodes.Mesh073_2.geometry} material={materials['MI_Copper_-cu-_scnnfhbp_4K']} />
            </group>
            <group name="StaticMeshComponent18_85" position={[0, -77, 210]}>
              <mesh name="Mesh069" geometry={nodes.Mesh069.geometry} material={materials.MI_FlatColor04} />
              <mesh name="Mesh069_1" geometry={nodes.Mesh069_1.geometry} material={materials['MI_Copper_-cu-_scnnfhbp_4K']} />
              <mesh name="Mesh069_2" geometry={nodes.Mesh069_2.geometry} material={materials['MI_Copper_-cu-_scnnfhbp_4K']} />
            </group>
            <group name="StaticMeshComponent19_86" position={[0, -77, 247]}>
              <mesh name="Mesh065" geometry={nodes.Mesh065.geometry} material={materials.MI_FlatColor04} />
              <mesh name="Mesh065_1" geometry={nodes.Mesh065_1.geometry} material={materials['MI_Copper_-cu-_scnnfhbp_4K']} />
              <mesh name="Mesh065_2" geometry={nodes.Mesh065_2.geometry} material={materials['MI_Copper_-cu-_scnnfhbp_4K']} />
            </group>
            <group name="StaticMeshComponent1_68" position={[0.001, 0, 41.999]}>
              <mesh name="Mesh070" geometry={nodes.Mesh070.geometry} material={materials.MI_FlatColor04} />
              <mesh name="Mesh070_1" geometry={nodes.Mesh070_1.geometry} material={materials['MI_Copper_-cu-_scnnfhbp_4K']} />
              <mesh name="Mesh070_2" geometry={nodes.Mesh070_2.geometry} material={materials['MI_Copper_-cu-_scnnfhbp_4K']} />
            </group>
            <group name="StaticMeshComponent20_87" position={[-0.001, -77, 286]}>
              <mesh name="Mesh078" geometry={nodes.Mesh078.geometry} material={materials.MI_FlatColor04} />
              <mesh name="Mesh078_1" geometry={nodes.Mesh078_1.geometry} material={materials['MI_Copper_-cu-_scnnfhbp_4K']} />
              <mesh name="Mesh078_2" geometry={nodes.Mesh078_2.geometry} material={materials['MI_Copper_-cu-_scnnfhbp_4K']} />
            </group>
            <group name="StaticMeshComponent21_88" position={[-0.001, -154, 0]}>
              <mesh name="Mesh082" geometry={nodes.Mesh082.geometry} material={materials.MI_FlatColor04} />
              <mesh name="Mesh082_1" geometry={nodes.Mesh082_1.geometry} material={materials['MI_Copper_-cu-_scnnfhbp_4K']} />
              <mesh name="Mesh082_2" geometry={nodes.Mesh082_2.geometry} material={materials['MI_Copper_-cu-_scnnfhbp_4K']} />
            </group>
            <group name="StaticMeshComponent22_89" position={[0, -154, 37]}>
              <mesh name="Mesh086" geometry={nodes.Mesh086.geometry} material={materials.MI_FlatColor04} />
              <mesh name="Mesh086_1" geometry={nodes.Mesh086_1.geometry} material={materials['MI_Copper_-cu-_scnnfhbp_4K']} />
              <mesh name="Mesh086_2" geometry={nodes.Mesh086_2.geometry} material={materials['MI_Copper_-cu-_scnnfhbp_4K']} />
            </group>
            <group name="StaticMeshComponent23_90" position={[0, -154, 74.001]}>
              <mesh name="Mesh090" geometry={nodes.Mesh090.geometry} material={materials.MI_FlatColor04} />
              <mesh name="Mesh090_1" geometry={nodes.Mesh090_1.geometry} material={materials['MI_Copper_-cu-_scnnfhbp_4K']} />
              <mesh name="Mesh090_2" geometry={nodes.Mesh090_2.geometry} material={materials['MI_Copper_-cu-_scnnfhbp_4K']} />
            </group>
            <group name="StaticMeshComponent24_91" position={[0, -154, 84]}>
              <mesh name="Mesh094" geometry={nodes.Mesh094.geometry} material={materials.MI_FlatColor04} />
              <mesh name="Mesh094_1" geometry={nodes.Mesh094_1.geometry} material={materials['MI_Copper_-cu-_scnnfhbp_4K']} />
              <mesh name="Mesh094_2" geometry={nodes.Mesh094_2.geometry} material={materials['MI_Copper_-cu-_scnnfhbp_4K']} />
            </group>
            <group name="StaticMeshComponent25_92" position={[0.002, -154, 107]}>
              <mesh name="Mesh075" geometry={nodes.Mesh075.geometry} material={materials.MI_FlatColor04} />
              <mesh name="Mesh075_1" geometry={nodes.Mesh075_1.geometry} material={materials['MI_Copper_-cu-_scnnfhbp_4K']} />
              <mesh name="Mesh075_2" geometry={nodes.Mesh075_2.geometry} material={materials['MI_Copper_-cu-_scnnfhbp_4K']} />
            </group>
            <group name="StaticMeshComponent26_93" position={[0, -154, 143]}>
              <mesh name="Mesh071" geometry={nodes.Mesh071.geometry} material={materials.MI_FlatColor04} />
              <mesh name="Mesh071_1" geometry={nodes.Mesh071_1.geometry} material={materials['MI_Copper_-cu-_scnnfhbp_4K']} />
              <mesh name="Mesh071_2" geometry={nodes.Mesh071_2.geometry} material={materials['MI_Copper_-cu-_scnnfhbp_4K']} />
            </group>
            <group name="StaticMeshComponent27_94" position={[0, -154, 182.001]}>
              <mesh name="Mesh067" geometry={nodes.Mesh067.geometry} material={materials.MI_FlatColor04} />
              <mesh name="Mesh067_1" geometry={nodes.Mesh067_1.geometry} material={materials['MI_Copper_-cu-_scnnfhbp_4K']} />
              <mesh name="Mesh067_2" geometry={nodes.Mesh067_2.geometry} material={materials['MI_Copper_-cu-_scnnfhbp_4K']} />
            </group>
            <group name="StaticMeshComponent28_95" position={[-0.001, -154, 221]}>
              <mesh name="Mesh063" geometry={nodes.Mesh063.geometry} material={materials.MI_FlatColor04} />
              <mesh name="Mesh063_1" geometry={nodes.Mesh063_1.geometry} material={materials['MI_Copper_-cu-_scnnfhbp_4K']} />
              <mesh name="Mesh063_2" geometry={nodes.Mesh063_2.geometry} material={materials['MI_Copper_-cu-_scnnfhbp_4K']} />
            </group>
            <group name="StaticMeshComponent29_96" position={[0.001, -154, 243]}>
              <mesh name="Mesh080" geometry={nodes.Mesh080.geometry} material={materials.MI_FlatColor04} />
              <mesh name="Mesh080_1" geometry={nodes.Mesh080_1.geometry} material={materials['MI_Copper_-cu-_scnnfhbp_4K']} />
              <mesh name="Mesh080_2" geometry={nodes.Mesh080_2.geometry} material={materials['MI_Copper_-cu-_scnnfhbp_4K']} />
            </group>
            <group name="StaticMeshComponent2_69" position={[0, 0, 64]}>
              <mesh name="Mesh066" geometry={nodes.Mesh066.geometry} material={materials.MI_FlatColor04} />
              <mesh name="Mesh066_1" geometry={nodes.Mesh066_1.geometry} material={materials['MI_Copper_-cu-_scnnfhbp_4K']} />
              <mesh name="Mesh066_2" geometry={nodes.Mesh066_2.geometry} material={materials['MI_Copper_-cu-_scnnfhbp_4K']} />
            </group>
            <group name="StaticMeshComponent30_97" position={[0, -154, 265.999]}>
              <mesh name="Mesh084" geometry={nodes.Mesh084.geometry} material={materials.MI_FlatColor04} />
              <mesh name="Mesh084_1" geometry={nodes.Mesh084_1.geometry} material={materials['MI_Copper_-cu-_scnnfhbp_4K']} />
              <mesh name="Mesh084_2" geometry={nodes.Mesh084_2.geometry} material={materials['MI_Copper_-cu-_scnnfhbp_4K']} />
            </group>
            <group name="StaticMeshComponent31_98" position={[0, -154, 294.001]}>
              <mesh name="Mesh088" geometry={nodes.Mesh088.geometry} material={materials.MI_FlatColor04} />
              <mesh name="Mesh088_1" geometry={nodes.Mesh088_1.geometry} material={materials['MI_Copper_-cu-_scnnfhbp_4K']} />
              <mesh name="Mesh088_2" geometry={nodes.Mesh088_2.geometry} material={materials['MI_Copper_-cu-_scnnfhbp_4K']} />
            </group>
            <group name="StaticMeshComponent32_99" position={[-0.001, -154, 337]}>
              <mesh name="Mesh092" geometry={nodes.Mesh092.geometry} material={materials.MI_FlatColor04} />
              <mesh name="Mesh092_1" geometry={nodes.Mesh092_1.geometry} material={materials['MI_Copper_-cu-_scnnfhbp_4K']} />
              <mesh name="Mesh092_2" geometry={nodes.Mesh092_2.geometry} material={materials['MI_Copper_-cu-_scnnfhbp_4K']} />
            </group>
            <group name="StaticMeshComponent3_70" position={[0, 0, 101]}>
              <mesh name="Mesh062" geometry={nodes.Mesh062.geometry} material={materials.MI_FlatColor04} />
              <mesh name="Mesh062_1" geometry={nodes.Mesh062_1.geometry} material={materials['MI_Copper_-cu-_scnnfhbp_4K']} />
              <mesh name="Mesh062_2" geometry={nodes.Mesh062_2.geometry} material={materials['MI_Copper_-cu-_scnnfhbp_4K']} />
            </group>
            <group name="StaticMeshComponent4_71" position={[0.001, 0, 140]}>
              <mesh name="Mesh081" geometry={nodes.Mesh081.geometry} material={materials.MI_FlatColor04} />
              <mesh name="Mesh081_1" geometry={nodes.Mesh081_1.geometry} material={materials['MI_Copper_-cu-_scnnfhbp_4K']} />
              <mesh name="Mesh081_2" geometry={nodes.Mesh081_2.geometry} material={materials['MI_Copper_-cu-_scnnfhbp_4K']} />
            </group>
            <group name="StaticMeshComponent5_72" position={[0, 0, 177.001]}>
              <mesh name="Mesh085" geometry={nodes.Mesh085.geometry} material={materials.MI_FlatColor04} />
              <mesh name="Mesh085_1" geometry={nodes.Mesh085_1.geometry} material={materials['MI_Copper_-cu-_scnnfhbp_4K']} />
              <mesh name="Mesh085_2" geometry={nodes.Mesh085_2.geometry} material={materials['MI_Copper_-cu-_scnnfhbp_4K']} />
            </group>
            <group name="StaticMeshComponent6_73" position={[0, 0, 193.999]}>
              <mesh name="Mesh089" geometry={nodes.Mesh089.geometry} material={materials.MI_FlatColor04} />
              <mesh name="Mesh089_1" geometry={nodes.Mesh089_1.geometry} material={materials['MI_Copper_-cu-_scnnfhbp_4K']} />
              <mesh name="Mesh089_2" geometry={nodes.Mesh089_2.geometry} material={materials['MI_Copper_-cu-_scnnfhbp_4K']} />
            </group>
            <group name="StaticMeshComponent7_74" position={[-0.001, 0, 226]}>
              <mesh name="Mesh093" geometry={nodes.Mesh093.geometry} material={materials.MI_FlatColor04} />
              <mesh name="Mesh093_1" geometry={nodes.Mesh093_1.geometry} material={materials['MI_Copper_-cu-_scnnfhbp_4K']} />
              <mesh name="Mesh093_2" geometry={nodes.Mesh093_2.geometry} material={materials['MI_Copper_-cu-_scnnfhbp_4K']} />
            </group>
            <group name="StaticMeshComponent8_75" position={[0.001, 0, 263.001]}>
              <mesh name="Mesh076" geometry={nodes.Mesh076.geometry} material={materials.MI_FlatColor04} />
              <mesh name="Mesh076_1" geometry={nodes.Mesh076_1.geometry} material={materials['MI_Copper_-cu-_scnnfhbp_4K']} />
              <mesh name="Mesh076_2" geometry={nodes.Mesh076_2.geometry} material={materials['MI_Copper_-cu-_scnnfhbp_4K']} />
            </group>
            <group name="StaticMeshComponent9_76" position={[0, 0, 322.999]}>
              <mesh name="Mesh072" geometry={nodes.Mesh072.geometry} material={materials.MI_FlatColor04} />
              <mesh name="Mesh072_1" geometry={nodes.Mesh072_1.geometry} material={materials['MI_Copper_-cu-_scnnfhbp_4K']} />
              <mesh name="Mesh072_2" geometry={nodes.Mesh072_2.geometry} material={materials['MI_Copper_-cu-_scnnfhbp_4K']} />
            </group>
          </group>
          <group name="Text3DActor3" position={[423.152, -169.887, -414.312]} rotation={[0, 0.028, 0]} scale={0.03}>
            <group name="StaticMeshComponent0_37" position={[-0.001, 0, -0.001]}>
              <mesh name="Mesh100" geometry={nodes.Mesh100.geometry} material={materials.MI_FlatColor04} />
              <mesh name="Mesh100_1" geometry={nodes.Mesh100_1.geometry} material={materials['MI_Copper_-cu-_scnnfhbp_4K']} />
              <mesh name="Mesh100_2" geometry={nodes.Mesh100_2.geometry} material={materials['MI_Copper_-cu-_scnnfhbp_4K']} />
            </group>
            <group name="StaticMeshComponent10_47" position={[-0.001, 0, 354.997]}>
              <mesh name="Mesh097" geometry={nodes.Mesh097.geometry} material={materials.MI_FlatColor04} />
              <mesh name="Mesh097_1" geometry={nodes.Mesh097_1.geometry} material={materials['MI_Copper_-cu-_scnnfhbp_4K']} />
              <mesh name="Mesh097_2" geometry={nodes.Mesh097_2.geometry} material={materials['MI_Copper_-cu-_scnnfhbp_4K']} />
            </group>
            <group name="StaticMeshComponent11_48" position={[0, 0, 389.997]}>
              <mesh name="Mesh105" geometry={nodes.Mesh105.geometry} material={materials.MI_FlatColor04} />
              <mesh name="Mesh105_1" geometry={nodes.Mesh105_1.geometry} material={materials['MI_Copper_-cu-_scnnfhbp_4K']} />
              <mesh name="Mesh105_2" geometry={nodes.Mesh105_2.geometry} material={materials['MI_Copper_-cu-_scnnfhbp_4K']} />
            </group>
            <group name="StaticMeshComponent12_49" position={[0, 0, 426.998]}>
              <mesh name="Mesh109" geometry={nodes.Mesh109.geometry} material={materials.MI_FlatColor04} />
              <mesh name="Mesh109_1" geometry={nodes.Mesh109_1.geometry} material={materials['MI_Copper_-cu-_scnnfhbp_4K']} />
              <mesh name="Mesh109_2" geometry={nodes.Mesh109_2.geometry} material={materials['MI_Copper_-cu-_scnnfhbp_4K']} />
            </group>
            <group name="StaticMeshComponent13_50" position={[0.002, 0, 464.001]}>
              <mesh name="Mesh103" geometry={nodes.Mesh103.geometry} material={materials.MI_FlatColor04} />
              <mesh name="Mesh103_1" geometry={nodes.Mesh103_1.geometry} material={materials['MI_Copper_-cu-_scnnfhbp_4K']} />
              <mesh name="Mesh103_2" geometry={nodes.Mesh103_2.geometry} material={materials['MI_Copper_-cu-_scnnfhbp_4K']} />
            </group>
            <group name="StaticMeshComponent14_51" position={[0, 0, 473.998]}>
              <mesh name="Mesh099" geometry={nodes.Mesh099.geometry} material={materials.MI_FlatColor04} />
              <mesh name="Mesh099_1" geometry={nodes.Mesh099_1.geometry} material={materials['MI_Copper_-cu-_scnnfhbp_4K']} />
              <mesh name="Mesh099_2" geometry={nodes.Mesh099_2.geometry} material={materials['MI_Copper_-cu-_scnnfhbp_4K']} />
            </group>
            <group name="StaticMeshComponent15_52" position={[0.001, 0, 497]}>
              <mesh name="Mesh095" geometry={nodes.Mesh095.geometry} material={materials.MI_FlatColor04} />
              <mesh name="Mesh095_1" geometry={nodes.Mesh095_1.geometry} material={materials['MI_Copper_-cu-_scnnfhbp_4K']} />
              <mesh name="Mesh095_2" geometry={nodes.Mesh095_2.geometry} material={materials['MI_Copper_-cu-_scnnfhbp_4K']} />
            </group>
            <group name="StaticMeshComponent16_53" position={[0, 0, 532.999]}>
              <mesh name="Mesh107" geometry={nodes.Mesh107.geometry} material={materials.MI_FlatColor04} />
              <mesh name="Mesh107_1" geometry={nodes.Mesh107_1.geometry} material={materials['MI_Copper_-cu-_scnnfhbp_4K']} />
              <mesh name="Mesh107_2" geometry={nodes.Mesh107_2.geometry} material={materials['MI_Copper_-cu-_scnnfhbp_4K']} />
            </group>
            <group name="StaticMeshComponent17_54" position={[0.001, 0, 572.997]}>
              <mesh name="Mesh111" geometry={nodes.Mesh111.geometry} material={materials.MI_FlatColor04} />
              <mesh name="Mesh111_1" geometry={nodes.Mesh111_1.geometry} material={materials['MI_Copper_-cu-_scnnfhbp_4K']} />
              <mesh name="Mesh111_2" geometry={nodes.Mesh111_2.geometry} material={materials['MI_Copper_-cu-_scnnfhbp_4K']} />
            </group>
            <group name="StaticMeshComponent1_38" position={[-0.001, 0, 42.997]}>
              <mesh name="Mesh096" geometry={nodes.Mesh096.geometry} material={materials.MI_FlatColor04} />
              <mesh name="Mesh096_1" geometry={nodes.Mesh096_1.geometry} material={materials['MI_Copper_-cu-_scnnfhbp_4K']} />
              <mesh name="Mesh096_2" geometry={nodes.Mesh096_2.geometry} material={materials['MI_Copper_-cu-_scnnfhbp_4K']} />
            </group>
            <group name="StaticMeshComponent2_39" position={[0.001, 0, 79]}>
              <mesh name="Mesh106" geometry={nodes.Mesh106.geometry} material={materials.MI_FlatColor04} />
              <mesh name="Mesh106_1" geometry={nodes.Mesh106_1.geometry} material={materials['MI_Copper_-cu-_scnnfhbp_4K']} />
              <mesh name="Mesh106_2" geometry={nodes.Mesh106_2.geometry} material={materials['MI_Copper_-cu-_scnnfhbp_4K']} />
            </group>
            <group name="StaticMeshComponent3_40" position={[0, 0, 119.998]}>
              <mesh name="Mesh110" geometry={nodes.Mesh110.geometry} material={materials.MI_FlatColor04} />
              <mesh name="Mesh110_1" geometry={nodes.Mesh110_1.geometry} material={materials['MI_Copper_-cu-_scnnfhbp_4K']} />
              <mesh name="Mesh110_2" geometry={nodes.Mesh110_2.geometry} material={materials['MI_Copper_-cu-_scnnfhbp_4K']} />
            </group>
            <group name="StaticMeshComponent4_41" position={[0, 0, 156.998]}>
              <mesh name="Mesh102" geometry={nodes.Mesh102.geometry} material={materials.MI_FlatColor04} />
              <mesh name="Mesh102_1" geometry={nodes.Mesh102_1.geometry} material={materials['MI_Copper_-cu-_scnnfhbp_4K']} />
              <mesh name="Mesh102_2" geometry={nodes.Mesh102_2.geometry} material={materials['MI_Copper_-cu-_scnnfhbp_4K']} />
            </group>
            <group name="StaticMeshComponent5_42" position={[-0.001, 0, 173.998]}>
              <mesh name="Mesh098" geometry={nodes.Mesh098.geometry} material={materials.MI_FlatColor04} />
              <mesh name="Mesh098_1" geometry={nodes.Mesh098_1.geometry} material={materials['MI_Copper_-cu-_scnnfhbp_4K']} />
              <mesh name="Mesh098_2" geometry={nodes.Mesh098_2.geometry} material={materials['MI_Copper_-cu-_scnnfhbp_4K']} />
            </group>
            <group name="StaticMeshComponent6_43" position={[0.001, 0, 207.998]}>
              <mesh name="Mesh104" geometry={nodes.Mesh104.geometry} material={materials.MI_FlatColor04} />
              <mesh name="Mesh104_1" geometry={nodes.Mesh104_1.geometry} material={materials['MI_Copper_-cu-_scnnfhbp_4K']} />
              <mesh name="Mesh104_2" geometry={nodes.Mesh104_2.geometry} material={materials['MI_Copper_-cu-_scnnfhbp_4K']} />
            </group>
            <group name="StaticMeshComponent7_44" position={[-0.001, 0, 245]}>
              <mesh name="Mesh108" geometry={nodes.Mesh108.geometry} material={materials.MI_FlatColor04} />
              <mesh name="Mesh108_1" geometry={nodes.Mesh108_1.geometry} material={materials['MI_Copper_-cu-_scnnfhbp_4K']} />
              <mesh name="Mesh108_2" geometry={nodes.Mesh108_2.geometry} material={materials['MI_Copper_-cu-_scnnfhbp_4K']} />
            </group>
            <group name="StaticMeshComponent8_45" position={[-0.001, 0, 288]}>
              <mesh name="Mesh112" geometry={nodes.Mesh112.geometry} material={materials.MI_FlatColor04} />
              <mesh name="Mesh112_1" geometry={nodes.Mesh112_1.geometry} material={materials['MI_Copper_-cu-_scnnfhbp_4K']} />
              <mesh name="Mesh112_2" geometry={nodes.Mesh112_2.geometry} material={materials['MI_Copper_-cu-_scnnfhbp_4K']} />
            </group>
            <group name="StaticMeshComponent9_46" position={[0.001, 0, 329]}>
              <mesh name="Mesh101" geometry={nodes.Mesh101.geometry} material={materials.MI_FlatColor04} />
              <mesh name="Mesh101_1" geometry={nodes.Mesh101_1.geometry} material={materials['MI_Copper_-cu-_scnnfhbp_4K']} />
              <mesh name="Mesh101_2" geometry={nodes.Mesh101_2.geometry} material={materials['MI_Copper_-cu-_scnnfhbp_4K']} />
            </group>
          </group>
          <group name="Text3DActor4" position={[423.152, -168.468, -332.607]} rotation={[0, 0.028, 0]} scale={0.03}>
            <group name="StaticMeshComponent0_71001" position={[0, 0, 0]}>
              <mesh name="Mesh154" geometry={nodes.Mesh154.geometry} material={materials.MI_FlatColor04} />
              <mesh name="Mesh154_1" geometry={nodes.Mesh154_1.geometry} material={materials['MI_Copper_-cu-_scnnfhbp_4K']} />
              <mesh name="Mesh154_2" geometry={nodes.Mesh154_2.geometry} material={materials['MI_Copper_-cu-_scnnfhbp_4K']} />
            </group>
            <group name="StaticMeshComponent10_81001" position={[0, 0, 372]}>
              <mesh name="Mesh149" geometry={nodes.Mesh149.geometry} material={materials.MI_FlatColor04} />
              <mesh name="Mesh149_1" geometry={nodes.Mesh149_1.geometry} material={materials['MI_Copper_-cu-_scnnfhbp_4K']} />
              <mesh name="Mesh149_2" geometry={nodes.Mesh149_2.geometry} material={materials['MI_Copper_-cu-_scnnfhbp_4K']} />
            </group>
            <group name="StaticMeshComponent11_82001" position={[0, 0, 425.999]}>
              <mesh name="Mesh145" geometry={nodes.Mesh145.geometry} material={materials.MI_FlatColor04} />
              <mesh name="Mesh145_1" geometry={nodes.Mesh145_1.geometry} material={materials['MI_Copper_-cu-_scnnfhbp_4K']} />
              <mesh name="Mesh145_2" geometry={nodes.Mesh145_2.geometry} material={materials['MI_Copper_-cu-_scnnfhbp_4K']} />
            </group>
            <group name="StaticMeshComponent12_83001" position={[0, 0, 469]}>
              <mesh name="Mesh141" geometry={nodes.Mesh141.geometry} material={materials.MI_FlatColor04} />
              <mesh name="Mesh141_1" geometry={nodes.Mesh141_1.geometry} material={materials['MI_Copper_-cu-_scnnfhbp_4K']} />
              <mesh name="Mesh141_2" geometry={nodes.Mesh141_2.geometry} material={materials['MI_Copper_-cu-_scnnfhbp_4K']} />
            </group>
            <group name="StaticMeshComponent13_84001" position={[0.001, 0, 485.998]}>
              <mesh name="Mesh159" geometry={nodes.Mesh159.geometry} material={materials.MI_FlatColor04} />
              <mesh name="Mesh159_1" geometry={nodes.Mesh159_1.geometry} material={materials['MI_Copper_-cu-_scnnfhbp_4K']} />
              <mesh name="Mesh159_2" geometry={nodes.Mesh159_2.geometry} material={materials['MI_Copper_-cu-_scnnfhbp_4K']} />
            </group>
            <group name="StaticMeshComponent14_85001" position={[0, 0, 518]}>
              <mesh name="Mesh163" geometry={nodes.Mesh163.geometry} material={materials.MI_FlatColor04} />
              <mesh name="Mesh163_1" geometry={nodes.Mesh163_1.geometry} material={materials['MI_Copper_-cu-_scnnfhbp_4K']} />
              <mesh name="Mesh163_2" geometry={nodes.Mesh163_2.geometry} material={materials['MI_Copper_-cu-_scnnfhbp_4K']} />
            </group>
            <group name="StaticMeshComponent15_86001" position={[0, 0, 560.998]}>
              <mesh name="Mesh167" geometry={nodes.Mesh167.geometry} material={materials.MI_FlatColor04} />
              <mesh name="Mesh167_1" geometry={nodes.Mesh167_1.geometry} material={materials['MI_Copper_-cu-_scnnfhbp_4K']} />
              <mesh name="Mesh167_2" geometry={nodes.Mesh167_2.geometry} material={materials['MI_Copper_-cu-_scnnfhbp_4K']} />
            </group>
            <group name="StaticMeshComponent16_87001" position={[0, -77, 0]}>
              <mesh name="Mesh171" geometry={nodes.Mesh171.geometry} material={materials.MI_FlatColor04} />
              <mesh name="Mesh171_1" geometry={nodes.Mesh171_1.geometry} material={materials['MI_Copper_-cu-_scnnfhbp_4K']} />
              <mesh name="Mesh171_2" geometry={nodes.Mesh171_2.geometry} material={materials['MI_Copper_-cu-_scnnfhbp_4K']} />
            </group>
            <group name="StaticMeshComponent17_88001" position={[-0.001, -77, 34.999]}>
              <mesh name="Mesh156" geometry={nodes.Mesh156.geometry} material={materials.MI_FlatColor04} />
              <mesh name="Mesh156_1" geometry={nodes.Mesh156_1.geometry} material={materials['MI_Copper_-cu-_scnnfhbp_4K']} />
              <mesh name="Mesh156_2" geometry={nodes.Mesh156_2.geometry} material={materials['MI_Copper_-cu-_scnnfhbp_4K']} />
            </group>
            <group name="StaticMeshComponent18_89001" position={[0, -77, 74.001]}>
              <mesh name="Mesh152" geometry={nodes.Mesh152.geometry} material={materials.MI_FlatColor04} />
              <mesh name="Mesh152_1" geometry={nodes.Mesh152_1.geometry} material={materials['MI_Copper_-cu-_scnnfhbp_4K']} />
              <mesh name="Mesh152_2" geometry={nodes.Mesh152_2.geometry} material={materials['MI_Copper_-cu-_scnnfhbp_4K']} />
            </group>
            <group name="StaticMeshComponent19_90001" position={[-0.002, -77, 111]}>
              <mesh name="Mesh148" geometry={nodes.Mesh148.geometry} material={materials.MI_FlatColor04} />
              <mesh name="Mesh148_1" geometry={nodes.Mesh148_1.geometry} material={materials['MI_Copper_-cu-_scnnfhbp_4K']} />
              <mesh name="Mesh148_2" geometry={nodes.Mesh148_2.geometry} material={materials['MI_Copper_-cu-_scnnfhbp_4K']} />
            </group>
            <group name="StaticMeshComponent1_72001" position={[0, 0, 45.999]}>
              <mesh name="Mesh150" geometry={nodes.Mesh150.geometry} material={materials.MI_FlatColor04} />
              <mesh name="Mesh150_1" geometry={nodes.Mesh150_1.geometry} material={materials['MI_Copper_-cu-_scnnfhbp_4K']} />
              <mesh name="Mesh150_2" geometry={nodes.Mesh150_2.geometry} material={materials['MI_Copper_-cu-_scnnfhbp_4K']} />
            </group>
            <group name="StaticMeshComponent20_91001" position={[0, -77, 169.001]}>
              <mesh name="Mesh144" geometry={nodes.Mesh144.geometry} material={materials.MI_FlatColor04} />
              <mesh name="Mesh144_1" geometry={nodes.Mesh144_1.geometry} material={materials['MI_Copper_-cu-_scnnfhbp_4K']} />
              <mesh name="Mesh144_2" geometry={nodes.Mesh144_2.geometry} material={materials['MI_Copper_-cu-_scnnfhbp_4K']} />
            </group>
            <group name="StaticMeshComponent21_92001" position={[0.001, -77, 214.999]}>
              <mesh name="Mesh140" geometry={nodes.Mesh140.geometry} material={materials.MI_FlatColor04} />
              <mesh name="Mesh140_1" geometry={nodes.Mesh140_1.geometry} material={materials['MI_Copper_-cu-_scnnfhbp_4K']} />
              <mesh name="Mesh140_2" geometry={nodes.Mesh140_2.geometry} material={materials['MI_Copper_-cu-_scnnfhbp_4K']} />
            </group>
            <group name="StaticMeshComponent22_93001" position={[0.001, -77, 251.999]}>
              <mesh name="Mesh160" geometry={nodes.Mesh160.geometry} material={materials.MI_FlatColor04} />
              <mesh name="Mesh160_1" geometry={nodes.Mesh160_1.geometry} material={materials['MI_Copper_-cu-_scnnfhbp_4K']} />
              <mesh name="Mesh160_2" geometry={nodes.Mesh160_2.geometry} material={materials['MI_Copper_-cu-_scnnfhbp_4K']} />
            </group>
            <group name="StaticMeshComponent23_94001" position={[-0.002, -77, 291]}>
              <mesh name="Mesh164" geometry={nodes.Mesh164.geometry} material={materials.MI_FlatColor04} />
              <mesh name="Mesh164_1" geometry={nodes.Mesh164_1.geometry} material={materials['MI_Copper_-cu-_scnnfhbp_4K']} />
              <mesh name="Mesh164_2" geometry={nodes.Mesh164_2.geometry} material={materials['MI_Copper_-cu-_scnnfhbp_4K']} />
            </group>
            <group name="StaticMeshComponent24_95001" position={[0.002, -77, 317]}>
              <mesh name="Mesh168" geometry={nodes.Mesh168.geometry} material={materials.MI_FlatColor04} />
              <mesh name="Mesh168_1" geometry={nodes.Mesh168_1.geometry} material={materials['MI_Copper_-cu-_scnnfhbp_4K']} />
              <mesh name="Mesh168_2" geometry={nodes.Mesh168_2.geometry} material={materials['MI_Copper_-cu-_scnnfhbp_4K']} />
            </group>
            <group name="StaticMeshComponent25_96001" position={[0, -77, 334]}>
              <mesh name="Mesh172" geometry={nodes.Mesh172.geometry} material={materials.MI_FlatColor04} />
              <mesh name="Mesh172_1" geometry={nodes.Mesh172_1.geometry} material={materials['MI_Copper_-cu-_scnnfhbp_4K']} />
              <mesh name="Mesh172_2" geometry={nodes.Mesh172_2.geometry} material={materials['MI_Copper_-cu-_scnnfhbp_4K']} />
            </group>
            <group name="StaticMeshComponent26_97001" position={[0, -77, 364]}>
              <mesh name="Mesh155" geometry={nodes.Mesh155.geometry} material={materials.MI_FlatColor04} />
              <mesh name="Mesh155_1" geometry={nodes.Mesh155_1.geometry} material={materials['MI_Copper_-cu-_scnnfhbp_4K']} />
              <mesh name="Mesh155_2" geometry={nodes.Mesh155_2.geometry} material={materials['MI_Copper_-cu-_scnnfhbp_4K']} />
            </group>
            <group name="StaticMeshComponent27_98001" position={[0, -77, 389.999]}>
              <mesh name="Mesh151" geometry={nodes.Mesh151.geometry} material={materials.MI_FlatColor04} />
              <mesh name="Mesh151_1" geometry={nodes.Mesh151_1.geometry} material={materials['MI_Copper_-cu-_scnnfhbp_4K']} />
              <mesh name="Mesh151_2" geometry={nodes.Mesh151_2.geometry} material={materials['MI_Copper_-cu-_scnnfhbp_4K']} />
            </group>
            <group name="StaticMeshComponent28_99001" position={[0, -77, 446]}>
              <mesh name="Mesh147" geometry={nodes.Mesh147.geometry} material={materials.MI_FlatColor04} />
              <mesh name="Mesh147_1" geometry={nodes.Mesh147_1.geometry} material={materials['MI_Copper_-cu-_scnnfhbp_4K']} />
              <mesh name="Mesh147_2" geometry={nodes.Mesh147_2.geometry} material={materials['MI_Copper_-cu-_scnnfhbp_4K']} />
            </group>
            <group name="StaticMeshComponent29_100001" position={[0, -77, 492.999]}>
              <mesh name="Mesh143" geometry={nodes.Mesh143.geometry} material={materials.MI_FlatColor04} />
              <mesh name="Mesh143_1" geometry={nodes.Mesh143_1.geometry} material={materials['MI_Copper_-cu-_scnnfhbp_4K']} />
              <mesh name="Mesh143_2" geometry={nodes.Mesh143_2.geometry} material={materials['MI_Copper_-cu-_scnnfhbp_4K']} />
            </group>
            <group name="StaticMeshComponent2_73001" position={[0, 0, 84]}>
              <mesh name="Mesh146" geometry={nodes.Mesh146.geometry} material={materials.MI_FlatColor04} />
              <mesh name="Mesh146_1" geometry={nodes.Mesh146_1.geometry} material={materials['MI_Copper_-cu-_scnnfhbp_4K']} />
              <mesh name="Mesh146_2" geometry={nodes.Mesh146_2.geometry} material={materials['MI_Copper_-cu-_scnnfhbp_4K']} />
            </group>
            <group name="StaticMeshComponent30_101001" position={[0.001, -77, 531]}>
              <mesh name="Mesh139" geometry={nodes.Mesh139.geometry} material={materials.MI_FlatColor04} />
              <mesh name="Mesh139_1" geometry={nodes.Mesh139_1.geometry} material={materials['MI_Copper_-cu-_scnnfhbp_4K']} />
              <mesh name="Mesh139_2" geometry={nodes.Mesh139_2.geometry} material={materials['MI_Copper_-cu-_scnnfhbp_4K']} />
            </group>
            <group name="StaticMeshComponent31_102001" position={[0.003, -77, 557]}>
              <mesh name="Mesh161" geometry={nodes.Mesh161.geometry} material={materials.MI_FlatColor04} />
              <mesh name="Mesh161_1" geometry={nodes.Mesh161_1.geometry} material={materials['MI_Copper_-cu-_scnnfhbp_4K']} />
              <mesh name="Mesh161_2" geometry={nodes.Mesh161_2.geometry} material={materials['MI_Copper_-cu-_scnnfhbp_4K']} />
            </group>
            <group name="StaticMeshComponent32_103001" position={[0.001, -77, 594.999]}>
              <mesh name="Mesh165" geometry={nodes.Mesh165.geometry} material={materials.MI_FlatColor04} />
              <mesh name="Mesh165_1" geometry={nodes.Mesh165_1.geometry} material={materials['MI_Copper_-cu-_scnnfhbp_4K']} />
              <mesh name="Mesh165_2" geometry={nodes.Mesh165_2.geometry} material={materials['MI_Copper_-cu-_scnnfhbp_4K']} />
            </group>
            <group name="StaticMeshComponent33_104001" position={[0.002, -77, 632.998]}>
              <mesh name="Mesh169" geometry={nodes.Mesh169.geometry} material={materials.MI_FlatColor04} />
              <mesh name="Mesh169_1" geometry={nodes.Mesh169_1.geometry} material={materials['MI_Copper_-cu-_scnnfhbp_4K']} />
              <mesh name="Mesh169_2" geometry={nodes.Mesh169_2.geometry} material={materials['MI_Copper_-cu-_scnnfhbp_4K']} />
            </group>
            <group name="StaticMeshComponent34_105001" position={[0, -77, 655]}>
              <mesh name="Mesh173" geometry={nodes.Mesh173.geometry} material={materials.MI_FlatColor04} />
              <mesh name="Mesh173_1" geometry={nodes.Mesh173_1.geometry} material={materials['MI_Copper_-cu-_scnnfhbp_4K']} />
              <mesh name="Mesh173_2" geometry={nodes.Mesh173_2.geometry} material={materials['MI_Copper_-cu-_scnnfhbp_4K']} />
            </group>
            <group name="StaticMeshComponent3_74001" position={[-0.001, 0, 110.001]}>
              <mesh name="Mesh142" geometry={nodes.Mesh142.geometry} material={materials.MI_FlatColor04} />
              <mesh name="Mesh142_1" geometry={nodes.Mesh142_1.geometry} material={materials['MI_Copper_-cu-_scnnfhbp_4K']} />
              <mesh name="Mesh142_2" geometry={nodes.Mesh142_2.geometry} material={materials['MI_Copper_-cu-_scnnfhbp_4K']} />
            </group>
            <group name="StaticMeshComponent4_75001" position={[0, 0, 131.999]}>
              <mesh name="Mesh158" geometry={nodes.Mesh158.geometry} material={materials.MI_FlatColor04} />
              <mesh name="Mesh158_1" geometry={nodes.Mesh158_1.geometry} material={materials['MI_Copper_-cu-_scnnfhbp_4K']} />
              <mesh name="Mesh158_2" geometry={nodes.Mesh158_2.geometry} material={materials['MI_Copper_-cu-_scnnfhbp_4K']} />
            </group>
            <group name="StaticMeshComponent5_76001" position={[0, 0, 169.001]}>
              <mesh name="Mesh162" geometry={nodes.Mesh162.geometry} material={materials.MI_FlatColor04} />
              <mesh name="Mesh162_1" geometry={nodes.Mesh162_1.geometry} material={materials['MI_Copper_-cu-_scnnfhbp_4K']} />
              <mesh name="Mesh162_2" geometry={nodes.Mesh162_2.geometry} material={materials['MI_Copper_-cu-_scnnfhbp_4K']} />
            </group>
            <group name="StaticMeshComponent6_77001" position={[0, 0, 195.002]}>
              <mesh name="Mesh166" geometry={nodes.Mesh166.geometry} material={materials.MI_FlatColor04} />
              <mesh name="Mesh166_1" geometry={nodes.Mesh166_1.geometry} material={materials['MI_Copper_-cu-_scnnfhbp_4K']} />
              <mesh name="Mesh166_2" geometry={nodes.Mesh166_2.geometry} material={materials['MI_Copper_-cu-_scnnfhbp_4K']} />
            </group>
            <group name="StaticMeshComponent7_78001" position={[0, 0, 255]}>
              <mesh name="Mesh170" geometry={nodes.Mesh170.geometry} material={materials.MI_FlatColor04} />
              <mesh name="Mesh170_1" geometry={nodes.Mesh170_1.geometry} material={materials['MI_Copper_-cu-_scnnfhbp_4K']} />
              <mesh name="Mesh170_2" geometry={nodes.Mesh170_2.geometry} material={materials['MI_Copper_-cu-_scnnfhbp_4K']} />
            </group>
            <group name="StaticMeshComponent8_79001" position={[0, 0, 298.002]}>
              <mesh name="Mesh157" geometry={nodes.Mesh157.geometry} material={materials.MI_FlatColor04} />
              <mesh name="Mesh157_1" geometry={nodes.Mesh157_1.geometry} material={materials['MI_Copper_-cu-_scnnfhbp_4K']} />
              <mesh name="Mesh157_2" geometry={nodes.Mesh157_2.geometry} material={materials['MI_Copper_-cu-_scnnfhbp_4K']} />
            </group>
            <group name="StaticMeshComponent9_80001" position={[-0.001, 0, 336]}>
              <mesh name="Mesh153" geometry={nodes.Mesh153.geometry} material={materials.MI_FlatColor04} />
              <mesh name="Mesh153_1" geometry={nodes.Mesh153_1.geometry} material={materials['MI_Copper_-cu-_scnnfhbp_4K']} />
              <mesh name="Mesh153_2" geometry={nodes.Mesh153_2.geometry} material={materials['MI_Copper_-cu-_scnnfhbp_4K']} />
            </group>
          </group>
          <group name="Text3DActor5" position={[423.152, -204.955, -219.826]} rotation={[0, 0.028, 0]} scale={0.045}>
            <group name="StaticMeshComponent0_53">
              <mesh name="Mesh122" geometry={nodes.Mesh122.geometry} material={materials.MI_FlatColor04} />
              <mesh name="Mesh122_1" geometry={nodes.Mesh122_1.geometry} material={materials['MI_Copper_-cu-_scnnfhbp_4K']} />
              <mesh name="Mesh122_2" geometry={nodes.Mesh122_2.geometry} material={materials['MI_Copper_-cu-_scnnfhbp_4K']} />
            </group>
            <group name="StaticMeshComponent10_63" position={[0, -77, 0]}>
              <mesh name="Mesh130" geometry={nodes.Mesh130.geometry} material={materials.MI_FlatColor04} />
              <mesh name="Mesh130_1" geometry={nodes.Mesh130_1.geometry} material={materials['MI_Copper_-cu-_scnnfhbp_4K']} />
              <mesh name="Mesh130_2" geometry={nodes.Mesh130_2.geometry} material={materials['MI_Copper_-cu-_scnnfhbp_4K']} />
            </group>
            <group name="StaticMeshComponent11_64" position={[-0.001, -77, 41]}>
              <mesh name="Mesh134" geometry={nodes.Mesh134.geometry} material={materials.MI_FlatColor04} />
              <mesh name="Mesh134_1" geometry={nodes.Mesh134_1.geometry} material={materials['MI_Copper_-cu-_scnnfhbp_4K']} />
              <mesh name="Mesh134_2" geometry={nodes.Mesh134_2.geometry} material={materials['MI_Copper_-cu-_scnnfhbp_4K']} />
            </group>
            <group name="StaticMeshComponent12_65" position={[0, -77, 78]}>
              <mesh name="Mesh138" geometry={nodes.Mesh138.geometry} material={materials.MI_FlatColor04} />
              <mesh name="Mesh138_1" geometry={nodes.Mesh138_1.geometry} material={materials['MI_Copper_-cu-_scnnfhbp_4K']} />
              <mesh name="Mesh138_2" geometry={nodes.Mesh138_2.geometry} material={materials['MI_Copper_-cu-_scnnfhbp_4K']} />
            </group>
            <group name="StaticMeshComponent13_66" position={[0, -77, 136]}>
              <mesh name="Mesh123" geometry={nodes.Mesh123.geometry} material={materials.MI_FlatColor04} />
              <mesh name="Mesh123_1" geometry={nodes.Mesh123_1.geometry} material={materials['MI_Copper_-cu-_scnnfhbp_4K']} />
              <mesh name="Mesh123_2" geometry={nodes.Mesh123_2.geometry} material={materials['MI_Copper_-cu-_scnnfhbp_4K']} />
            </group>
            <group name="StaticMeshComponent14_67" position={[-0.002, -77, 181.999]}>
              <mesh name="Mesh119" geometry={nodes.Mesh119.geometry} material={materials.MI_FlatColor04} />
              <mesh name="Mesh119_1" geometry={nodes.Mesh119_1.geometry} material={materials['MI_Copper_-cu-_scnnfhbp_4K']} />
              <mesh name="Mesh119_2" geometry={nodes.Mesh119_2.geometry} material={materials['MI_Copper_-cu-_scnnfhbp_4K']} />
            </group>
            <group name="StaticMeshComponent15_68" position={[-0.001, -77, 220]}>
              <mesh name="Mesh115" geometry={nodes.Mesh115.geometry} material={materials.MI_FlatColor04} />
              <mesh name="Mesh115_1" geometry={nodes.Mesh115_1.geometry} material={materials['MI_Copper_-cu-_scnnfhbp_4K']} />
              <mesh name="Mesh115_2" geometry={nodes.Mesh115_2.geometry} material={materials['MI_Copper_-cu-_scnnfhbp_4K']} />
            </group>
            <group name="StaticMeshComponent16_69" position={[0, -77, 246]}>
              <mesh name="Mesh127" geometry={nodes.Mesh127.geometry} material={materials.MI_FlatColor04} />
              <mesh name="Mesh127_1" geometry={nodes.Mesh127_1.geometry} material={materials['MI_Copper_-cu-_scnnfhbp_4K']} />
              <mesh name="Mesh127_2" geometry={nodes.Mesh127_2.geometry} material={materials['MI_Copper_-cu-_scnnfhbp_4K']} />
            </group>
            <group name="StaticMeshComponent17_70" position={[0, -77, 285]}>
              <mesh name="Mesh131" geometry={nodes.Mesh131.geometry} material={materials.MI_FlatColor04} />
              <mesh name="Mesh131_1" geometry={nodes.Mesh131_1.geometry} material={materials['MI_Copper_-cu-_scnnfhbp_4K']} />
              <mesh name="Mesh131_2" geometry={nodes.Mesh131_2.geometry} material={materials['MI_Copper_-cu-_scnnfhbp_4K']} />
            </group>
            <group name="StaticMeshComponent18_71" position={[0.001, -77, 322]}>
              <mesh name="Mesh135" geometry={nodes.Mesh135.geometry} material={materials.MI_FlatColor04} />
              <mesh name="Mesh135_1" geometry={nodes.Mesh135_1.geometry} material={materials['MI_Copper_-cu-_scnnfhbp_4K']} />
              <mesh name="Mesh135_2" geometry={nodes.Mesh135_2.geometry} material={materials['MI_Copper_-cu-_scnnfhbp_4K']} />
            </group>
            <group name="StaticMeshComponent19_72" position={[-0.001, -77, 348]}>
              <mesh name="Mesh125" geometry={nodes.Mesh125.geometry} material={materials.MI_FlatColor04} />
              <mesh name="Mesh125_1" geometry={nodes.Mesh125_1.geometry} material={materials['MI_Copper_-cu-_scnnfhbp_4K']} />
              <mesh name="Mesh125_2" geometry={nodes.Mesh125_2.geometry} material={materials['MI_Copper_-cu-_scnnfhbp_4K']} />
            </group>
            <group name="StaticMeshComponent1_54" position={[-0.002, 0, 40.001]}>
              <mesh name="Mesh118" geometry={nodes.Mesh118.geometry} material={materials.MI_FlatColor04} />
              <mesh name="Mesh118_1" geometry={nodes.Mesh118_1.geometry} material={materials['MI_Copper_-cu-_scnnfhbp_4K']} />
              <mesh name="Mesh118_2" geometry={nodes.Mesh118_2.geometry} material={materials['MI_Copper_-cu-_scnnfhbp_4K']} />
            </group>
            <group name="StaticMeshComponent20_73" position={[-0.001, -77, 391]}>
              <mesh name="Mesh121" geometry={nodes.Mesh121.geometry} material={materials.MI_FlatColor04} />
              <mesh name="Mesh121_1" geometry={nodes.Mesh121_1.geometry} material={materials['MI_Copper_-cu-_scnnfhbp_4K']} />
              <mesh name="Mesh121_2" geometry={nodes.Mesh121_2.geometry} material={materials['MI_Copper_-cu-_scnnfhbp_4K']} />
            </group>
            <group name="StaticMeshComponent21_74" position={[0, -77, 432.001]}>
              <mesh name="Mesh117" geometry={nodes.Mesh117.geometry} material={materials.MI_FlatColor04} />
              <mesh name="Mesh117_1" geometry={nodes.Mesh117_1.geometry} material={materials['MI_Copper_-cu-_scnnfhbp_4K']} />
              <mesh name="Mesh117_2" geometry={nodes.Mesh117_2.geometry} material={materials['MI_Copper_-cu-_scnnfhbp_4K']} />
            </group>
            <group name="StaticMeshComponent22_75" position={[-0.001, -77, 467.001]}>
              <mesh name="Mesh113" geometry={nodes.Mesh113.geometry} material={materials.MI_FlatColor04} />
              <mesh name="Mesh113_1" geometry={nodes.Mesh113_1.geometry} material={materials['MI_Copper_-cu-_scnnfhbp_4K']} />
              <mesh name="Mesh113_2" geometry={nodes.Mesh113_2.geometry} material={materials['MI_Copper_-cu-_scnnfhbp_4K']} />
            </group>
            <group name="StaticMeshComponent23_76" position={[-0.001, -77, 490.001]}>
              <mesh name="Mesh129" geometry={nodes.Mesh129.geometry} material={materials.MI_FlatColor04} />
              <mesh name="Mesh129_1" geometry={nodes.Mesh129_1.geometry} material={materials['MI_Copper_-cu-_scnnfhbp_4K']} />
              <mesh name="Mesh129_2" geometry={nodes.Mesh129_2.geometry} material={materials['MI_Copper_-cu-_scnnfhbp_4K']} />
            </group>
            <group name="StaticMeshComponent24_77" position={[-0.002, -77, 526.001]}>
              <mesh name="Mesh133" geometry={nodes.Mesh133.geometry} material={materials.MI_FlatColor04} />
              <mesh name="Mesh133_1" geometry={nodes.Mesh133_1.geometry} material={materials['MI_Copper_-cu-_scnnfhbp_4K']} />
              <mesh name="Mesh133_2" geometry={nodes.Mesh133_2.geometry} material={materials['MI_Copper_-cu-_scnnfhbp_4K']} />
            </group>
            <group name="StaticMeshComponent25_78" position={[-0.002, -77, 560]}>
              <mesh name="Mesh137" geometry={nodes.Mesh137.geometry} material={materials.MI_FlatColor04} />
              <mesh name="Mesh137_1" geometry={nodes.Mesh137_1.geometry} material={materials['MI_Copper_-cu-_scnnfhbp_4K']} />
              <mesh name="Mesh137_2" geometry={nodes.Mesh137_2.geometry} material={materials['MI_Copper_-cu-_scnnfhbp_4K']} />
            </group>
            <group name="StaticMeshComponent2_55" position={[-0.002, 0, 96]}>
              <mesh name="Mesh114" geometry={nodes.Mesh114.geometry} material={materials.MI_FlatColor04} />
              <mesh name="Mesh114_1" geometry={nodes.Mesh114_1.geometry} material={materials['MI_Copper_-cu-_scnnfhbp_4K']} />
              <mesh name="Mesh114_2" geometry={nodes.Mesh114_2.geometry} material={materials['MI_Copper_-cu-_scnnfhbp_4K']} />
            </group>
            <group name="StaticMeshComponent3_56" position={[-0.001, 0, 134]}>
              <mesh name="Mesh128" geometry={nodes.Mesh128.geometry} material={materials.MI_FlatColor04} />
              <mesh name="Mesh128_1" geometry={nodes.Mesh128_1.geometry} material={materials['MI_Copper_-cu-_scnnfhbp_4K']} />
              <mesh name="Mesh128_2" geometry={nodes.Mesh128_2.geometry} material={materials['MI_Copper_-cu-_scnnfhbp_4K']} />
            </group>
            <group name="StaticMeshComponent4_57" position={[-0.001, 0, 151.001]}>
              <mesh name="Mesh132" geometry={nodes.Mesh132.geometry} material={materials.MI_FlatColor04} />
              <mesh name="Mesh132_1" geometry={nodes.Mesh132_1.geometry} material={materials['MI_Copper_-cu-_scnnfhbp_4K']} />
              <mesh name="Mesh132_2" geometry={nodes.Mesh132_2.geometry} material={materials['MI_Copper_-cu-_scnnfhbp_4K']} />
            </group>
            <group name="StaticMeshComponent5_58" position={[0, 0, 183.001]}>
              <mesh name="Mesh136" geometry={nodes.Mesh136.geometry} material={materials.MI_FlatColor04} />
              <mesh name="Mesh136_1" geometry={nodes.Mesh136_1.geometry} material={materials['MI_Copper_-cu-_scnnfhbp_4K']} />
              <mesh name="Mesh136_2" geometry={nodes.Mesh136_2.geometry} material={materials['MI_Copper_-cu-_scnnfhbp_4K']} />
            </group>
            <group name="StaticMeshComponent6_59" position={[0, 0, 199.999]}>
              <mesh name="Mesh124" geometry={nodes.Mesh124.geometry} material={materials.MI_FlatColor04} />
              <mesh name="Mesh124_1" geometry={nodes.Mesh124_1.geometry} material={materials['MI_Copper_-cu-_scnnfhbp_4K']} />
              <mesh name="Mesh124_2" geometry={nodes.Mesh124_2.geometry} material={materials['MI_Copper_-cu-_scnnfhbp_4K']} />
            </group>
            <group name="StaticMeshComponent7_60" position={[0, 0, 241.001]}>
              <mesh name="Mesh120" geometry={nodes.Mesh120.geometry} material={materials.MI_FlatColor04} />
              <mesh name="Mesh120_1" geometry={nodes.Mesh120_1.geometry} material={materials['MI_Copper_-cu-_scnnfhbp_4K']} />
              <mesh name="Mesh120_2" geometry={nodes.Mesh120_2.geometry} material={materials['MI_Copper_-cu-_scnnfhbp_4K']} />
            </group>
            <group name="StaticMeshComponent8_61" position={[0, 0, 299.001]}>
              <mesh name="Mesh116" geometry={nodes.Mesh116.geometry} material={materials.MI_FlatColor04} />
              <mesh name="Mesh116_1" geometry={nodes.Mesh116_1.geometry} material={materials['MI_Copper_-cu-_scnnfhbp_4K']} />
              <mesh name="Mesh116_2" geometry={nodes.Mesh116_2.geometry} material={materials['MI_Copper_-cu-_scnnfhbp_4K']} />
            </group>
            <group name="StaticMeshComponent9_62" position={[-0.001, 0, 342]}>
              <mesh name="Mesh126" geometry={nodes.Mesh126.geometry} material={materials.MI_FlatColor04} />
              <mesh name="Mesh126_1" geometry={nodes.Mesh126_1.geometry} material={materials['MI_Copper_-cu-_scnnfhbp_4K']} />
              <mesh name="Mesh126_2" geometry={nodes.Mesh126_2.geometry} material={materials['MI_Copper_-cu-_scnnfhbp_4K']} />
            </group>
          </group>
          <group name="Text3DActor6" position={[421.926, -204.955, 55.251]} rotation={[0, 0.028, 0]} scale={0.045}>
            <group name="StaticMeshComponent0_65" position={[0, 0, 0]}>
              <mesh name="Mesh188" geometry={nodes.Mesh188.geometry} material={materials.MI_FlatColor04} />
              <mesh name="Mesh188_1" geometry={nodes.Mesh188_1.geometry} material={materials['MI_Copper_-cu-_scnnfhbp_4K']} />
              <mesh name="Mesh188_2" geometry={nodes.Mesh188_2.geometry} material={materials['MI_Copper_-cu-_scnnfhbp_4K']} />
            </group>
            <group name="StaticMeshComponent10_75" position={[-0.001, 0, 364]}>
              <mesh name="Mesh181" geometry={nodes.Mesh181.geometry} material={materials.MI_FlatColor04} />
              <mesh name="Mesh181_1" geometry={nodes.Mesh181_1.geometry} material={materials['MI_Copper_-cu-_scnnfhbp_4K']} />
              <mesh name="Mesh181_2" geometry={nodes.Mesh181_2.geometry} material={materials['MI_Copper_-cu-_scnnfhbp_4K']} />
            </group>
            <group name="StaticMeshComponent11_76" position={[-0.001, 0, 381]}>
              <mesh name="Mesh177" geometry={nodes.Mesh177.geometry} material={materials.MI_FlatColor04} />
              <mesh name="Mesh177_1" geometry={nodes.Mesh177_1.geometry} material={materials['MI_Copper_-cu-_scnnfhbp_4K']} />
              <mesh name="Mesh177_2" geometry={nodes.Mesh177_2.geometry} material={materials['MI_Copper_-cu-_scnnfhbp_4K']} />
            </group>
            <group name="StaticMeshComponent12_77" position={[0, 0, 419]}>
              <mesh name="Mesh194" geometry={nodes.Mesh194.geometry} material={materials.MI_FlatColor04} />
              <mesh name="Mesh194_1" geometry={nodes.Mesh194_1.geometry} material={materials['MI_Copper_-cu-_scnnfhbp_4K']} />
              <mesh name="Mesh194_2" geometry={nodes.Mesh194_2.geometry} material={materials['MI_Copper_-cu-_scnnfhbp_4K']} />
            </group>
            <group name="StaticMeshComponent13_78" position={[-0.002, 0, 458]}>
              <mesh name="Mesh198" geometry={nodes.Mesh198.geometry} material={materials.MI_FlatColor04} />
              <mesh name="Mesh198_1" geometry={nodes.Mesh198_1.geometry} material={materials['MI_Copper_-cu-_scnnfhbp_4K']} />
              <mesh name="Mesh198_2" geometry={nodes.Mesh198_2.geometry} material={materials['MI_Copper_-cu-_scnnfhbp_4K']} />
            </group>
            <group name="StaticMeshComponent14_79" position={[0, 0, 501]}>
              <mesh name="Mesh202" geometry={nodes.Mesh202.geometry} material={materials.MI_FlatColor04} />
              <mesh name="Mesh202_1" geometry={nodes.Mesh202_1.geometry} material={materials['MI_Copper_-cu-_scnnfhbp_4K']} />
              <mesh name="Mesh202_2" geometry={nodes.Mesh202_2.geometry} material={materials['MI_Copper_-cu-_scnnfhbp_4K']} />
            </group>
            <group name="StaticMeshComponent15_80" position={[0, -77, 0]}>
              <mesh name="Mesh206" geometry={nodes.Mesh206.geometry} material={materials.MI_FlatColor04} />
              <mesh name="Mesh206_1" geometry={nodes.Mesh206_1.geometry} material={materials['MI_Copper_-cu-_scnnfhbp_4K']} />
              <mesh name="Mesh206_2" geometry={nodes.Mesh206_2.geometry} material={materials['MI_Copper_-cu-_scnnfhbp_4K']} />
            </group>
            <group name="StaticMeshComponent16_81" position={[0, -77, 43]}>
              <mesh name="Mesh190" geometry={nodes.Mesh190.geometry} material={materials.MI_FlatColor04} />
              <mesh name="Mesh190_1" geometry={nodes.Mesh190_1.geometry} material={materials['MI_Copper_-cu-_scnnfhbp_4K']} />
              <mesh name="Mesh190_2" geometry={nodes.Mesh190_2.geometry} material={materials['MI_Copper_-cu-_scnnfhbp_4K']} />
            </group>
            <group name="StaticMeshComponent17_82" position={[-0.001, -77, 84]}>
              <mesh name="Mesh186" geometry={nodes.Mesh186.geometry} material={materials.MI_FlatColor04} />
              <mesh name="Mesh186_1" geometry={nodes.Mesh186_1.geometry} material={materials['MI_Copper_-cu-_scnnfhbp_4K']} />
              <mesh name="Mesh186_2" geometry={nodes.Mesh186_2.geometry} material={materials['MI_Copper_-cu-_scnnfhbp_4K']} />
            </group>
            <group name="StaticMeshComponent18_83" position={[0, -77, 145]}>
              <mesh name="Mesh182" geometry={nodes.Mesh182.geometry} material={materials.MI_FlatColor04} />
              <mesh name="Mesh182_1" geometry={nodes.Mesh182_1.geometry} material={materials['MI_Copper_-cu-_scnnfhbp_4K']} />
              <mesh name="Mesh182_2" geometry={nodes.Mesh182_2.geometry} material={materials['MI_Copper_-cu-_scnnfhbp_4K']} />
            </group>
            <group name="StaticMeshComponent19_84" position={[0, -77, 188]}>
              <mesh name="Mesh178" geometry={nodes.Mesh178.geometry} material={materials.MI_FlatColor04} />
              <mesh name="Mesh178_1" geometry={nodes.Mesh178_1.geometry} material={materials['MI_Copper_-cu-_scnnfhbp_4K']} />
              <mesh name="Mesh178_2" geometry={nodes.Mesh178_2.geometry} material={materials['MI_Copper_-cu-_scnnfhbp_4K']} />
            </group>
            <group name="StaticMeshComponent1_66" position={[-0.001, 0, 46]}>
              <mesh name="Mesh184" geometry={nodes.Mesh184.geometry} material={materials.MI_FlatColor04} />
              <mesh name="Mesh184_1" geometry={nodes.Mesh184_1.geometry} material={materials['MI_Copper_-cu-_scnnfhbp_4K']} />
              <mesh name="Mesh184_2" geometry={nodes.Mesh184_2.geometry} material={materials['MI_Copper_-cu-_scnnfhbp_4K']} />
            </group>
            <group name="StaticMeshComponent20_85" position={[0.001, -77, 225]}>
              <mesh name="Mesh193" geometry={nodes.Mesh193.geometry} material={materials.MI_FlatColor04} />
              <mesh name="Mesh193_1" geometry={nodes.Mesh193_1.geometry} material={materials['MI_Copper_-cu-_scnnfhbp_4K']} />
              <mesh name="Mesh193_2" geometry={nodes.Mesh193_2.geometry} material={materials['MI_Copper_-cu-_scnnfhbp_4K']} />
            </group>
            <group name="StaticMeshComponent21_86" position={[-0.002, -77, 276]}>
              <mesh name="Mesh197" geometry={nodes.Mesh197.geometry} material={materials.MI_FlatColor04} />
              <mesh name="Mesh197_1" geometry={nodes.Mesh197_1.geometry} material={materials['MI_Copper_-cu-_scnnfhbp_4K']} />
              <mesh name="Mesh197_2" geometry={nodes.Mesh197_2.geometry} material={materials['MI_Copper_-cu-_scnnfhbp_4K']} />
            </group>
            <group name="StaticMeshComponent22_87" position={[0, -77, 319]}>
              <mesh name="Mesh201" geometry={nodes.Mesh201.geometry} material={materials.MI_FlatColor04} />
              <mesh name="Mesh201_1" geometry={nodes.Mesh201_1.geometry} material={materials['MI_Copper_-cu-_scnnfhbp_4K']} />
              <mesh name="Mesh201_2" geometry={nodes.Mesh201_2.geometry} material={materials['MI_Copper_-cu-_scnnfhbp_4K']} />
            </group>
            <group name="StaticMeshComponent23_88" position={[0, -77, 376]}>
              <mesh name="Mesh205" geometry={nodes.Mesh205.geometry} material={materials.MI_FlatColor04} />
              <mesh name="Mesh205_1" geometry={nodes.Mesh205_1.geometry} material={materials['MI_Copper_-cu-_scnnfhbp_4K']} />
              <mesh name="Mesh205_2" geometry={nodes.Mesh205_2.geometry} material={materials['MI_Copper_-cu-_scnnfhbp_4K']} />
            </group>
            <group name="StaticMeshComponent24_89" position={[-0.001, -77, 393]}>
              <mesh name="Mesh191" geometry={nodes.Mesh191.geometry} material={materials.MI_FlatColor04} />
              <mesh name="Mesh191_1" geometry={nodes.Mesh191_1.geometry} material={materials['MI_Copper_-cu-_scnnfhbp_4K']} />
              <mesh name="Mesh191_2" geometry={nodes.Mesh191_2.geometry} material={materials['MI_Copper_-cu-_scnnfhbp_4K']} />
            </group>
            <group name="StaticMeshComponent25_90" position={[0, -77, 430]}>
              <mesh name="Mesh187" geometry={nodes.Mesh187.geometry} material={materials.MI_FlatColor04} />
              <mesh name="Mesh187_1" geometry={nodes.Mesh187_1.geometry} material={materials['MI_Copper_-cu-_scnnfhbp_4K']} />
              <mesh name="Mesh187_2" geometry={nodes.Mesh187_2.geometry} material={materials['MI_Copper_-cu-_scnnfhbp_4K']} />
            </group>
            <group name="StaticMeshComponent26_91" position={[-0.002, -77, 473]}>
              <mesh name="Mesh183" geometry={nodes.Mesh183.geometry} material={materials.MI_FlatColor04} />
              <mesh name="Mesh183_1" geometry={nodes.Mesh183_1.geometry} material={materials['MI_Copper_-cu-_scnnfhbp_4K']} />
              <mesh name="Mesh183_2" geometry={nodes.Mesh183_2.geometry} material={materials['MI_Copper_-cu-_scnnfhbp_4K']} />
            </group>
            <group name="StaticMeshComponent27_92" position={[0, -77, 514]}>
              <mesh name="Mesh179" geometry={nodes.Mesh179.geometry} material={materials.MI_FlatColor04} />
              <mesh name="Mesh179_1" geometry={nodes.Mesh179_1.geometry} material={materials['MI_Copper_-cu-_scnnfhbp_4K']} />
              <mesh name="Mesh179_2" geometry={nodes.Mesh179_2.geometry} material={materials['MI_Copper_-cu-_scnnfhbp_4K']} />
            </group>
            <group name="StaticMeshComponent28_93" position={[0, -153.999, 0]}>
              <mesh name="Mesh192" geometry={nodes.Mesh192.geometry} material={materials.MI_FlatColor04} />
              <mesh name="Mesh192_1" geometry={nodes.Mesh192_1.geometry} material={materials['MI_Copper_-cu-_scnnfhbp_4K']} />
              <mesh name="Mesh192_2" geometry={nodes.Mesh192_2.geometry} material={materials['MI_Copper_-cu-_scnnfhbp_4K']} />
            </group>
            <group name="StaticMeshComponent29_94" position={[0.001, -153.999, 23]}>
              <mesh name="Mesh196" geometry={nodes.Mesh196.geometry} material={materials.MI_FlatColor04} />
              <mesh name="Mesh196_1" geometry={nodes.Mesh196_1.geometry} material={materials['MI_Copper_-cu-_scnnfhbp_4K']} />
              <mesh name="Mesh196_2" geometry={nodes.Mesh196_2.geometry} material={materials['MI_Copper_-cu-_scnnfhbp_4K']} />
            </group>
            <group name="StaticMeshComponent2_67" position={[0.001, 0, 83]}>
              <mesh name="Mesh180" geometry={nodes.Mesh180.geometry} material={materials.MI_FlatColor04} />
              <mesh name="Mesh180_1" geometry={nodes.Mesh180_1.geometry} material={materials['MI_Copper_-cu-_scnnfhbp_4K']} />
              <mesh name="Mesh180_2" geometry={nodes.Mesh180_2.geometry} material={materials['MI_Copper_-cu-_scnnfhbp_4K']} />
            </group>
            <group name="StaticMeshComponent30_95" position={[0, -153.999, 63]}>
              <mesh name="Mesh200" geometry={nodes.Mesh200.geometry} material={materials.MI_FlatColor04} />
              <mesh name="Mesh200_1" geometry={nodes.Mesh200_1.geometry} material={materials['MI_Copper_-cu-_scnnfhbp_4K']} />
              <mesh name="Mesh200_2" geometry={nodes.Mesh200_2.geometry} material={materials['MI_Copper_-cu-_scnnfhbp_4K']} />
            </group>
            <group name="StaticMeshComponent31_96" position={[0, -153.999, 103]}>
              <mesh name="Mesh204" geometry={nodes.Mesh204.geometry} material={materials.MI_FlatColor04} />
              <mesh name="Mesh204_1" geometry={nodes.Mesh204_1.geometry} material={materials['MI_Copper_-cu-_scnnfhbp_4K']} />
              <mesh name="Mesh204_2" geometry={nodes.Mesh204_2.geometry} material={materials['MI_Copper_-cu-_scnnfhbp_4K']} />
            </group>
            <group name="StaticMeshComponent3_68" position={[0, 0, 122]}>
              <mesh name="Mesh176" geometry={nodes.Mesh176.geometry} material={materials.MI_FlatColor04} />
              <mesh name="Mesh176_1" geometry={nodes.Mesh176_1.geometry} material={materials['MI_Copper_-cu-_scnnfhbp_4K']} />
              <mesh name="Mesh176_2" geometry={nodes.Mesh176_2.geometry} material={materials['MI_Copper_-cu-_scnnfhbp_4K']} />
            </group>
            <group name="StaticMeshComponent4_69" position={[0.001, 0, 139]}>
              <mesh name="Mesh195" geometry={nodes.Mesh195.geometry} material={materials.MI_FlatColor04} />
              <mesh name="Mesh195_1" geometry={nodes.Mesh195_1.geometry} material={materials['MI_Copper_-cu-_scnnfhbp_4K']} />
              <mesh name="Mesh195_2" geometry={nodes.Mesh195_2.geometry} material={materials['MI_Copper_-cu-_scnnfhbp_4K']} />
            </group>
            <group name="StaticMeshComponent5_70" position={[0, 0, 169]}>
              <mesh name="Mesh199" geometry={nodes.Mesh199.geometry} material={materials.MI_FlatColor04} />
              <mesh name="Mesh199_1" geometry={nodes.Mesh199_1.geometry} material={materials['MI_Copper_-cu-_scnnfhbp_4K']} />
              <mesh name="Mesh199_2" geometry={nodes.Mesh199_2.geometry} material={materials['MI_Copper_-cu-_scnnfhbp_4K']} />
            </group>
            <group name="StaticMeshComponent6_71" position={[0, 0, 195]}>
              <mesh name="Mesh203" geometry={nodes.Mesh203.geometry} material={materials.MI_FlatColor04} />
              <mesh name="Mesh203_1" geometry={nodes.Mesh203_1.geometry} material={materials['MI_Copper_-cu-_scnnfhbp_4K']} />
              <mesh name="Mesh203_2" geometry={nodes.Mesh203_2.geometry} material={materials['MI_Copper_-cu-_scnnfhbp_4K']} />
            </group>
            <group name="StaticMeshComponent7_72" position={[0, 0, 251]}>
              <mesh name="Mesh207" geometry={nodes.Mesh207.geometry} material={materials.MI_FlatColor04} />
              <mesh name="Mesh207_1" geometry={nodes.Mesh207_1.geometry} material={materials['MI_Copper_-cu-_scnnfhbp_4K']} />
              <mesh name="Mesh207_2" geometry={nodes.Mesh207_2.geometry} material={materials['MI_Copper_-cu-_scnnfhbp_4K']} />
            </group>
            <group name="StaticMeshComponent8_73" position={[0.001, 0, 290]}>
              <mesh name="Mesh189" geometry={nodes.Mesh189.geometry} material={materials.MI_FlatColor04} />
              <mesh name="Mesh189_1" geometry={nodes.Mesh189_1.geometry} material={materials['MI_Copper_-cu-_scnnfhbp_4K']} />
              <mesh name="Mesh189_2" geometry={nodes.Mesh189_2.geometry} material={materials['MI_Copper_-cu-_scnnfhbp_4K']} />
            </group>
            <group name="StaticMeshComponent9_74" position={[0, 0, 327]}>
              <mesh name="Mesh185" geometry={nodes.Mesh185.geometry} material={materials.MI_FlatColor04} />
              <mesh name="Mesh185_1" geometry={nodes.Mesh185_1.geometry} material={materials['MI_Copper_-cu-_scnnfhbp_4K']} />
              <mesh name="Mesh185_2" geometry={nodes.Mesh185_2.geometry} material={materials['MI_Copper_-cu-_scnnfhbp_4K']} />
            </group>
          </group>
          <group name="Text3DActor7" position={[421.926, -127.948, 206.313]} rotation={[0, 0.028, 0]} scale={0.045}>
            <group name="StaticMeshComponent0_27" position={[0, 0.001, 0]}>
              <mesh name="Mesh213" geometry={nodes.Mesh213.geometry} material={materials.MI_FlatColor04} />
              <mesh name="Mesh213_1" geometry={nodes.Mesh213_1.geometry} material={materials['MI_Copper_-cu-_scnnfhbp_4K']} />
              <mesh name="Mesh213_2" geometry={nodes.Mesh213_2.geometry} material={materials['MI_Copper_-cu-_scnnfhbp_4K']} />
            </group>
            <group name="StaticMeshComponent10_37" position={[0.001, 0.001, 330]}>
              <mesh name="Mesh214" geometry={nodes.Mesh214.geometry} material={materials.MI_FlatColor04} />
              <mesh name="Mesh214_1" geometry={nodes.Mesh214_1.geometry} material={materials['MI_Copper_-cu-_scnnfhbp_4K']} />
              <mesh name="Mesh214_2" geometry={nodes.Mesh214_2.geometry} material={materials['MI_Copper_-cu-_scnnfhbp_4K']} />
            </group>
            <group name="StaticMeshComponent11_38" position={[0.002, 0.001, 370]}>
              <mesh name="Mesh217" geometry={nodes.Mesh217.geometry} material={materials.MI_FlatColor04} />
              <mesh name="Mesh217_1" geometry={nodes.Mesh217_1.geometry} material={materials['MI_Copper_-cu-_scnnfhbp_4K']} />
              <mesh name="Mesh217_2" geometry={nodes.Mesh217_2.geometry} material={materials['MI_Copper_-cu-_scnnfhbp_4K']} />
            </group>
            <group name="StaticMeshComponent12_39" position={[0.001, 0.001, 413]}>
              <mesh name="Mesh221" geometry={nodes.Mesh221.geometry} material={materials.MI_FlatColor04} />
              <mesh name="Mesh221_1" geometry={nodes.Mesh221_1.geometry} material={materials['MI_Copper_-cu-_scnnfhbp_4K']} />
              <mesh name="Mesh221_2" geometry={nodes.Mesh221_2.geometry} material={materials['MI_Copper_-cu-_scnnfhbp_4K']} />
            </group>
            <group name="StaticMeshComponent1_28" position={[0.001, 0.001, 42.999]}>
              <mesh name="Mesh218" geometry={nodes.Mesh218.geometry} material={materials.MI_FlatColor04} />
              <mesh name="Mesh218_1" geometry={nodes.Mesh218_1.geometry} material={materials['MI_Copper_-cu-_scnnfhbp_4K']} />
              <mesh name="Mesh218_2" geometry={nodes.Mesh218_2.geometry} material={materials['MI_Copper_-cu-_scnnfhbp_4K']} />
            </group>
            <group name="StaticMeshComponent2_29" position={[0, 0.001, 82]}>
              <mesh name="Mesh222" geometry={nodes.Mesh222.geometry} material={materials.MI_FlatColor04} />
              <mesh name="Mesh222_1" geometry={nodes.Mesh222_1.geometry} material={materials['MI_Copper_-cu-_scnnfhbp_4K']} />
              <mesh name="Mesh222_2" geometry={nodes.Mesh222_2.geometry} material={materials['MI_Copper_-cu-_scnnfhbp_4K']} />
            </group>
            <group name="StaticMeshComponent3_30" position={[0.002, 0.001, 107.999]}>
              <mesh name="Mesh215" geometry={nodes.Mesh215.geometry} material={materials.MI_FlatColor04} />
              <mesh name="Mesh215_1" geometry={nodes.Mesh215_1.geometry} material={materials['MI_Copper_-cu-_scnnfhbp_4K']} />
              <mesh name="Mesh215_2" geometry={nodes.Mesh215_2.geometry} material={materials['MI_Copper_-cu-_scnnfhbp_4K']} />
            </group>
            <group name="StaticMeshComponent4_31" position={[0.001, 0.001, 125]}>
              <mesh name="Mesh211" geometry={nodes.Mesh211.geometry} material={materials.MI_FlatColor04} />
              <mesh name="Mesh211_1" geometry={nodes.Mesh211_1.geometry} material={materials['MI_Copper_-cu-_scnnfhbp_4K']} />
              <mesh name="Mesh211_2" geometry={nodes.Mesh211_2.geometry} material={materials['MI_Copper_-cu-_scnnfhbp_4K']} />
            </group>
            <group name="StaticMeshComponent5_32" position={[0.001, 0.001, 156.999]}>
              <mesh name="Mesh220" geometry={nodes.Mesh220.geometry} material={materials.MI_FlatColor04} />
              <mesh name="Mesh220_1" geometry={nodes.Mesh220_1.geometry} material={materials['MI_Copper_-cu-_scnnfhbp_4K']} />
              <mesh name="Mesh220_2" geometry={nodes.Mesh220_2.geometry} material={materials['MI_Copper_-cu-_scnnfhbp_4K']} />
            </group>
            <group name="StaticMeshComponent6_33" position={[0.001, 0.001, 173.999]}>
              <mesh name="Mesh216" geometry={nodes.Mesh216.geometry} material={materials.MI_FlatColor04} />
              <mesh name="Mesh216_1" geometry={nodes.Mesh216_1.geometry} material={materials['MI_Copper_-cu-_scnnfhbp_4K']} />
              <mesh name="Mesh216_2" geometry={nodes.Mesh216_2.geometry} material={materials['MI_Copper_-cu-_scnnfhbp_4K']} />
            </group>
            <group name="StaticMeshComponent7_34" position={[0, 0.001, 235]}>
              <mesh name="Mesh212" geometry={nodes.Mesh212.geometry} material={materials.MI_FlatColor04} />
              <mesh name="Mesh212_1" geometry={nodes.Mesh212_1.geometry} material={materials['MI_Copper_-cu-_scnnfhbp_4K']} />
              <mesh name="Mesh212_2" geometry={nodes.Mesh212_2.geometry} material={materials['MI_Copper_-cu-_scnnfhbp_4K']} />
            </group>
            <group name="StaticMeshComponent8_35" position={[0.001, 0.001, 272]}>
              <mesh name="Mesh219" geometry={nodes.Mesh219.geometry} material={materials.MI_FlatColor04} />
              <mesh name="Mesh219_1" geometry={nodes.Mesh219_1.geometry} material={materials['MI_Copper_-cu-_scnnfhbp_4K']} />
              <mesh name="Mesh219_2" geometry={nodes.Mesh219_2.geometry} material={materials['MI_Copper_-cu-_scnnfhbp_4K']} />
            </group>
            <group name="StaticMeshComponent9_36" position={[0, 0.001, 307]}>
              <mesh name="Mesh223" geometry={nodes.Mesh223.geometry} material={materials.MI_FlatColor04} />
              <mesh name="Mesh223_1" geometry={nodes.Mesh223_1.geometry} material={materials['MI_Copper_-cu-_scnnfhbp_4K']} />
              <mesh name="Mesh223_2" geometry={nodes.Mesh223_2.geometry} material={materials['MI_Copper_-cu-_scnnfhbp_4K']} />
            </group>
          </group>
          <group name="Text3DActor8" position={[421.926, -161.715, 308.257]} rotation={[0, 0.028, 0]} scale={0.045}>
            <group name="StaticMeshComponent0_55">
              <mesh name="Mesh236" geometry={nodes.Mesh236.geometry} material={materials.MI_FlatColor04} />
              <mesh name="Mesh236_1" geometry={nodes.Mesh236_1.geometry} material={materials['MI_Copper_-cu-_scnnfhbp_4K']} />
              <mesh name="Mesh236_2" geometry={nodes.Mesh236_2.geometry} material={materials['MI_Copper_-cu-_scnnfhbp_4K']} />
            </group>
            <group name="StaticMeshComponent10_65" position={[0.001, 0, 347]}>
              <mesh name="Mesh241" geometry={nodes.Mesh241.geometry} material={materials.MI_FlatColor04} />
              <mesh name="Mesh241_1" geometry={nodes.Mesh241_1.geometry} material={materials['MI_Copper_-cu-_scnnfhbp_4K']} />
              <mesh name="Mesh241_2" geometry={nodes.Mesh241_2.geometry} material={materials['MI_Copper_-cu-_scnnfhbp_4K']} />
            </group>
            <group name="StaticMeshComponent11_66" position={[0, 0, 385.999]}>
              <mesh name="Mesh245" geometry={nodes.Mesh245.geometry} material={materials.MI_FlatColor04} />
              <mesh name="Mesh245_1" geometry={nodes.Mesh245_1.geometry} material={materials['MI_Copper_-cu-_scnnfhbp_4K']} />
              <mesh name="Mesh245_2" geometry={nodes.Mesh245_2.geometry} material={materials['MI_Copper_-cu-_scnnfhbp_4K']} />
            </group>
            <group name="StaticMeshComponent12_67" position={[0.001, 0, 442]}>
              <mesh name="Mesh249" geometry={nodes.Mesh249.geometry} material={materials.MI_FlatColor04} />
              <mesh name="Mesh249_1" geometry={nodes.Mesh249_1.geometry} material={materials['MI_Copper_-cu-_scnnfhbp_4K']} />
              <mesh name="Mesh249_2" geometry={nodes.Mesh249_2.geometry} material={materials['MI_Copper_-cu-_scnnfhbp_4K']} />
            </group>
            <group name="StaticMeshComponent13_68" position={[0, -77, 0]}>
              <mesh name="Mesh238" geometry={nodes.Mesh238.geometry} material={materials.MI_FlatColor04} />
              <mesh name="Mesh238_1" geometry={nodes.Mesh238_1.geometry} material={materials['MI_Copper_-cu-_scnnfhbp_4K']} />
              <mesh name="Mesh238_2" geometry={nodes.Mesh238_2.geometry} material={materials['MI_Copper_-cu-_scnnfhbp_4K']} />
            </group>
            <group name="StaticMeshComponent14_69" position={[0.002, -77, 47]}>
              <mesh name="Mesh234" geometry={nodes.Mesh234.geometry} material={materials.MI_FlatColor04} />
              <mesh name="Mesh234_1" geometry={nodes.Mesh234_1.geometry} material={materials['MI_Copper_-cu-_scnnfhbp_4K']} />
              <mesh name="Mesh234_2" geometry={nodes.Mesh234_2.geometry} material={materials['MI_Copper_-cu-_scnnfhbp_4K']} />
            </group>
            <group name="StaticMeshComponent15_70" position={[0.001, -77, 84]}>
              <mesh name="Mesh230" geometry={nodes.Mesh230.geometry} material={materials.MI_FlatColor04} />
              <mesh name="Mesh230_1" geometry={nodes.Mesh230_1.geometry} material={materials['MI_Copper_-cu-_scnnfhbp_4K']} />
              <mesh name="Mesh230_2" geometry={nodes.Mesh230_2.geometry} material={materials['MI_Copper_-cu-_scnnfhbp_4K']} />
            </group>
            <group name="StaticMeshComponent16_71" position={[0.001, -77, 114]}>
              <mesh name="Mesh226" geometry={nodes.Mesh226.geometry} material={materials.MI_FlatColor04} />
              <mesh name="Mesh226_1" geometry={nodes.Mesh226_1.geometry} material={materials['MI_Copper_-cu-_scnnfhbp_4K']} />
              <mesh name="Mesh226_2" geometry={nodes.Mesh226_2.geometry} material={materials['MI_Copper_-cu-_scnnfhbp_4K']} />
            </group>
            <group name="StaticMeshComponent17_72" position={[0, -77, 140]}>
              <mesh name="Mesh242" geometry={nodes.Mesh242.geometry} material={materials.MI_FlatColor04} />
              <mesh name="Mesh242_1" geometry={nodes.Mesh242_1.geometry} material={materials['MI_Copper_-cu-_scnnfhbp_4K']} />
              <mesh name="Mesh242_2" geometry={nodes.Mesh242_2.geometry} material={materials['MI_Copper_-cu-_scnnfhbp_4K']} />
            </group>
            <group name="StaticMeshComponent18_73" position={[0, -77, 166]}>
              <mesh name="Mesh246" geometry={nodes.Mesh246.geometry} material={materials.MI_FlatColor04} />
              <mesh name="Mesh246_1" geometry={nodes.Mesh246_1.geometry} material={materials['MI_Copper_-cu-_scnnfhbp_4K']} />
              <mesh name="Mesh246_2" geometry={nodes.Mesh246_2.geometry} material={materials['MI_Copper_-cu-_scnnfhbp_4K']} />
            </group>
            <group name="StaticMeshComponent19_74" position={[0, -77, 207]}>
              <mesh name="Mesh250" geometry={nodes.Mesh250.geometry} material={materials.MI_FlatColor04} />
              <mesh name="Mesh250_1" geometry={nodes.Mesh250_1.geometry} material={materials['MI_Copper_-cu-_scnnfhbp_4K']} />
              <mesh name="Mesh250_2" geometry={nodes.Mesh250_2.geometry} material={materials['MI_Copper_-cu-_scnnfhbp_4K']} />
            </group>
            <group name="StaticMeshComponent1_56" position={[0.001, 0, 43]}>
              <mesh name="Mesh232" geometry={nodes.Mesh232.geometry} material={materials.MI_FlatColor04} />
              <mesh name="Mesh232_1" geometry={nodes.Mesh232_1.geometry} material={materials['MI_Copper_-cu-_scnnfhbp_4K']} />
              <mesh name="Mesh232_2" geometry={nodes.Mesh232_2.geometry} material={materials['MI_Copper_-cu-_scnnfhbp_4K']} />
            </group>
            <group name="StaticMeshComponent20_75" position={[0.001, -77, 242]}>
              <mesh name="Mesh237" geometry={nodes.Mesh237.geometry} material={materials.MI_FlatColor04} />
              <mesh name="Mesh237_1" geometry={nodes.Mesh237_1.geometry} material={materials['MI_Copper_-cu-_scnnfhbp_4K']} />
              <mesh name="Mesh237_2" geometry={nodes.Mesh237_2.geometry} material={materials['MI_Copper_-cu-_scnnfhbp_4K']} />
            </group>
            <group name="StaticMeshComponent21_76" position={[0, -77, 278.999]}>
              <mesh name="Mesh233" geometry={nodes.Mesh233.geometry} material={materials.MI_FlatColor04} />
              <mesh name="Mesh233_1" geometry={nodes.Mesh233_1.geometry} material={materials['MI_Copper_-cu-_scnnfhbp_4K']} />
              <mesh name="Mesh233_2" geometry={nodes.Mesh233_2.geometry} material={materials['MI_Copper_-cu-_scnnfhbp_4K']} />
            </group>
            <group name="StaticMeshComponent22_77" position={[0.001, -77, 316]}>
              <mesh name="Mesh229" geometry={nodes.Mesh229.geometry} material={materials.MI_FlatColor04} />
              <mesh name="Mesh229_1" geometry={nodes.Mesh229_1.geometry} material={materials['MI_Copper_-cu-_scnnfhbp_4K']} />
              <mesh name="Mesh229_2" geometry={nodes.Mesh229_2.geometry} material={materials['MI_Copper_-cu-_scnnfhbp_4K']} />
            </group>
            <group name="StaticMeshComponent23_78" position={[0, -77, 325.999]}>
              <mesh name="Mesh225" geometry={nodes.Mesh225.geometry} material={materials.MI_FlatColor04} />
              <mesh name="Mesh225_1" geometry={nodes.Mesh225_1.geometry} material={materials['MI_Copper_-cu-_scnnfhbp_4K']} />
              <mesh name="Mesh225_2" geometry={nodes.Mesh225_2.geometry} material={materials['MI_Copper_-cu-_scnnfhbp_4K']} />
            </group>
            <group name="StaticMeshComponent24_79" position={[0, -77, 349]}>
              <mesh name="Mesh243" geometry={nodes.Mesh243.geometry} material={materials.MI_FlatColor04} />
              <mesh name="Mesh243_1" geometry={nodes.Mesh243_1.geometry} material={materials['MI_Copper_-cu-_scnnfhbp_4K']} />
              <mesh name="Mesh243_2" geometry={nodes.Mesh243_2.geometry} material={materials['MI_Copper_-cu-_scnnfhbp_4K']} />
            </group>
            <group name="StaticMeshComponent25_80" position={[0, -77, 386.999]}>
              <mesh name="Mesh247" geometry={nodes.Mesh247.geometry} material={materials.MI_FlatColor04} />
              <mesh name="Mesh247_1" geometry={nodes.Mesh247_1.geometry} material={materials['MI_Copper_-cu-_scnnfhbp_4K']} />
              <mesh name="Mesh247_2" geometry={nodes.Mesh247_2.geometry} material={materials['MI_Copper_-cu-_scnnfhbp_4K']} />
            </group>
            <group name="StaticMeshComponent26_81" position={[0.001, -77, 422.999]}>
              <mesh name="Mesh251" geometry={nodes.Mesh251.geometry} material={materials.MI_FlatColor04} />
              <mesh name="Mesh251_1" geometry={nodes.Mesh251_1.geometry} material={materials['MI_Copper_-cu-_scnnfhbp_4K']} />
              <mesh name="Mesh251_2" geometry={nodes.Mesh251_2.geometry} material={materials['MI_Copper_-cu-_scnnfhbp_4K']} />
            </group>
            <group name="StaticMeshComponent2_57" position={[0, 0, 104.001]}>
              <mesh name="Mesh228" geometry={nodes.Mesh228.geometry} material={materials.MI_FlatColor04} />
              <mesh name="Mesh228_1" geometry={nodes.Mesh228_1.geometry} material={materials['MI_Copper_-cu-_scnnfhbp_4K']} />
              <mesh name="Mesh228_2" geometry={nodes.Mesh228_2.geometry} material={materials['MI_Copper_-cu-_scnnfhbp_4K']} />
            </group>
            <group name="StaticMeshComponent3_58" position={[0, 0, 121.001]}>
              <mesh name="Mesh240" geometry={nodes.Mesh240.geometry} material={materials.MI_FlatColor04} />
              <mesh name="Mesh240_1" geometry={nodes.Mesh240_1.geometry} material={materials['MI_Copper_-cu-_scnnfhbp_4K']} />
              <mesh name="Mesh240_2" geometry={nodes.Mesh240_2.geometry} material={materials['MI_Copper_-cu-_scnnfhbp_4K']} />
            </group>
            <group name="StaticMeshComponent4_59" position={[0.002, 0, 138]}>
              <mesh name="Mesh244" geometry={nodes.Mesh244.geometry} material={materials.MI_FlatColor04} />
              <mesh name="Mesh244_1" geometry={nodes.Mesh244_1.geometry} material={materials['MI_Copper_-cu-_scnnfhbp_4K']} />
              <mesh name="Mesh244_2" geometry={nodes.Mesh244_2.geometry} material={materials['MI_Copper_-cu-_scnnfhbp_4K']} />
            </group>
            <group name="StaticMeshComponent5_60" position={[0.001, 0, 155]}>
              <mesh name="Mesh248" geometry={nodes.Mesh248.geometry} material={materials.MI_FlatColor04} />
              <mesh name="Mesh248_1" geometry={nodes.Mesh248_1.geometry} material={materials['MI_Copper_-cu-_scnnfhbp_4K']} />
              <mesh name="Mesh248_2" geometry={nodes.Mesh248_2.geometry} material={materials['MI_Copper_-cu-_scnnfhbp_4K']} />
            </group>
            <group name="StaticMeshComponent6_61" position={[0.001, 0, 211]}>
              <mesh name="Mesh239" geometry={nodes.Mesh239.geometry} material={materials.MI_FlatColor04} />
              <mesh name="Mesh239_1" geometry={nodes.Mesh239_1.geometry} material={materials['MI_Copper_-cu-_scnnfhbp_4K']} />
              <mesh name="Mesh239_2" geometry={nodes.Mesh239_2.geometry} material={materials['MI_Copper_-cu-_scnnfhbp_4K']} />
            </group>
            <group name="StaticMeshComponent7_62" position={[0, 0, 256.999]}>
              <mesh name="Mesh235" geometry={nodes.Mesh235.geometry} material={materials.MI_FlatColor04} />
              <mesh name="Mesh235_1" geometry={nodes.Mesh235_1.geometry} material={materials['MI_Copper_-cu-_scnnfhbp_4K']} />
              <mesh name="Mesh235_2" geometry={nodes.Mesh235_2.geometry} material={materials['MI_Copper_-cu-_scnnfhbp_4K']} />
            </group>
            <group name="StaticMeshComponent8_63" position={[0.001, 0, 292]}>
              <mesh name="Mesh231" geometry={nodes.Mesh231.geometry} material={materials.MI_FlatColor04} />
              <mesh name="Mesh231_1" geometry={nodes.Mesh231_1.geometry} material={materials['MI_Copper_-cu-_scnnfhbp_4K']} />
              <mesh name="Mesh231_2" geometry={nodes.Mesh231_2.geometry} material={materials['MI_Copper_-cu-_scnnfhbp_4K']} />
            </group>
            <group name="StaticMeshComponent9_64" position={[0.001, 0, 329.999]}>
              <mesh name="Mesh227" geometry={nodes.Mesh227.geometry} material={materials.MI_FlatColor04} />
              <mesh name="Mesh227_1" geometry={nodes.Mesh227_1.geometry} material={materials['MI_Copper_-cu-_scnnfhbp_4K']} />
              <mesh name="Mesh227_2" geometry={nodes.Mesh227_2.geometry} material={materials['MI_Copper_-cu-_scnnfhbp_4K']} />
            </group>
          </group>
          <group name="Text3DActor9" position={[421.926, -161.715, 439.072]} rotation={[0, 0.028, 0]} scale={0.045}>
            <group name="StaticMeshComponent0_39">
              <mesh name="Mesh259" geometry={nodes.Mesh259.geometry} material={materials.MI_FlatColor04} />
              <mesh name="Mesh259_1" geometry={nodes.Mesh259_1.geometry} material={materials['MI_Copper_-cu-_scnnfhbp_4K']} />
              <mesh name="Mesh259_2" geometry={nodes.Mesh259_2.geometry} material={materials['MI_Copper_-cu-_scnnfhbp_4K']} />
            </group>
            <group name="StaticMeshComponent10_49" position={[0.001, 0, 404.998]}>
              <mesh name="Mesh257" geometry={nodes.Mesh257.geometry} material={materials.MI_FlatColor04} />
              <mesh name="Mesh257_1" geometry={nodes.Mesh257_1.geometry} material={materials['MI_Copper_-cu-_scnnfhbp_4K']} />
              <mesh name="Mesh257_2" geometry={nodes.Mesh257_2.geometry} material={materials['MI_Copper_-cu-_scnnfhbp_4K']} />
            </group>
            <group name="StaticMeshComponent11_50" position={[0.001, 0, 443.999]}>
              <mesh name="Mesh253" geometry={nodes.Mesh253.geometry} material={materials.MI_FlatColor04} />
              <mesh name="Mesh253_1" geometry={nodes.Mesh253_1.geometry} material={materials['MI_Copper_-cu-_scnnfhbp_4K']} />
              <mesh name="Mesh253_2" geometry={nodes.Mesh253_2.geometry} material={materials['MI_Copper_-cu-_scnnfhbp_4K']} />
            </group>
            <group name="StaticMeshComponent12_51" position={[0, -77, 0]}>
              <mesh name="Mesh265" geometry={nodes.Mesh265.geometry} material={materials.MI_FlatColor04} />
              <mesh name="Mesh265_1" geometry={nodes.Mesh265_1.geometry} material={materials['MI_Copper_-cu-_scnnfhbp_4K']} />
              <mesh name="Mesh265_2" geometry={nodes.Mesh265_2.geometry} material={materials['MI_Copper_-cu-_scnnfhbp_4K']} />
            </group>
            <group name="StaticMeshComponent13_52" position={[-0.001, -77, 36.999]}>
              <mesh name="Mesh269" geometry={nodes.Mesh269.geometry} material={materials.MI_FlatColor04} />
              <mesh name="Mesh269_1" geometry={nodes.Mesh269_1.geometry} material={materials['MI_Copper_-cu-_scnnfhbp_4K']} />
              <mesh name="Mesh269_2" geometry={nodes.Mesh269_2.geometry} material={materials['MI_Copper_-cu-_scnnfhbp_4K']} />
            </group>
            <group name="StaticMeshComponent14_53" position={[0.001, -77, 73.999]}>
              <mesh name="Mesh260" geometry={nodes.Mesh260.geometry} material={materials.MI_FlatColor04} />
              <mesh name="Mesh260_1" geometry={nodes.Mesh260_1.geometry} material={materials['MI_Copper_-cu-_scnnfhbp_4K']} />
              <mesh name="Mesh260_2" geometry={nodes.Mesh260_2.geometry} material={materials['MI_Copper_-cu-_scnnfhbp_4K']} />
            </group>
            <group name="StaticMeshComponent15_54" position={[0, -77, 83.998]}>
              <mesh name="Mesh256" geometry={nodes.Mesh256.geometry} material={materials.MI_FlatColor04} />
              <mesh name="Mesh256_1" geometry={nodes.Mesh256_1.geometry} material={materials['MI_Copper_-cu-_scnnfhbp_4K']} />
              <mesh name="Mesh256_2" geometry={nodes.Mesh256_2.geometry} material={materials['MI_Copper_-cu-_scnnfhbp_4K']} />
            </group>
            <group name="StaticMeshComponent16_55" position={[0, -77, 106.999]}>
              <mesh name="Mesh252" geometry={nodes.Mesh252.geometry} material={materials.MI_FlatColor04} />
              <mesh name="Mesh252_1" geometry={nodes.Mesh252_1.geometry} material={materials['MI_Copper_-cu-_scnnfhbp_4K']} />
              <mesh name="Mesh252_2" geometry={nodes.Mesh252_2.geometry} material={materials['MI_Copper_-cu-_scnnfhbp_4K']} />
            </group>
            <group name="StaticMeshComponent17_56" position={[-0.001, -77, 145.999]}>
              <mesh name="Mesh266" geometry={nodes.Mesh266.geometry} material={materials.MI_FlatColor04} />
              <mesh name="Mesh266_1" geometry={nodes.Mesh266_1.geometry} material={materials['MI_Copper_-cu-_scnnfhbp_4K']} />
              <mesh name="Mesh266_2" geometry={nodes.Mesh266_2.geometry} material={materials['MI_Copper_-cu-_scnnfhbp_4K']} />
            </group>
            <group name="StaticMeshComponent18_57" position={[-0.001, -77, 183.999]}>
              <mesh name="Mesh270" geometry={nodes.Mesh270.geometry} material={materials.MI_FlatColor04} />
              <mesh name="Mesh270_1" geometry={nodes.Mesh270_1.geometry} material={materials['MI_Copper_-cu-_scnnfhbp_4K']} />
              <mesh name="Mesh270_2" geometry={nodes.Mesh270_2.geometry} material={materials['MI_Copper_-cu-_scnnfhbp_4K']} />
            </group>
            <group name="StaticMeshComponent1_40" position={[0, 0, 50]}>
              <mesh name="Mesh255" geometry={nodes.Mesh255.geometry} material={materials.MI_FlatColor04} />
              <mesh name="Mesh255_1" geometry={nodes.Mesh255_1.geometry} material={materials['MI_Copper_-cu-_scnnfhbp_4K']} />
              <mesh name="Mesh255_2" geometry={nodes.Mesh255_2.geometry} material={materials['MI_Copper_-cu-_scnnfhbp_4K']} />
            </group>
            <group name="StaticMeshComponent2_41" position={[0, 0, 86.998]}>
              <mesh name="Mesh263" geometry={nodes.Mesh263.geometry} material={materials.MI_FlatColor04} />
              <mesh name="Mesh263_1" geometry={nodes.Mesh263_1.geometry} material={materials['MI_Copper_-cu-_scnnfhbp_4K']} />
              <mesh name="Mesh263_2" geometry={nodes.Mesh263_2.geometry} material={materials['MI_Copper_-cu-_scnnfhbp_4K']} />
            </group>
            <group name="StaticMeshComponent3_42" position={[0.001, 0, 147.999]}>
              <mesh name="Mesh267" geometry={nodes.Mesh267.geometry} material={materials.MI_FlatColor04} />
              <mesh name="Mesh267_1" geometry={nodes.Mesh267_1.geometry} material={materials['MI_Copper_-cu-_scnnfhbp_4K']} />
              <mesh name="Mesh267_2" geometry={nodes.Mesh267_2.geometry} material={materials['MI_Copper_-cu-_scnnfhbp_4K']} />
            </group>
            <group name="StaticMeshComponent4_43" position={[0, 0, 203.999]}>
              <mesh name="Mesh262" geometry={nodes.Mesh262.geometry} material={materials.MI_FlatColor04} />
              <mesh name="Mesh262_1" geometry={nodes.Mesh262_1.geometry} material={materials['MI_Copper_-cu-_scnnfhbp_4K']} />
              <mesh name="Mesh262_2" geometry={nodes.Mesh262_2.geometry} material={materials['MI_Copper_-cu-_scnnfhbp_4K']} />
            </group>
            <group name="StaticMeshComponent5_44" position={[0, 0, 250.999]}>
              <mesh name="Mesh258" geometry={nodes.Mesh258.geometry} material={materials.MI_FlatColor04} />
              <mesh name="Mesh258_1" geometry={nodes.Mesh258_1.geometry} material={materials['MI_Copper_-cu-_scnnfhbp_4K']} />
              <mesh name="Mesh258_2" geometry={nodes.Mesh258_2.geometry} material={materials['MI_Copper_-cu-_scnnfhbp_4K']} />
            </group>
            <group name="StaticMeshComponent6_45" position={[-0.002, 0, 287.998]}>
              <mesh name="Mesh254" geometry={nodes.Mesh254.geometry} material={materials.MI_FlatColor04} />
              <mesh name="Mesh254_1" geometry={nodes.Mesh254_1.geometry} material={materials['MI_Copper_-cu-_scnnfhbp_4K']} />
              <mesh name="Mesh254_2" geometry={nodes.Mesh254_2.geometry} material={materials['MI_Copper_-cu-_scnnfhbp_4K']} />
            </group>
            <group name="StaticMeshComponent7_46" position={[0, 0, 314]}>
              <mesh name="Mesh264" geometry={nodes.Mesh264.geometry} material={materials.MI_FlatColor04} />
              <mesh name="Mesh264_1" geometry={nodes.Mesh264_1.geometry} material={materials['MI_Copper_-cu-_scnnfhbp_4K']} />
              <mesh name="Mesh264_2" geometry={nodes.Mesh264_2.geometry} material={materials['MI_Copper_-cu-_scnnfhbp_4K']} />
            </group>
            <group name="StaticMeshComponent8_47" position={[0, 0, 350.999]}>
              <mesh name="Mesh268" geometry={nodes.Mesh268.geometry} material={materials.MI_FlatColor04} />
              <mesh name="Mesh268_1" geometry={nodes.Mesh268_1.geometry} material={materials['MI_Copper_-cu-_scnnfhbp_4K']} />
              <mesh name="Mesh268_2" geometry={nodes.Mesh268_2.geometry} material={materials['MI_Copper_-cu-_scnnfhbp_4K']} />
            </group>
            <group name="StaticMeshComponent9_48" position={[0, 0, 367.999]}>
              <mesh name="Mesh261" geometry={nodes.Mesh261.geometry} material={materials.MI_FlatColor04} />
              <mesh name="Mesh261_1" geometry={nodes.Mesh261_1.geometry} material={materials['MI_Copper_-cu-_scnnfhbp_4K']} />
              <mesh name="Mesh261_2" geometry={nodes.Mesh261_2.geometry} material={materials['MI_Copper_-cu-_scnnfhbp_4K']} />
            </group>
          </group>
          <group name="UCX_10_Box008" position={[-377.7, -85.208, 720.344]} rotation={[Math.PI / 2, 0, -Math.PI / 2]} scale={[1.01, 1, 1]} />
          <group name="UCX_10_TextPlus008" position={[-378.617, -89.058, 720.344]} rotation={[Math.PI / 2, 0, -Math.PI / 2]} />
          <group name="UCX_1_Box001" position={[122.937, -130.283, 552.198]} rotation={[Math.PI / 2, 0, 0]} />
          <group name="UCX_2_Box022" position={[296.041, -131.258, 551.803]} rotation={[Math.PI / 2, 0.008, 0]} scale={1.03} />
          <group name="UCX_3_Box004" position={[347.266, -237.338, 650.952]} rotation={[1.511, -0.164, 1.217]} />
          <group name="UCX_4_Box002" position={[425.123, -141.462, 797.501]} rotation={[Math.PI / 2, 0, Math.PI / 2]} />
          <group name="UCX_5_6_7_Box019" position={[424.466, -84.7, 1017.727]} rotation={[Math.PI / 2, 0, Math.PI / 2]} />
          <group name="UCX_5_6_7_Box020" position={[423.318, -127.281, 926.679]} rotation={[Math.PI / 2, 0, Math.PI / 2]} />
          <group name="UCX_5_6_7_Box021" position={[424.939, -163.041, 1017.289]} rotation={[Math.PI / 2, 0, Math.PI / 2]} />
          <group name="UCX_8_Box006" position={[-377.562, -107.188, 960.289]} rotation={[Math.PI / 2, 0, -Math.PI / 2]} />
          <group name="UCX_9_Box007" position={[-377.132, -83.981, 585.551]} rotation={[Math.PI / 2, 0, -Math.PI / 2]} />
          <group name="UCX_9_TextPlus007" position={[-378.27, -89.95, 594.054]} rotation={[Math.PI / 2, 0, -Math.PI / 2]} />
          <group name="UCX_Box001" position={[117.071, -286.077, 1063.302]} rotation={[-Math.PI, 0, -Math.PI]} scale={[0.15, 0.112, 0.112]} />
          <group name="UCX_candelabro_sangre" position={[248.469, -325.171, -133.147]} rotation={[-Math.PI, 0, -Math.PI]} scale={0} />
          <group name="UCX_cuadro" position={[94.92, -49.648, 529.787]} rotation={[Math.PI / 2, 0, Math.PI]} scale={0.927} />
          <group name="UCX_cuadros_2_Box002" position={[426.047, -66.686, 217.947]} rotation={[Math.PI / 2, 0, Math.PI / 2]} />
          <group name="UCX_cuadros_2_Box003" position={[423.477, -69.628, 67.072]} rotation={[Math.PI / 2, 0, Math.PI / 2]} scale={0.902} />
          <group name="UCX_cuadros_2_Box004" position={[316.524, -52.815, 529.787]} rotation={[Math.PI / 2, 0, Math.PI]} />
          <group name="UCX_cuadros_2_Box005" position={[215.436, -55.28, 529.787]} rotation={[Math.PI / 2, 0, Math.PI]} scale={[0.93, 0.93, 0.99]} />
          <group name="UCX_cuadros_2_Box3" position={[423.488, -70.341, 318.025]} rotation={[Math.PI / 2, 0, Math.PI / 2]} />
          <group name="UCX_cuadros_2_Box4" position={[423.488, -71.068, 448.565]} rotation={[Math.PI / 2, 0, Math.PI / 2]} />
          <group name="UCX_Cuadros_Box026" position={[424.511, -118.52, -320.151]} />
          <group name="UCX_Cuadros_Box027" position={[424.58, -119.83, -497.637]} rotation={[-Math.PI, 0, -Math.PI]} />
          <group name="UCX_Cuadros_Box030" position={[424.053, -114.917, -210.994]} rotation={[-Math.PI, 0, -Math.PI]} />
          <group name="UCX_Cuadros_Box27" position={[424.511, -117.316, -403.308]} rotation={[Math.PI / 2, 0, Math.PI / 2]} scale={0.912} />
          <group name="UCX_defaultMaterial" position={[344.885, -320.522, -137.906]} rotation={[-Math.PI, 0, -Math.PI]} scale={[0.003, 0.004, 0.004]} />
          <group name="UCX_desk_defaultMaterial" position={[376.657, -276.664, 978.981]} rotation={[0, 1.571, 0]} scale={0.001} />
          <group name="UCX_desk_defaultMaterial_001" position={[376.657, -276.664, 978.981]} rotation={[0, 1.571, 0]} scale={0.001} />
          <group name="UCX_desk_defaultMaterial_002" position={[376.657, -276.664, 978.981]} rotation={[0, 1.571, 0]} scale={0.001} />
          <group name="UCX_EaselStand_Easel_LOD0" position={[332.427, -320.609, 654.918]} rotation={[0, -1.222, 0]} scale={0.165} />
          <group name="UCX_metal_straps_low" position={[117.071, -286.077, 1063.302]} rotation={[-Math.PI, 0, -Math.PI]} scale={[0.15, 0.112, 0.112]} />
          <group name="UCX_obispo" position={[424.145, -117.654, -626.254]} rotation={[-Math.PI, 0, -Math.PI]} />
          <group name="UCX_Object_10_StaticMeshComponent0" position={[337.748, -192.58, -149.764]} rotation={[0, -1.571, 0]} scale={0.001} />
          <group name="UCX_Object_11_StaticMeshComponent0" position={[337.748, -192.58, -149.764]} rotation={[0, -1.571, 0]} scale={0.001} />
          <group name="UCX_Object_12_StaticMeshComponent0" position={[337.748, -192.58, -149.764]} rotation={[0, -1.571, 0]} scale={0.001} />
          <group name="UCX_Object_13_StaticMeshComponent0" position={[337.748, -192.58, -149.764]} rotation={[0, -1.571, 0]} scale={0.001} />
          <group name="UCX_Object_14_StaticMeshComponent0" position={[337.748, -192.58, -149.764]} rotation={[0, -1.571, 0]} scale={0.001} />
          <group name="UCX_Object_15_StaticMeshComponent0" position={[337.748, -192.58, -149.764]} rotation={[0, -1.571, 0]} scale={0.001} />
          <group name="UCX_Object_16_StaticMeshComponent0" position={[337.748, -192.58, -149.764]} rotation={[0, -1.571, 0]} scale={0.001} />
          <group name="UCX_Object_17_StaticMeshComponent0" position={[337.748, -192.58, -149.764]} rotation={[0, -1.571, 0]} scale={0.001} />
          <group name="UCX_Object_2_StaticMeshComponent0" position={[337.748, -192.58, -149.764]} rotation={[0, -1.571, 0]} scale={0.001} />
          <group name="UCX_Object_3_StaticMeshComponent0" position={[337.748, -192.58, -149.764]} rotation={[0, -1.571, 0]} scale={0.001} />
          <group name="UCX_Object_4_StaticMeshComponent0" position={[337.748, -192.58, -149.764]} rotation={[0, -1.571, 0]} scale={0.001} />
          <group name="UCX_Object_5_StaticMeshComponent0" position={[337.748, -192.58, -149.764]} rotation={[0, -1.571, 0]} scale={0.001} />
          <group name="UCX_Object_9_StaticMeshComponent0" position={[337.748, -192.58, -149.764]} rotation={[0, -1.571, 0]} scale={0.001} />
          <group name="UCX_sala_nueva_Box003" position={[-156.955, -113.987, 1099.39]} rotation={[Math.PI / 2, 0, -Math.PI]} />
          <group name="UCX_sala_nueva_Box005" position={[135.266, -89.001, 1102.131]} rotation={[Math.PI / 2, 0, Math.PI]} />
          <group name="UCX_silla_Box167" position={[316.966, -283.615, 1049.327]} rotation={[Math.PI, -Math.PI / 9, Math.PI]} scale={2.4} />
          <group name="UCX_silla_Box168" position={[316.966, -283.615, 1049.327]} rotation={[Math.PI, -Math.PI / 9, Math.PI]} scale={2.4} />
          <group name="UCX_silla_Box169" position={[316.966, -283.615, 1049.327]} rotation={[Math.PI, -Math.PI / 9, Math.PI]} scale={2.4} />
          <group name="UCX_silla_Box170" position={[316.966, -283.615, 1049.327]} rotation={[Math.PI, -Math.PI / 9, Math.PI]} scale={2.4} />
          <group name="UCX_silla_Box22" position={[316.966, -283.615, 1049.327]} rotation={[Math.PI, -Math.PI / 9, Math.PI]} scale={2.4} />
          <group name="UCX_silla_Sphere47" position={[316.966, -283.615, 1049.327]} rotation={[Math.PI, -Math.PI / 9, Math.PI]} scale={2.4} />
          <group name="UCX_Skrzynia_na_zboze_LR" position={[117.071, -286.077, 1063.302]} rotation={[-Math.PI, 0, -Math.PI]} scale={[0.15, 0.112, 0.112]} />
          <group name="UCX_SM_Bench3_LOD0" position={[3.093, -318.506, -1105.665]} rotation={[0, 1.571, 0]} scale={0.658} />
          <group name="UCX_SM_window_05" position={[-7.414, 99.515, 109.668]} rotation={[0, -1.571, 0]} />
          <group name="UCX_SM_window_6" position={[-7.414, 95.685, 820.296]} rotation={[0, -1.571, 0]} />
          <group name="UCX_ultimo_Box009" position={[-375.916, -115.347, -235.859]} rotation={[Math.PI / 2, 0, -Math.PI / 2]} />
          <group name="UCX_ultimo_Box010" position={[-375.916, -115.347, -235.859]} rotation={[Math.PI / 2, 0, -Math.PI / 2]} />
          <group name="UCX_ultimo_Box011" position={[-375.916, -115.347, -235.859]} rotation={[Math.PI / 2, 0, -Math.PI / 2]} />
          <group name="UCX_ultimo_Box012" position={[-375.916, -115.347, -239.118]} rotation={[Math.PI / 2, 0, -Math.PI / 2]} />
          <group name="UCX_ultimo_Box013" position={[-375.916, -115.347, -235.859]} rotation={[Math.PI / 2, 0, -Math.PI / 2]} />
          <group name="UCX_ultimo_Box014" position={[-375.916, -115.347, -233.996]} rotation={[Math.PI / 2, 0, -Math.PI / 2]} />
          <group name="UCX_ultimo_Box015" position={[-375.916, -115.347, -235.859]} rotation={[Math.PI / 2, 0, -Math.PI / 2]} />
          <group name="UCX_ultimo_Box016" position={[-375.916, -115.347, -235.859]} rotation={[Math.PI / 2, 0, -Math.PI / 2]} />
          <group name="UCX_ultimo_Box017" position={[-375.916, -115.347, -235.859]} rotation={[Math.PI / 2, 0, -Math.PI / 2]} />
          <group name="UCX_ultimo_Box018" position={[-375.916, -115.347, -235.859]} rotation={[Math.PI / 2, 0, -Math.PI / 2]} />
          <group name="WorldSettings" position={[26.044, -340.522, 183.035]} rotation={[0, -1.571, 0]} />
          <group name="S_Fireplace_ujdjcjxga_lod0_Var1" position={[388.434, -261.296, 793.663]}>
            <mesh name="S_Fireplace_ujdjcjxga_lod0_Var1_LOD4" geometry={nodes.S_Fireplace_ujdjcjxga_lod0_Var1_LOD4.geometry} material={materials['Material #158']} position={[0.134, -55.965, -0.039]} rotation={[-Math.PI, 0, -Math.PI]} />
          </group>
          <group name="SM_Bench3" position={[3.093, -278.004, -923.123]} />
          <mesh name="10_Box008" geometry={nodes['10_Box008'].geometry} material={materials['Material #89']} position={[-377.7, -85.208, 720.344]} rotation={[Math.PI / 2, 0, -Math.PI / 2]} scale={[1.01, 1, 1]} />
          <mesh name="10_TextPlus008" geometry={nodes['10_TextPlus008'].geometry} material={materials.Material__53} position={[-378.617, -89.058, 720.344]} rotation={[Math.PI / 2, 0, -Math.PI / 2]} />
          <mesh name="1_Box001" geometry={nodes['1_Box001'].geometry} material={materials['Material #93']} position={[122.937, -130.283, 552.198]} rotation={[Math.PI / 2, 0, 0]} />
          <mesh name="1_TextPlus001" geometry={nodes['1_TextPlus001'].geometry} material={materials.Material__56} position={[122.937, -140.934, 560.101]} rotation={[Math.PI / 2, 0, 0]} scale={[1, 13.941, 1]} />
          <mesh name="2_Box022" geometry={nodes['2_Box022'].geometry} material={materials['Material #94']} position={[296.041, -131.258, 551.803]} rotation={[Math.PI / 2, 0.008, 0]} scale={1.03} />
          <mesh name="2_TextPlus018" geometry={nodes['2_TextPlus018'].geometry} material={materials.Material__56} position={[295.977, -144.894, 553.103]} rotation={[Math.PI / 2, 0, 0]} />
          <mesh name="3_Box004" geometry={nodes['3_Box004'].geometry} material={materials['Material #91']} position={[347.266, -237.338, 650.952]} rotation={[1.511, -0.164, 1.217]} />
          <mesh name="3_TextPlus004" geometry={nodes['3_TextPlus004'].geometry} material={materials.Material__53} position={[424.365, -188.414, 686.971]} rotation={[Math.PI / 2, 0, Math.PI / 2]} />
          <mesh name="4_Box002" geometry={nodes['4_Box002'].geometry} material={materials['Material #82']} position={[425.123, -141.462, 797.501]} rotation={[Math.PI / 2, 0, Math.PI / 2]} />
          <mesh name="4_TextPlus002" geometry={nodes['4_TextPlus002'].geometry} material={materials.Material__53} position={[424.501, -142.185, 797.501]} rotation={[Math.PI / 2, 0, Math.PI / 2]} />
          <mesh name="5_6_7_Box019" geometry={nodes['5_6_7_Box019'].geometry} material={materials['Material #83']} position={[424.466, -84.7, 1017.727]} rotation={[Math.PI / 2, 0, Math.PI / 2]} />
          <mesh name="5_6_7_Box020" geometry={nodes['5_6_7_Box020'].geometry} material={materials['Material #84']} position={[423.318, -127.281, 926.679]} rotation={[Math.PI / 2, 0, Math.PI / 2]} />
          <mesh name="5_6_7_Box021" geometry={nodes['5_6_7_Box021'].geometry} material={materials['Material #85']} position={[424.939, -163.041, 1017.289]} rotation={[Math.PI / 2, 0, Math.PI / 2]} />
          <mesh name="5_6_7_TextPlus015" geometry={nodes['5_6_7_TextPlus015'].geometry} material={materials.Material__53} position={[424.197, -88.708, 1017.727]} rotation={[Math.PI / 2, 0, Math.PI / 2]} />
          <mesh name="5_6_7_TextPlus016" geometry={nodes['5_6_7_TextPlus016'].geometry} material={materials.Material__53} position={[423.88, -138.857, 925.261]} rotation={[Math.PI / 2, 0, Math.PI / 2]} />
          <mesh name="5_6_7_TextPlus017" geometry={nodes['5_6_7_TextPlus017'].geometry} material={materials.Material__53} position={[424.298, -159.051, 1017.289]} rotation={[Math.PI / 2, 0, Math.PI / 2]} />
          <mesh name="8_Box006" geometry={nodes['8_Box006'].geometry} material={materials['Material #88']} position={[-377.562, -107.188, 960.289]} rotation={[Math.PI / 2, 0, -Math.PI / 2]} />
          <mesh name="8_TextPlus006" geometry={nodes['8_TextPlus006'].geometry} material={materials.M_Wood_Walnut_Inst} position={[-377.918, -107.188, 960.289]} rotation={[Math.PI / 2, 0, -Math.PI / 2]} />
          <mesh name="9_Box007" geometry={nodes['9_Box007'].geometry} material={materials['Material #90']} position={[-377.132, -83.981, 585.551]} rotation={[Math.PI / 2, 0, -Math.PI / 2]} />
          <mesh name="9_TextPlus007" geometry={nodes['9_TextPlus007'].geometry} material={materials.Material__53} position={[-378.27, -89.95, 594.054]} rotation={[Math.PI / 2, 0, -Math.PI / 2]} />
          <mesh name="Box001" geometry={nodes.Box001.geometry} material={materials.Material__37} position={[117.071, -286.077, 1063.302]} rotation={[-Math.PI, 0, -Math.PI]} scale={[0.15, 0.112, 0.112]} />
          <mesh name="Box007" geometry={nodes.Box007.geometry} material={materials['Material #57']} position={[26.306, -328.531, 183.172]} rotation={[0, -1.571, 0]} scale={8.972} />
          <mesh name="candelabro_sangre" geometry={nodes.candelabro_sangre.geometry} material={materials['Material #122']} position={[248.469, -325.171, -133.147]} rotation={[-Math.PI, 0, -Math.PI]} scale={0} />
          <mesh name="cuadro" geometry={nodes.cuadro.geometry} material={materials['Material #114']} position={[94.92, -49.648, 529.787]} rotation={[Math.PI / 2, 0, Math.PI]} scale={0.927} />
          <mesh name="cuadros_2_Box002" geometry={nodes.cuadros_2_Box002.geometry} material={materials['Material #96']} position={[426.047, -66.686, 217.947]} rotation={[Math.PI / 2, 0, Math.PI / 2]} />
          <mesh name="cuadros_2_Box003" geometry={nodes.cuadros_2_Box003.geometry} material={materials['Material #95']} position={[423.477, -69.628, 67.072]} rotation={[Math.PI / 2, 0, Math.PI / 2]} scale={0.902} />
          <mesh name="cuadros_2_Box004" geometry={nodes.cuadros_2_Box004.geometry} material={materials['Material #115']} position={[316.524, -52.815, 529.787]} rotation={[Math.PI / 2, 0, Math.PI]} />
          <mesh name="cuadros_2_Box3" geometry={nodes.cuadros_2_Box3.geometry} material={materials['Material #98']} position={[423.488, -70.341, 318.025]} rotation={[Math.PI / 2, 0, Math.PI / 2]} />
          <mesh name="cuadros_2_Box4" geometry={nodes.cuadros_2_Box4.geometry} material={materials['Material #97']} position={[423.488, -71.068, 448.565]} rotation={[Math.PI / 2, 0, Math.PI / 2]} />
          <mesh name="Cuadros_Box026" geometry={nodes.Cuadros_Box026.geometry} material={materials['Material #100']} position={[424.511, -118.52, -320.151]} />
          <mesh name="Cuadros_Box027" geometry={nodes.Cuadros_Box027.geometry} material={materials['Material #102']} position={[424.58, -119.83, -497.637]} rotation={[-Math.PI, 0, -Math.PI]} />
          <mesh name="Cuadros_Box030" geometry={nodes.Cuadros_Box030.geometry} material={materials['Material #101']} position={[424.053, -114.917, -210.994]} rotation={[-Math.PI, 0, -Math.PI]} />
          <mesh name="Cuadros_Box27" geometry={nodes.Cuadros_Box27.geometry} material={materials['Material #166']} position={[424.511, -117.316, -403.308]} rotation={[Math.PI / 2, 0, Math.PI / 2]} scale={0.912} />
          <mesh name="defaultMaterial" geometry={nodes.defaultMaterial.geometry} material={materials['Material #121']} position={[344.885, -320.522, -137.906]} rotation={[-Math.PI, 0, -Math.PI]} scale={[0.003, 0.004, 0.004]} />
          <mesh name="desk_defaultMaterial" geometry={nodes.desk_defaultMaterial.geometry} material={materials['Material #118']} position={[376.657, -276.664, 978.981]} rotation={[0, 1.571, 0]} scale={0.001} />
          <mesh name="desk_defaultMaterial_001" geometry={nodes.desk_defaultMaterial_001.geometry} material={materials['Material #117']} position={[376.657, -276.664, 978.981]} rotation={[0, 1.571, 0]} scale={0.001} />
          <mesh name="desk_defaultMaterial_002" geometry={nodes.desk_defaultMaterial_002.geometry} material={materials['Material #118']} position={[376.657, -276.664, 978.981]} rotation={[0, 1.571, 0]} scale={0.001} />
          <mesh name="EaselStand_Easel_LOD0" geometry={nodes.EaselStand_Easel_LOD0.geometry} material={materials['Material #119']} position={[332.427, -320.519, 655.536]} rotation={[0, -1.222, 0]} scale={0.165} />
          <mesh name="metal_straps_low" geometry={nodes.metal_straps_low.geometry} material={materials.Material__30} position={[117.071, -286.077, 1063.302]} rotation={[-Math.PI, 0, -Math.PI]} scale={[0.15, 0.112, 0.112]} />
          <mesh name="obispo" geometry={nodes.obispo.geometry} material={materials['Material #99']} position={[424.145, -117.654, -626.254]} rotation={[-Math.PI, 0, -Math.PI]} />
          <mesh name="sala_nueva_Box003" geometry={nodes.sala_nueva_Box003.geometry} material={materials['Material #87']} position={[-156.955, -113.987, 1099.39]} rotation={[Math.PI / 2, 0, -Math.PI]} />
          <mesh name="sala_nueva_Box005" geometry={nodes.sala_nueva_Box005.geometry} material={materials['Material #86']} position={[135.266, -89.001, 1102.131]} rotation={[Math.PI / 2, 0, Math.PI]} />
          <mesh name="sala_nueva_TextPlus003" geometry={nodes.sala_nueva_TextPlus003.geometry} material={materials.M_Wood_Walnut_Inst} position={[-156.955, -46.279, 1102]} rotation={[Math.PI / 2, 0, -Math.PI]} scale={1.83} />
          <mesh name="sala_nueva_TextPlus005" geometry={nodes.sala_nueva_TextPlus005.geometry} material={materials.M_Wood_Walnut_Inst} position={[135.266, -89.001, 1102.131]} rotation={[Math.PI / 2, 0, Math.PI]} />
          <mesh name="silla_Box167" geometry={nodes.silla_Box167.geometry} material={materials['Material #123']} position={[316.966, -283.615, 1049.327]} rotation={[Math.PI, -Math.PI / 9, Math.PI]} scale={2.4} />
          <mesh name="silla_Box168" geometry={nodes.silla_Box168.geometry} material={materials.M_Wood_Walnut} position={[316.966, -283.615, 1049.327]} rotation={[Math.PI, -Math.PI / 9, Math.PI]} scale={2.4} />
          <mesh name="silla_Box169" geometry={nodes.silla_Box169.geometry} material={materials.M_Wood_Walnut} position={[316.966, -283.615, 1049.327]} rotation={[Math.PI, -Math.PI / 9, Math.PI]} scale={2.4} />
          <mesh name="silla_Box170" geometry={nodes.silla_Box170.geometry} material={materials.M_Wood_Walnut} position={[316.966, -283.615, 1049.327]} rotation={[Math.PI, -Math.PI / 9, Math.PI]} scale={2.4} />
          <mesh name="silla_Box22" geometry={nodes.silla_Box22.geometry} material={materials.M_Wood_Walnut} position={[316.966, -283.615, 1049.327]} rotation={[Math.PI, -Math.PI / 9, Math.PI]} scale={2.4} />
          <mesh name="silla_Sphere47" geometry={nodes.silla_Sphere47.geometry} material={materials.MI_Furniture_Fabric_sjfvdgbc_4K} position={[316.966, -283.615, 1049.327]} rotation={[Math.PI, -Math.PI / 9, Math.PI]} scale={2.4} />
          <mesh name="Skrzynia_na_zboze_LR" geometry={nodes.Skrzynia_na_zboze_LR.geometry} material={materials.Material__33} position={[117.071, -286.077, 1063.302]} rotation={[-Math.PI, 0, -Math.PI]} scale={[0.15, 0.112, 0.112]} />
          <mesh name="SM_Frame1_01" geometry={nodes.SM_Frame1_01.geometry} material={materials['Material #120']} position={[424.105, -179.839, 1017.033]} rotation={[0, 1.571, 0]} scale={[2.089, 1.637, 1.757]} />
          <mesh name="SM_Frame1_2" geometry={nodes.SM_Frame1_2.geometry} material={materials['Material #120']} position={[424.105, -98.95, 1017.578]} rotation={[0, 1.571, 0]} scale={[1.358, 1.162, 1.197]} />
          <mesh name="SM_Frame2_01" geometry={nodes.SM_Frame2_01.geometry} material={materials['Material #120']} position={[317.719, -88.153, 528.955]} scale={[1.9, 1.81, 1.9]} />
          <mesh name="SM_Frame2_2" geometry={nodes.SM_Frame2_2.geometry} material={materials['Material #120']} position={[215.634, -82.916, 529.612]} scale={[1.418, 1.35, 1.418]} />
          <mesh name="SM_Frame2_3" geometry={nodes.SM_Frame2_3.geometry} material={materials['Material #120']} position={[40.5, -50.475, 532.791]} rotation={[0, 0, -Math.PI / 2]} scale={[2.66, 2.747, 2.66]} />
          <mesh name="SM_Frame2_4" geometry={nodes.SM_Frame2_4.geometry} material={materials['Material #120']} position={[226.585, -121.131, 552.352]} rotation={[Math.PI, 0, -Math.PI / 2]} scale={[4.262, 3.442, 3.492]} />
          <mesh name="SM_Frame2_5" geometry={nodes.SM_Frame2_5.geometry} material={materials['Material #120']} position={[422.769, -186.997, 926.112]} rotation={[0, 1.571, 0]} scale={[3.398, 3.447, 2.828]} />
          <mesh name="SM_Frame2_6" geometry={nodes.SM_Frame2_6.geometry} material={materials['Material #120']} position={[424.575, -172.836, 797.419]} rotation={[0, 1.571, 0]} scale={[2.946, 2.059, 1]} />
          <mesh name="SM_Frame2_7" geometry={nodes.SM_Frame2_7.geometry} material={materials['Material #120']} position={[-378.005, -130.715, 720.362]} rotation={[0.005, -Math.PI / 2, 0]} scale={[3.613, 2.721, 1]} />
          <mesh name="SM_Frame4_13" geometry={nodes.SM_Frame4_13.geometry} material={materials['Material #120']} position={[-377.034, -75.895, 517.964]} rotation={[Math.PI / 2, -Math.PI / 2, 0]} scale={[3.164, 3.863, 1]} />
          <mesh name="SM_Frame5_01" geometry={nodes.SM_Frame5_01.geometry} material={materials['Material #120']} position={[423.339, -167.355, -497.718]} rotation={[0, 1.571, 0]} scale={[1.922, 1.902, 1.922]} />
          <mesh name="SM_Frame5_2" geometry={nodes.SM_Frame5_2.geometry} material={materials['Material #120']} position={[423.339, -154.583, -322.767]} rotation={[0, 1.571, 0]} scale={[2.205, 2.082, 2.105]} />
          <mesh name="SM_Frame5_3" geometry={nodes.SM_Frame5_3.geometry} material={materials['Material #120']} position={[418.002, -174.105, 66.324]} rotation={[0, 1.571, 0]} scale={[4.29, 4.243, 4.29]} />
          <mesh name="SM_Frame5_4" geometry={nodes.SM_Frame5_4.geometry} material={materials['Material #120']} position={[421.452, -107.155, 215.725]} rotation={[0, 1.571, 0]} scale={[2.395, 2.262, 2.286]} />
          <mesh name="SM_Frame5_5" geometry={nodes.SM_Frame5_5.geometry} material={materials['Material #120']} position={[418.311, -135.146, 319.956]} rotation={[0, 1.571, 0]} scale={[3.818, 3.605, 3.644]} />
          <mesh name="SM_Frame5_6" geometry={nodes.SM_Frame5_6.geometry} material={materials['Material #120']} position={[418.311, -135.146, 448.592]} rotation={[0, 1.571, 0]} scale={[3.818, 3.605, 3.644]} />
          <mesh name="SM_Frame5_7" geometry={nodes.SM_Frame5_7.geometry} material={materials['Material #120']} position={[423.339, -155.183, -404.465]} rotation={[0, 1.571, 0]} scale={[2.505, 2.225, 2.391]} />
          <mesh name="SM_Frame5_8" geometry={nodes.SM_Frame5_8.geometry} material={materials['Material #120']} position={[122.463, -199.814, 555.132]} rotation={[-Math.PI, 0, -Math.PI]} scale={[2.588, 3.107, 2.588]} />
          <mesh name="SM_WallTrim19" geometry={nodes.SM_WallTrim19.geometry} material={materials.MI_WallTrims01} position={[-401.728, 99.601, 1100.806]} rotation={[0, 1.571, 0]} scale={[0.207, 0.207, 2.25]} />
          <group name="SM_window_05" position={[-7.414, 99.515, 109.668]} rotation={[0, -1.571, 0]}>
            <mesh name="Mesh002" geometry={nodes.Mesh002.geometry} material={materials.MI_WindowFrame01} />
            <mesh name="Mesh002_1" geometry={nodes.Mesh002_1.geometry} material={materials.MI_Glass02} />
          </group>
          <group name="SM_window_6" position={[-7.414, 95.685, 820.296]} rotation={[0, -1.571, 0]}>
            <mesh name="Mesh344" geometry={nodes.Mesh344.geometry} material={materials.MI_WindowFrame01} />
            <mesh name="Mesh344_1" geometry={nodes.Mesh344_1.geometry} material={materials.MI_Glass02} />
          </group>
          <group name="StaticMeshComponent10_35" position={[192.929, -317.625, 951.362]} scale={0.27}>
            <mesh name="Mesh454" geometry={nodes.Mesh454.geometry} material={materials.M_Basic_Wall} />
            <mesh name="Mesh454_1" geometry={nodes.Mesh454_1.geometry} material={materials.M_AssetPlatform} />
            <mesh name="Mesh454_2" geometry={nodes.Mesh454_2.geometry} material={materials.BasicShapeMaterial} />
          </group>
          <group name="StaticMeshComponent11_36" position={[192.929, -317.625, 962.431]} scale={0.27}>
            <mesh name="Mesh453" geometry={nodes.Mesh453.geometry} material={materials.M_Basic_Wall} />
            <mesh name="Mesh453_1" geometry={nodes.Mesh453_1.geometry} material={materials.M_AssetPlatform} />
            <mesh name="Mesh453_2" geometry={nodes.Mesh453_2.geometry} material={materials.BasicShapeMaterial} />
          </group>
          <mesh name="UCX_SM_Frame2_2" geometry={nodes.UCX_SM_Frame2_2.geometry} material={materials['Material #113']} position={[215.634, -82.409, 530.685]} scale={[1.252, 1.272, 1.418]} />
          <mesh name="UCX_SM_WallTrim19" geometry={nodes.UCX_SM_WallTrim19.geometry} material={nodes.UCX_SM_WallTrim19.material} position={[-401.728, 99.601, 1100.806]} rotation={[0, 1.571, 0]} scale={[0.207, 0.207, 2.25]} />
          <mesh name="ultimo_Box009" geometry={nodes.ultimo_Box009.geometry} material={materials['Material #111']} position={[-375.916, -115.347, -235.859]} rotation={[Math.PI / 2, 0, -Math.PI / 2]} />
          <mesh name="ultimo_Box010" geometry={nodes.ultimo_Box010.geometry} material={materials['Material #110']} position={[-375.916, -115.347, -235.859]} rotation={[Math.PI / 2, 0, -Math.PI / 2]} />
          <mesh name="ultimo_Box011" geometry={nodes.ultimo_Box011.geometry} material={materials['Material #112']} position={[-375.916, -115.347, -235.859]} rotation={[Math.PI / 2, 0, -Math.PI / 2]} />
          <mesh name="ultimo_Box012" geometry={nodes.ultimo_Box012.geometry} material={materials['Material #109']} position={[-375.916, -115.347, -239.118]} rotation={[Math.PI / 2, 0, -Math.PI / 2]} />
          <mesh name="ultimo_Box013" geometry={nodes.ultimo_Box013.geometry} material={materials['Material #107']} position={[-375.916, -115.347, -235.859]} rotation={[Math.PI / 2, 0, -Math.PI / 2]} />
          <mesh name="ultimo_Box014" geometry={nodes.ultimo_Box014.geometry} material={materials['Material #108']} position={[-375.916, -115.347, -233.996]} rotation={[Math.PI / 2, 0, -Math.PI / 2]} />
          <mesh name="ultimo_Box015" geometry={nodes.ultimo_Box015.geometry} material={materials['Material #106']} position={[-375.916, -115.347, -235.859]} rotation={[Math.PI / 2, 0, -Math.PI / 2]} />
          <mesh name="ultimo_Box016" geometry={nodes.ultimo_Box016.geometry} material={materials['Material #105']} position={[-375.916, -115.347, -259.388]} rotation={[Math.PI / 2, 0, -Math.PI / 2]} scale={[0.745, 1, 1]} />
          <mesh name="ultimo_Box017" geometry={nodes.ultimo_Box017.geometry} material={materials['Material #104']} position={[-375.916, -115.347, -269.173]} rotation={[Math.PI / 2, 0, -Math.PI / 2]} scale={[0.725, 1, 1]} />
          <mesh name="ultimo_Box018" geometry={nodes.ultimo_Box018.geometry} material={materials['Material #103']} position={[-375.916, -115.347, -235.859]} rotation={[Math.PI / 2, 0, -Math.PI / 2]} />
          <mesh name="ultimo_TextPlus009" geometry={nodes.ultimo_TextPlus009.geometry} material={materials.WorldGridMaterial} position={[-375.916, -115.347, -235.859]} rotation={[Math.PI / 2, 0, -Math.PI / 2]} />
          <mesh name="ultimo_TextPlus010" geometry={nodes.ultimo_TextPlus010.geometry} material={materials.WorldGridMaterial} position={[-375.916, -115.347, -235.859]} rotation={[Math.PI / 2, 0, -Math.PI / 2]} />
          <mesh name="ultimo_TextPlus011" geometry={nodes.ultimo_TextPlus011.geometry} material={materials.WorldGridMaterial} position={[-375.916, -115.347, -235.859]} rotation={[Math.PI / 2, 0, -Math.PI / 2]} />
          <mesh name="ultimo_TextPlus012" geometry={nodes.ultimo_TextPlus012.geometry} material={materials.WorldGridMaterial} position={[-375.916, -115.347, -235.859]} rotation={[Math.PI / 2, 0, -Math.PI / 2]} />
          <mesh name="ultimo_TextPlus013" geometry={nodes.ultimo_TextPlus013.geometry} material={materials.WorldGridMaterial} position={[-375.916, -115.347, -235.859]} rotation={[Math.PI / 2, 0, -Math.PI / 2]} />
          <mesh name="Box009" geometry={nodes.Box009.geometry} material={nodes.Box009.material} position={[20.613, 131.296, -541.123]} scale={[1, 0.461, 1]} />
          <mesh name="Box010" geometry={nodes.Box010.geometry} material={nodes.Box010.material} position={[20.613, 131.296, 1086.059]} scale={[1, 0.461, 0.111]} />
          <mesh name="Box011" geometry={nodes.Box011.geometry} material={nodes.Box011.material} position={[20.613, 131.296, 463.366]} scale={[1, 0.461, 0.294]} />
          <mesh name="Box012" geometry={nodes.Box012.geometry} material={nodes.Box012.material} position={[342.767, 131.296, 463.366]} rotation={[0, 1.571, 0]} scale={[1.513, 0.461, 0.294]} />
          <mesh name="Box013" geometry={nodes.Box013.geometry} material={nodes.Box013.material} position={[-349.929, 131.296, 463.366]} rotation={[0, 1.571, 0]} scale={[1.513, 0.461, 0.294]} />
        </group>
      </group>
    </group>
  )
}

useGLTF.preload('/3dModels/museo_estructura_.glb')
